export function formatAsHumanReadableList(list, lastSeparator = 'and') {
  let result = '';

  list.forEach((item, i) => {
    if (i !== 0) {
      result += (i === list.length - 1 ? ` ${lastSeparator} ` : ', ');
    }

    result += item;
  });

  return result;
}
