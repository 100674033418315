import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

export function TableRow({ children, className, ...otherProps }) {
  return (
    <tr className={classNames('TableRow', className)} {...otherProps}>
      {children}
    </tr>
  );
}

export function TableCell({ children, className, ...otherProps }) {
  return (
    <td className={classNames('TableCell', className)} {...otherProps}>
      {children}
    </td>
  );
}

Table.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node,
  className: PropTypes.string
};

export default function Table({ header, body, footer, className }) {
  return (
    <table className={classNames('Table', className)}>
      {header && (
        <thead>
          {header}
        </thead>
      )}
      <tbody>
        {body}
      </tbody>
      {footer && (
        <tfoot>
          {footer}
        </tfoot>
      )}
    </table>
  );
}
