import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../Tooltip';
import './styles.css';

InfoTooltip.propTypes = {
  iconClassName: PropTypes.string
};

export default function InfoTooltip({ iconClassName, ...otherProps }) {
  return (
    <Tooltip offset={[ 4, -14 ]} {...otherProps}>
      <span className={classNames('InfoTooltip_icon', 'icon', 'icon-info', iconClassName)} />
    </Tooltip>
  );
}
