import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  idKey: PropTypes.string
};

export default function SelectField({ label, name, children, className, idKey, ...otherProps }) {
  const inputFieldId = `${name}${idKey ? `-${idKey}` : ''}`;
  return ( // TODO replace with react-select dependency??
    <div className='SelectField'>
      {label && <label className='SelectField_label' htmlFor={inputFieldId}>{label}</label>}
      <div className='SelectField_input-wrapper'>
        <select className={classNames('SelectField_select', className)} name={name} id={inputFieldId} {...otherProps}>
          {children}
        </select>
      </div>
    </div>
  );
}
