import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import './styles.css';
import { Dropdown } from '../Dropdown';

ActionsButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

interface ActionsButtonProps {
  children: ReactNode,
  className?: string
}

export function ActionsButton({ children, className }: ActionsButtonProps) {
  const classNameForComponent = classNames('ActionsButton', className);

  return (
    <Dropdown className={classNameForComponent} icon={<i className='icon icon-actions' aria-hidden='true' />} popupOffset={[ 4, 14 ]}>
      {children}
    </Dropdown>
  );
}
