import _ from 'lodash';
import React, { Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Page from '../../../components/Page';
import RouterComponent from '../../../components/RouterComponent';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Toggle from '../../../components/Toggle';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { withRouter } from '../../../utils/withRouter';
import { notificationNameLabels, reminderNameLabels } from '../labels';
import './styles.css';

function getNotificationAlertText(notificationName, enabled) {
  return enabled ?
    `We will send you an email on "${notificationNameLabels[notificationName] || notificationName}".` :
    `We will not email you about "${notificationNameLabels[notificationName] || notificationName}" anymore.`;
}

function getReminderAlertText(reminderName, enabled) {
  return enabled ?
    `We will send you an email on "${reminderNameLabels[reminderName] || reminderName}".` :
    `We will not email you about "${reminderNameLabels[reminderName] || reminderName}" anymore.`;
}

export default withRouter(class AdminUserSettingsPage extends RouterComponent {

  state = {
    accountStatus: null,
    fetchingAccountStatus: true,
    notificationSettings: [],
    fetchingNotificationSettings: true,
    reminderSettings: [],
    fetchingReminderSettings: true,
    alert: this.getAlertText()
  };

  componentDidMount() {
    this.fetch(this.state.alert);
    Api.post('/admin-users/events/open/email-notification-settings', { origin: this.getRouteParameter('origin') || 'GUI' });
  }

  fetch = alert => {
    Api.get('/account', ({ status }) => {
      this.setState({ accountStatus: status, fetchingAccountStatus: false });
    });
    Api.get('/admin-users/email-notification-settings', ({ list }) => {
      this.setState({ notificationSettings: _(list).sortBy('notificationName').value(), fetchingNotificationSettings: false, alert });
    });
    Api.get('/admin-users/email-reminder-settings', ({ list }) => {
      this.setState({ reminderSettings: _(list).sortBy('reminderName').value(), fetchingReminderSettings: false });
    });
  };

  // TODO: CLS-591 remove as the new design will redirect to unsubscribe page, not here
  getAlertText() {
    if (this.getRouteStateParameter('notificationName')) {
      return getNotificationAlertText(this.getRouteStateParameter('notificationName'), false);
    }
    else if (this.getRouteStateParameter('reminderName')) {
      return getReminderAlertText(this.getRouteStateParameter('reminderName'), false);
    }

    return null;
  }

  toggleSingleNotification = (notificationName, enabled) => {
    const alert = getNotificationAlertText(notificationName, enabled);

    Api.put('/admin-users/email-notification-settings', { list: [ { notificationName, enabled } ], origin: 'GUI' }, () => this.fetch(alert));
  };

  toggleSingleReminder = (reminderName, enabled) => {
    const alert = getReminderAlertText(reminderName, enabled);

    Api.put('/admin-users/email-reminder-settings', { list: [ { reminderName, enabled } ], origin: 'GUI' }, () => this.fetch(alert));
  };

  toggleAll = () => {
    const anyDisabled = !!this.state.notificationSettings.find(({ enabled }) => !enabled) || !!this.state.reminderSettings.find(({ enabled }) => !enabled);
    const notificationSettings = _(this.state.notificationSettings)
      .map(({ notificationName }) => ({ notificationName, enabled: anyDisabled }))
      .value();
    const reminderSettings = _(this.state.reminderSettings)
      .map(({ reminderName }) => ({ reminderName, enabled: anyDisabled }))
      .value();
    const alert = anyDisabled ? 'All email notifications enabled.' : 'All notifications disabled. We will not send you emails about any notifications.';

    Api.put('/admin-users/email-notification-settings', { list: notificationSettings, origin: 'GUI' }, () => {
      Api.put('/admin-users/email-reminder-settings', { list: reminderSettings, origin: 'GUI' }, () => this.fetch(alert));
    });
  };

  render() {
    return (
      <Page
        className='AdminUserSettingsPage'
        title='User Settings'
        alert={this.state.alert && <Alert type='ok'><AlertEmphasis>{this.state.alert}</AlertEmphasis></Alert>}
      >
        <Card>
          <Table
            header={
              <TableRow>
                <th className='AdminUserSettingsPage_event-name'>Event</th>
                <th className='AdminUserSettingsPage_send-email'>Send email</th>
              </TableRow>
            }
            body={this.state.fetchingNotificationSettings || this.state.fetchingReminderSettings || this.state.fetchingAccountStatus ?

              <TableRow className='AdminUserSettingsPage_fetching'>
                <TableCell colSpan='2'>
                  Fetching data...
                </TableCell>
              </TableRow> :
              <Fragment>
                {this.state.reminderSettings
                  .filter(reminder => reminder.reminderName !== 'ACTIVATE_ACCOUNT')
                  .filter(({ reminderName }) => !User.isTeamLead() || reminderName === 'INVITE_USERS')
                  .map(({ reminderName, enabled }) => {
                    return (
                      <TableRow key={reminderName}>
                        <TableCell>
                          {reminderNameLabels[reminderName] || reminderName}
                        </TableCell>
                        <TableCell>
                          <Toggle
                            enabled={enabled}
                            onChange={enabled => this.toggleSingleReminder(reminderName, enabled)}
                            className='pull-right'
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                {User.isAccountAdmin() && this.state.notificationSettings
                  .filter(({ notificationName }) => this.state.accountStatus !== 'EVALUATION_ONGOING' || notificationName !== 'INVALID_SEAT_USER_DOMAIN')
                  .filter(({ notificationName }) => this.state.accountStatus !== 'EVALUATION_ENDED' || notificationName !== 'INVALID_SEAT_USER_DOMAIN')
                  .map(({ notificationName, enabled }) => {
                    return (
                      <TableRow key={notificationName}>
                        <TableCell>
                          {notificationNameLabels[notificationName] || notificationName}
                        </TableCell>
                        <TableCell>
                          <Toggle
                            enabled={enabled}
                            onChange={enabled => this.toggleSingleNotification(notificationName, enabled)}
                            className='pull-right'
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </Fragment>
            }
            footer={
              <TableRow>
                <TableCell colSpan='2'>
                  <Button className='AdminUserSettingsPage_toggle-all' onClick={this.toggleAll}>Toggle all</Button>
                </TableCell>
              </TableRow>
            }
          />
        </Card>
      </Page>
    );
  }
});
