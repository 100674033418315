import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { ActionsButton } from '../../../components/ActionsButton';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../components/Dropdown';
import InfoTooltip from '../../../components/InfoTooltip';
import InviteOrCopyButtons from '../../../components/InviteOrCopyButtons';
import ProductTypeLabel from '../../../components/ProductTypeLabel';
import RedeployMetricLabel from '../../../components/RedeployMetricLabel';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Tooltip from '../../../components/Tooltip';
import { sortByProductType } from '../../../enums/ProductType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import CopyTextButton from '../../TeamTokenPage/CopyTextButton';
import CornerNotificationPopup from '../CornerNotificationPopup';
import SeatUserInviteForm from '../SeatUserInviteForm';
import { formatDuration, hasJRebelEnabled } from '../selectors';
import TeamForm from '../TeamForm';
import './styles.css';

const TeamTable = ({ onTeamsUpdate, origin, list, fetching }) => {

  const [ modal, setModal ] = useState({ active: null, team: null });
  const [ hoveredTeam, setHoveredTeam ] = useState(null);

  const showCornerNotification = () => {
    setModal(modal => ({ ...modal, active: 'cornerNotification' }));
  };

  const fetch = () => {
    onTeamsUpdate();
  };

  const openModalForRow = (id, rowToken) => {
    setModal(({ active: id, team: _(list).find(({ token }) => token === rowToken) }));
  };

  const onEditButtonPress = ({ currentTarget }) => {
    openModalForRow('teamForm', currentTarget.getAttribute('data-token'));
  };

  const onSeatUserInviteButtonPress = rowToken => {
    openModalForRow('seatUserInviteForm', rowToken);
  };

  const onTeamCopyTeamUrlPress = token => {
    return () => {
      Api.post(`/teams/${token}/events/copy/team-url`, { origin }, () => {
        showCornerNotification();
      });
    };
  };

  const onTeamPanelBodyMouseOver = ({ currentTarget }) => {
    setHoveredTeam(_(list).find(({ token }) => token === currentTarget.getAttribute('data-token')));
  };

  const onTeamPanelBodyMouseLeave = () => {
    setHoveredTeam(null);
  };

  const closeModal = () => {
    setModal({});
  };

  const onModalSave = () => {
    closeModal();
    fetch();
  };

  const onModalSaveShowCornerNotification = () => {
    showCornerNotification();
    fetch();
  };

  const renderTimeSavedCell = (products, timeSavedSeconds) => {
    if (hasJRebelEnabled(products)) {
      const timeSaved = formatDuration(timeSavedSeconds);
      return <RedeployMetricLabel value={timeSaved.value} text={timeSaved.unit} />;
    }
    return null;
  };

  function renderNoResults() {
    return (<div className='TeamTable_no-results-message'>
      <a className='TeamTable_no-results-image' />
      <div className='TeamTable_no-results-text'>
        <h2>No teams added yet</h2>
        <p>Add teams to start distributing your licenses</p>
      </div>
    </div>);
  }

  return (
    <Fragment>
      {modal.active === 'teamForm' && (
        <TeamForm
          editedTeam={modal.team}
          onSave={onModalSave}
          onCancel={closeModal}
        />
      )}

      {modal.active === 'seatUserInviteForm' && (
        <SeatUserInviteForm
          team={modal.team}
          onSave={onModalSaveShowCornerNotification}
          onCancel={showCornerNotification}
        />
      )}

      {User.isNPSNotificationEnabled() && modal.active === 'cornerNotification' && (
        <CornerNotificationPopup
          type='SHOW_FEEDBACK_FORM'
          origin='TEAM_TABLE'
          onSave={onModalSave}
          onCancel={closeModal}
        />
      )}

      <Card>
        <Table
          className='TeamTable'
          header={
            <TableRow>
              <th className='TeamTable_name-column'>
                <span>Team name / URL</span>
                {!User.isTeamLead() && <span className='pull-right'>Team lead</span>}
              </th>
              <th className='TeamTable_second-column' />
              <th className='TeamTable_product-column'>Products</th>
              <th className='TeamTable_usage-column'>
                <span>Usage</span>
                {list.length > 0 && list.some(team => team.products.some(product => product.enabled && product.limit)) && (
                  <InfoTooltip text='Seats in use / Seat limit (if set)' />
                )}
              </th>
              <th className='TeamTable_created-column'>Created</th>
              <th className='TeamTable_time-saved-column'>
                <span>Time saved</span>
                <InfoTooltip text='Over the last 30 days' />
              </th>
              <th className='TeamTable_redeploy-time-column'>Redeploy time (s)</th>
              <th className='TeamTable_actions-column' />
            </TableRow>
          }
          body={list.length === 0 ?

            <TableRow className='TeamTable_no-results'>
              <TableCell colSpan='10'>
                {fetching ? 'Fetching data...' : renderNoResults()}
              </TableCell>
            </TableRow> :

            list.map(({ products, token, name, teamLead, createdAt, createdBy, timeSavedSeconds, redeployTimeSeconds }) => {
              return (
                <TableRow key={token} data-token={token} onMouseOver={onTeamPanelBodyMouseOver} onMouseLeave={onTeamPanelBodyMouseLeave}>
                  <TableCell>
                    <div>
                      {User.canEdit() && <button type='button' className='TeamTable_team-name_button' data-token={token} onClick={onEditButtonPress}>{name}</button>}
                      {!User.canEdit() && <span className='TeamTable_team-name_text'>{name}</span>}
                      {!User.isTeamLead() && <span className='TeamTable_team-lead pull-right'>{teamLead}</span>}
                    </div>
                    <div>
                      <CopyTextButton
                        component='button'
                        text={`${window.location.origin}/${token}`}
                        copiedTooltip={{ offset: [ 0, 10 ] }}
                        hoverTooltip={{ text: 'Click to copy your Team URL', offset: [ 0, -5 ] }}
                        className='TeamTable_team-url text-code'
                        onPress={onTeamCopyTeamUrlPress(token)}
                      >
                        {`${window.location.origin}/${token}`}
                      </CopyTextButton>
                    </div>
                  </TableCell>
                  <TableCell>
                    {User.canEdit() && (
                      <InviteOrCopyButtons
                        className='TeamTable_invite-or-copy-buttons'
                        origin={origin}
                        token={token}
                        active={hoveredTeam ? hoveredTeam.token === token : false}
                        onInviteButtonPress={onSeatUserInviteButtonPress}
                        hideCopyButton
                      />
                    )}
                  </TableCell>
                  <TableCell className='TeamTable_product-column_cell'>
                    {sortByProductType(products).filter(({ enabled }) => enabled).map(({ productType }) => {
                      return <div key={productType}><ProductTypeLabel id={productType} /></div>;
                    })}
                  </TableCell>
                  <TableCell>
                    {sortByProductType(products).filter(({ enabled }) => enabled).map(({ productType, usage, limit }) => {
                      if (limit) {
                        return <div key={productType}>{usage} / {limit}</div>;
                      }

                      return <div key={productType}>{usage}</div>;
                    })}
                  </TableCell>
                  <TableCell>
                    <Tooltip offset={[ -10, -10 ]} text={<Fragment>Created at <DateTimeLabel timestamp={createdAt} displayAsTimestamp /><br /> by {createdBy}</Fragment>}>
                      <div>
                        <DateTimeLabel timestamp={createdAt} displayAsFromNow withJustNow />
                        <div className='TeamTable_created-label'>{`by ${createdBy}`}</div>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {renderTimeSavedCell(products, timeSavedSeconds.monthly)}
                  </TableCell>
                  <TableCell>
                    {hasJRebelEnabled(products) && redeployTimeSeconds && <RedeployMetricLabel value={redeployTimeSeconds} text='team default' />}
                  </TableCell>
                  <TableCell>
                    <ActionsButton>
                      {User.canEdit() && <DropdownMenuButton data-token={token} onPress={onEditButtonPress}>Edit team</DropdownMenuButton>}
                      <DropdownMenuButton routeTo={{ path: '/seat-users/team', parameters: { teamName: name } }}>View users</DropdownMenuButton>
                    </ActionsButton>
                  </TableCell>
                </TableRow>
              );
            })}
        />
      </Card>
    </Fragment>
  );

};

TeamTable.propTypes = {
  onTeamsUpdate: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  origin: PropTypes.oneOf([ 'DASHBOARD', 'TEAMS_PAGE' ]).isRequired
};

export default TeamTable;
