import 'react-app-polyfill/stable';
import 'normalize.css/normalize.css';
import 'font-awesome/css/font-awesome.min.css';
import 'rc-trigger/assets/index.css';
import 'react-dates/lib/css/_datepicker.css';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles.css';

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer as Element);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
