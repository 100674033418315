import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';

export default class RouterComponent extends Component {

  static propTypes = {
    navigate: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      state: PropTypes.object
    }).isRequired,
    params: PropTypes.object
  };

  getRoutePath() {
    return this.props.location.pathname;
  }

  getRoutingKey() {
    return this.props.params.rk || '';
  }

  getRouteMatchParameter(name) {
    return this.props.params[name];
  }

  getRouteStateParameter(key) {
    return (this.props.location.state || {})[key];
  }

  getRouteParameters() {
    return queryString.parse(this.props.location.search);
  }

  getRouteParameter(name) {
    return this.getRouteParameters()[name];
  }

  hasRouteParameter(name) {
    return this.getRouteParameter(name) !== undefined;
  }

  updateRouteParameter(name, value) {
    this.updateRouteParameters({ [name]: value || undefined });
  }

  updateRouteParameters(parameters) {
    this.routeTo({
      search: queryString.stringify({
        ...this.getRouteParameters(),
        ...parameters
      })
    });
  }

  routeWithStateParameters(pathname, state) {
    this.props.navigate(pathname, { state });
  }

  routeTo(route) {
    this.props.navigate(route);
  }

  routeToHomepage = () => {
    this.routeTo('/');
  };
}
