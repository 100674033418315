import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

const NavButton = ({ to, type, className, otherProps, children }) => {
  const navigate = useNavigate();
  const onPress = () => navigate(to);

  return (
    <Button type={type} onClick={onPress} className={className} {...otherProps}>
      {children}
    </Button>
  );
};

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  otherProps: PropTypes.object
};

export default NavButton;
