import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../../../components/Button';
import NavButton from '../../../../components/NavButton';
import User from '../../../../utils/User';
import SeatUserInviteForm from '../../SeatUserInviteForm';
import TeamForm from '../../TeamForm';
import TeamTable from '../../TeamTable';
import './styles.css';

export default class LatestTeams extends Component {

  static propTypes = {
    list: PropTypes.array.isRequired,
    onRef: PropTypes.func.isRequired,
    onAnyButtonPress: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  state = {
    modal: {
      active: null,
      team: null
    },
    hoveredTeam: null
  };

  onCreateTeamButtonPress = () => {
    this.setState({ modal: { active: 'teamForm', team: null } });
    this.props.onAnyButtonPress();
  };

  onModalSave = () => {
    this.closeModal();
    this.props.onSave();
  };

  closeModal = () => {
    this.setState({ modal: {} });
  };

  sortedTeams() {
    if (User.isTeamLead()) {
      return _(this.props.list)
        .sortBy('createdAt')
        .reverse()
        .value();
    }
    else {
      return _(this.props.list)
        .sortBy('createdAt')
        .reverse()
        .take(3)
        .value();
    }
  }

  render() {
    const { list } = this.props;

    return (
      <div className='DashboardLatestTeams' ref={this.props.onRef}>
        {this.state.modal.active === 'teamForm' && <TeamForm editedTeam={this.state.modal.team} onSave={this.onModalSave} onCancel={this.closeModal} />}
        {this.state.modal.active === 'seatUserInviteForm' && <SeatUserInviteForm team={this.state.modal.team} onSave={this.onModalSave} onCancel={this.closeModal} />}

        <div className='DashboardLatestTeams_header'>
          <div className='DashboardLatestTeams_header_title'>
            <h1>{User.isTeamLead() ? 'Your teams' : 'Latest teams' }</h1>
          </div>
          <div className='DashboardLatestTeams_header_buttons'>
            <div className='pull-right'>
              {User.isAccountAdmin() && <Button type='primary' onClick={this.onCreateTeamButtonPress}>Add team</Button>}
              {!User.isTeamLead() && (
                <NavButton to='/teams' type='secondary' className='DashboardLatestTeams_all-teams-button pull-right'>
                  {`See all ${list.length <= this.sortedTeams().length ? '' : `${list.length} `}teams`}
                </NavButton>
              )}
            </div>
          </div>
        </div>
        <div className='DashboardLatestTeams_list'>
          <TeamTable onTeamsUpdate={this.props.onSave} list={this.sortedTeams()} fetching={false} origin='DASHBOARD' />
        </div>
      </div>
    );
  }
}
