import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from '../../../../components/InfoTooltip';
import LicenseTypeLabel from '../../../../components/LicenseTypeLabel';
import WarningTooltip from '../../../../components/WarningTooltip';
import { licenseTypesSupportingProduct, sortByLicenseType } from '../../../../enums/LicenseType';
import User from '../../../../utils/User';
import UsageCircle from './../UsageCircle';
import './styles.css';

const LOW_USAGE_THRESHOLD = 0.75;

SeatUsage.propTypes = {
  seatUsage: PropTypes.array.isRequired,
  teams: PropTypes.array
};

function getTeamsWithLimitsForLicense(teams, licenseType) {
  return teams
    .filter(({ products }) => products
      .some(({ productType, enabled, limit }) => enabled && limit &&
        licenseTypesSupportingProduct(productType).some(lt => lt === licenseType)));
}

function getWarningText(teams, licenseType) {
  if (getTeamsWithLimitsForLicense(teams, licenseType).length > 0) {
    return `Availability for ${getTeamsWithLimitsForLicense(teams, licenseType).map(({ name }) => name).join(', ')} is capped by seat limits.`;
  }
  return null;
}

function renderIndicatorFooter(licenseType, reserved, total, teams) {
  if (User.isTeamLead()) {
    return (
      <div className='DashboardSeatUsage_indicator_footer'>
        <span>
          <span className='DashboardSeatUsage_number'>{total - reserved}</span>
          <span> available</span>
        </span>
        {getTeamsWithLimitsForLicense(teams, licenseType).length > 0 && (
          <span>
            <span className='DashboardSeatUsage_indicator_footer_separator' />
            <WarningTooltip size='m' text={getWarningText(teams, licenseType)} />
          </span>
        )}
      </div>
    );
  }

  if (total === 0) {
    const { text, link } = ({
      'JREBEL_STANDALONE': {
        text: 'Reload code changes instantly',
        link: 'https://jrebel.com/products/jrebel/'
      },

      'XREBEL_STANDALONE': {
        text: 'Real time insights, faster apps',
        link: 'https://jrebel.com/products/xrebel/'
      },

      'REBEL_SUITE': {
        text: 'Combination of development tools'
      }
    })[licenseType];

    return (
      <div className='DashboardSeatUsage_indicator_footer de-emphasized'>
        <div>{text}</div>
        {link && <a href={link} target='_blank' rel='noopener noreferrer'>Learn more</a>}
      </div>
    );
  }
  else if (reserved === total) {
    return (
      <div className='DashboardSeatUsage_indicator_footer'>
        <div>Get more seats at</div>
        <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>
      </div>
    );
  }

  return (
    <div className='DashboardSeatUsage_indicator_footer'>
      <span className='DashboardSeatUsage_number'>{total - reserved}</span>
      <span> available</span>

      {User.canEdit() && reserved / total < LOW_USAGE_THRESHOLD && (
        <span>
          <span className='DashboardSeatUsage_indicator_footer_separator' />
          <WarningTooltip to='/teams' size='m' text='This license is not being used to its full potential. Invite developers to teams! Press to manage teams.' />
        </span>
      )}
    </div>
  );
}

export default function SeatUsage({ seatUsage, teams }) {
  return (
    <div className={classNames('DashboardSeatUsage', User.isTeamLead() ? 'team-lead' : '')}>
      <h1>{User.isTeamLead() ? 'Available seats by license' : 'Seat usage by license'}</h1>

      <div className='DashboardSeatUsage_indicators'>
        {sortByLicenseType(seatUsage).map(({ licenseType, reserved, total }, i) => {
          return (
            User.isTeamLead() ? (
              <div key={licenseType} className='DashboardSeatUsage_indicators_team-lead_column'>
                <h2 className='DashboardSeatUsage_indicator_header'>
                  <LicenseTypeLabel id={licenseType} styled />
                  {licenseType === 'REBEL_SUITE' && (
                    <InfoTooltip iconClassName='DashboardSeatUsage_indicator_header_tooltip' size='l' text='A RebelSuite license allows activating both JRebel and XRebel.' />
                  )}
                </h2>
                {renderIndicatorFooter(licenseType, reserved, total, teams)}
              </div>
            ) : (
              <div key={licenseType} className='DashboardSeatUsage_indicators_column'>
                <div className='DashboardSeatUsage_indicator'>
                  <h2 className='DashboardSeatUsage_indicator_header'>
                    <LicenseTypeLabel id={licenseType} styled />
                    {licenseType === 'REBEL_SUITE' && (
                      <InfoTooltip iconClassName='DashboardSeatUsage_indicator_header_tooltip' size='l' text='A RebelSuite license allows activating both JRebel and XRebel.' />
                    )}
                  </h2>
                  <UsageCircle
                    radius={51}
                    strokeWidth={4}
                    lowUsageThreshold={LOW_USAGE_THRESHOLD}
                    usage={reserved}
                    total={total}
                    numberClassName='DashboardSeatUsage_number'
                    withNumber
                  />
                  {renderIndicatorFooter(licenseType, reserved, total)}
                </div>
                {i < seatUsage.length - 1 && <div className='DashboardSeatUsage_indicator-separator' />}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
