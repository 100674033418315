import React, { Fragment, useState } from 'react';
import './styles.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SsoForm from '../SsoForm';
import Card from '../../../../components/Card';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import User from '../../../../utils/User';
import Toggle from '../../../../components/Toggle';
import Button from '../../../../components/Button';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import Tooltip from '../../../../components/Tooltip';
import DeleteModal from '../DeleteModal';
import ActivateModal from '../ActivateModal';
import { translateStatusCode } from '../labels';
import SsoDetails from '../SsoDetails';
import { ActionsButton } from '../../../../components/ActionsButton';
import { DropdownMenuButton } from '../../../../components/Dropdown';

const SsoList = ({ list, onListUpdate, fetching, onVerify, onShowNotification }) => {

  const [ modal, setModal ] = useState({ active: null, sso: null });
  const [ enableAction, setEnableAction ] = useState(null);
  const fetch = () => {
    onListUpdate();
  };

  const openModalForRow = (form, token) => {
    onShowNotification(null);
    setModal(({ active: form, sso: _(list).find(({ uuid }) => uuid === token) }));
  };
  const onEditButtonPress = ({ currentTarget }) => {
    openModalForRow('ssoForm', currentTarget.getAttribute('data-token'));
  };

  const onViewButtonPress = ({ currentTarget }) => {
    openModalForRow('ssoDetails', currentTarget.getAttribute('data-token'));
  };

  const onVerifyButtonPress = ({ currentTarget }) => {
    const uuid = currentTarget.getAttribute('data-token');
    onVerify(uuid);
  };

  const onDeleteButtonPress = ({ currentTarget }) => {
    openModalForRow('deleteModal', currentTarget.getAttribute('data-token'));
  };

  const onActivate = (token, enable) => {
    onShowNotification(null);
    setModal(({ active: 'activateModal', sso: _(list).find(({ uuid }) => uuid === token) }));
    setEnableAction(enable);
  };

  const closeModal = () => {
    setModal({});
    setEnableAction(null);
  };

  const onModalSave = () => {
    closeModal();
    fetch();
  };

  const canView = () => {
    return User.isAccountAdmin() || User.isAccountViewAdmin();
  };

  const canEdit = () => {
    return User.isAccountAdmin();
  };

  return (
    <Fragment>
      {modal.active === 'ssoForm' && (
        <SsoForm sso={modal.sso} onSave={onModalSave} onCancel={closeModal} onVerify={onVerify} onShowNotification={onShowNotification} />)}
      {modal.active === 'deleteModal' && (
        <DeleteModal sso={modal.sso} onSuccess={onModalSave} onCancel={closeModal} onShowNotification={onShowNotification} />)}
      {modal.active === 'activateModal' && (
        <ActivateModal sso={modal.sso} enable={enableAction} onSuccess={onModalSave} onCancel={closeModal} onShowNotification={onShowNotification} />)}
      {modal.active === 'ssoDetails' && (
        <SsoDetails sso={modal.sso} onCancel={closeModal} />)}

      <Card>
        <Table
          header={
            <TableRow>
              <th className='SSoList_event-name'>SSO Type</th>
              <th className='SSoList_send-email'>Client  Name</th>
              <th className='SSoList_send-email'>Updated by</th>
              <th className='SSoList_actions-column' />
              <th />
            </TableRow>
          }
          body={fetching ?
            <TableRow className='SsoUserSettingsPage_fetching'>
              <TableCell colSpan='5'>
                Fetching data...
              </TableCell>
            </TableRow> :
            <Fragment>
              {canView() && list
                .map(({ uuid, clientName, clientId, status, updatedAt, updatedBy }) => {
                  return (
                    <TableRow key={uuid}>
                      <TableCell className='SsoList_type'>
                        OpenID Connect
                        <Fragment>
                          { (status === 'VERIFYING' || status === 'DRAFT') &&
                            <div className='SsoList_status-label'>
                              <div className='SsoList_status-label-text'>{translateStatusCode(status)}</div>
                            </div>
                          }
                        </Fragment>
                      </TableCell>
                      <TableCell>
                        {clientName || '-'}
                        {(clientId) &&
                          <Fragment>
                            <br />
                            ({clientId})
                          </Fragment>}
                      </TableCell>
                      <TableCell>
                        <Tooltip offset={[ -10, -10 ]} text={<Fragment>Updated at <DateTimeLabel timestamp={updatedAt} displayAsTimestamp /><br /> by {updatedBy}</Fragment>}>
                          <div>
                            <DateTimeLabel timestamp={updatedAt} displayAsFromNow withJustNow />
                            <div className='SsoList_created-label'>{`by ${updatedBy}`}</div>
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className='SSoList_actions-column'>
                        {(status === 'VERIFIED' || status === 'ACTIVE') &&
                          <Fragment>
                            <Tooltip offset={[ -10, -10 ]} text='Enable / Disable'>
                              <Toggle
                                enabled={status === 'ACTIVE'}
                                onChange={enabled => onActivate(uuid, enabled)}
                                className='pull-right SsoList_toggle-all'
                                editable={canEdit() && (status === 'VERIFIED' || status === 'ACTIVE')}
                              />
                            </Tooltip>
                          </Fragment>
                        }
                        {canEdit() && status === 'VERIFYING' && <Button className='secondary' data-token={uuid} onClick={onVerifyButtonPress}>Verify now</Button>}
                        {canEdit() && status === 'DRAFT' && <Button className='secondary' data-token={uuid} onClick={onEditButtonPress}>Edit SSO</Button>}
                      </TableCell>
                      <TableCell className='SSoList_actions-column2'>
                        <ActionsButton className='pull-right'>
                          <DropdownMenuButton data-token={uuid} onPress={onViewButtonPress}>View details</DropdownMenuButton>
                          {canEdit() && <DropdownMenuButton data-token={uuid} onPress={onEditButtonPress}>Edit</DropdownMenuButton>}
                          {canEdit() && <DropdownMenuButton data-token={uuid} onPress={onDeleteButtonPress}>Delete</DropdownMenuButton>}
                        </ActionsButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {list.length === 0 && <Fragment>
                <TableRow>
                  <TableCell colspan='5'>
                    <div className='empty-table-wrapper'>
                      <div className='empty-state-message'>
                        <div className='sso-icon SSoList_no-results_image' />
                        <div className='text-grp'>
                          <b className='header-text'>No SSO configured yet</b>
                          <div className='some-kind-of'>Configure SSO to start streamlining your login experience</div>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </Fragment>
              }
            </Fragment>
          }
        />
      </Card>
    </Fragment>
  );

};

SsoList.propTypes = {
  onListUpdate: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onShowNotification: PropTypes.func.isRequired
};
export default SsoList;
