import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

TokenLinkResultMessage.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  negative: PropTypes.bool.isRequired
};

export default function TokenLinkResultMessage({ title, body, negative }) {
  return (
    <div className='TokenLinkResultMessage'>
      <h1 className={classNames('TokenLinkResultMessage_title', { 'negative': negative })}>{title}</h1>
      <div className='TokenLinkResultMessage_body'>{body}</div>
      <div className='TokenLinkResultMessage_footer'>Need help? <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a></div>
    </div>
  );
}
