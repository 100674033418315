import PropTypes from 'prop-types';
import React from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import { isTeamLeadAccountAdmin } from './selectors';
import './styles.css';

TeamLeadAddedModal.propTypes = {
  teamLead: PropTypes.string.isRequired,
  adminUsers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default function TeamLeadAddedModal({ teamLead, adminUsers, onClose }) {
  return (
    <Modal
      title='Success! Your team is now ready.'
      className='TeamFormTeamLeadAddedModal'
      size='m'
      buttons={<ModalButton type='primary' className='pull-right' onClick={onClose}>Got it!</ModalButton>}
      onClose={onClose}
    >
      <span>We sent an email {isTeamLeadAccountAdmin(teamLead, adminUsers) ? 'alert' : 'invitation'} to <strong>{teamLead}</strong> </span>
      <span>who can now invite developers to activate JRebel & XRebel.</span>
    </Modal>
  );
}
