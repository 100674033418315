import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../../components/Button';
import Card, { CardBody, CardFooter } from '../../../../../components/Card';
import UnitLabel from '../../../../../components/UnitLabel';
import MoneyLabel from '../MoneyLabel';
import jrebelLogo from './jrebelLogo.svg';
import './styles.css';

SavingsNumbers.propTypes = {
  savingsHeading: PropTypes.string,
  timeSavedPerDeveloperDailyMinutes: PropTypes.number.isRequired,
  timeSavedYearlyHours: PropTypes.number.isRequired,
  moneySavedYearly: PropTypes.number.isRequired,
  currency: PropTypes.oneOf([ 'USD', 'EUR' ])
};

export function SavingsNumbers(props) {
  const {
    savingsHeading,
    timeSavedPerDeveloperDailyMinutes,
    timeSavedYearlyHours,
    moneySavedYearly,
    currency
  } = props;

  return (
    <div className='RoiCalculatorPanel_savings-numbers'>
      <h5>{savingsHeading}</h5>
      <span className='RoiCalculatorPanel_savings-numbers_number'>
        <UnitLabel value={timeSavedPerDeveloperDailyMinutes} unit='minute' />
      </span>
      <span> per developer every day. </span>
      <span className='RoiCalculatorPanel_savings-numbers_number'>
        <UnitLabel value={timeSavedYearlyHours} unit='hour' />
      </span>
      <span> of development time per year. </span>
      <span className='RoiCalculatorPanel_savings-numbers_number'>
        <MoneyLabel value={moneySavedYearly} currency={currency} />
      </span>
      <span> in total per year.</span>
    </div>
  );
}

RoiSummary.propTypes = {
  roiSummaryText: PropTypes.string.isRequired,
  roiSummaryDays: PropTypes.number.isRequired,
  extraFooterContent: PropTypes.element
};

export function RoiSummary(props) {
  const {
    roiSummaryText,
    roiSummaryDays
  } = props;

  return (
    <div className='RoiSummary'>
      <img className='RoiSummary_jrebel-logo' src={jrebelLogo} alt='' />
      <div className='RoiSummary_roi-text'>
        {roiSummaryText} <span><UnitLabel valueClass='RoiSummary_roi-number' value={roiSummaryDays} unit='day' /></span>
      </div>
    </div>
  );
}

RoiCalculatorPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  headerLeftPart: PropTypes.node,
  headerButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  headerRightPart: PropTypes.element,
  hideCentralSection: PropTypes.bool.isRequired,
  centralSection: PropTypes.node.isRequired,
  footerSection: PropTypes.element,

  timeSavedPerDeveloperDailyMinutes: PropTypes.number.isRequired,
  timeSavedYearlyHours: PropTypes.number.isRequired,
  moneySavedYearly: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired
};

export default function RoiCalculatorPanel(props) {
  const {
    className,
    heading,
    headerLeftPart,
    headerButton,
    headerRightPart,
    hideCentralSection,
    centralSection,
    footerSection
  } = props;

  return (
    <Card className={classNames('RoiCalculatorPanel', className)}>
      <CardBody>
        <div className='RoiCalculatorPanel_header'>
          <div className='RoiCalculatorPanel_header-left-part'>
            <h5 className='RoiCalculatorPanel_header-left-part_title'>{heading}</h5>
            {headerLeftPart}
          </div>
          {headerButton && (
            <div className='RoiCalculatorPanel_header-right-part'>
              <Button type='link' className='RoiCalculatorPanel_header-right-part_button' onClick={headerButton.onClick}>{headerButton.text}</Button>
            </div>
          )}
          {headerRightPart}
        </div>
        {!hideCentralSection && (
          <div>
            <div className='RoiCalculatorPanel_central-section'>
              {centralSection}
            </div>
          </div>
        )}
      </CardBody>
      <CardFooter className='RoiCalculatorPanel_footer'>
        {footerSection}
      </CardFooter>
    </Card>
  );
}
