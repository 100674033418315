import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ComponentPropsWithoutRef } from 'react';

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default function Form({ className, onSubmit, children, ...otherProps }: ComponentPropsWithoutRef<'form'>) {
  return (
    <form className={classNames('Form', className)} onSubmit={onSubmit} {...otherProps}>
      {children}
    </form>
  );
}
