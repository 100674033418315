import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import './styles.css';

RedeployMetricLabel.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  text: PropTypes.string.isRequired
};

export default function RedeployMetricLabel({ value, text }) {
  return (
    <Fragment>
      {value} <span className='RedeployMetricLabel_text'>{text}</span>
    </Fragment>
  );
}
