import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import { hasOfflineProducts } from './selectors';

export default class DeleteModal extends Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false
  };

  onOkButtonPress = () => {
    this.setState({ saving: true });

    Api.del('/seat-users', { email: this.props.user.email }, this.onSuccess);
  };

  onSuccess = () => {
    this.props.onSuccess(this.props.user.email);
  };

  isPendingBlock() {
    return hasOfflineProducts(this.props.user);
  }

  render() {
    return (
      <Modal
        title='Delete user'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onOkButtonPress}>Delete</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <div>
          <p>
            You are about to delete <strong>{this.props.user.email}</strong>.
          </p>
          <p>
            This action cannot be reversed. Past time savings for the user will still contribute to team and account statistics.
          </p>
          <p>Are you sure you want to continue?</p>
        </div>
      </Modal>
    );
  }
}
