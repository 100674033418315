import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import { getHavingOtherDomainThanListOrForm } from '../selectors';
import './styles.css';
import Card, { CardBody } from '../../../components/Card';

export default class EnableDomainWhitelistingModal extends Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    domains: PropTypes.arrayOf(
      PropTypes.shape({
        domain: PropTypes.string.isRequired
      })
    ).isRequired,
    domain: PropTypes.string,
    invitedSeatUsers: PropTypes.array.isRequired
  };

  state = {
    saving: false
  };

  saveFormAndCloseModal = () => {
    this.setState({ saving: true });

    this.props.onSave();
  };

  formatDomainsAsHumanReadableList() {
    const list = this.props.domains.map(({ domain }) => domain).concat(this.props.domain ? [ this.props.domain ] : []);

    return (
      <Fragment>
        {list.map((domain, i) => (
          <Fragment key={domain}>
            {i > 0 && list.length > i + 1 && ', '}
            {i > 0 && list.length === i + 1 && ' and '}
            <strong>{domain}</strong>
          </Fragment>
        ))}
      </Fragment>
    );
  }

  render() {
    return (
      <Modal
        title='Turn on whitelist'
        size='l' // TODO remove modal in CLS-786??
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='primary' disabled={this.state.saving} onClick={this.saveFormAndCloseModal}>Turn on whitelist</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          Activation will only be allowed with {this.formatDomainsAsHumanReadableList()}.<br />
          Users who have already activated will not be affected, use{' '}
          <a href='https://manuals.jrebel.com/zt-license-management/users/#blocking-users' rel='noopener noreferrer' target='_blank'>Blocking</a>{' '}
          if needed.
        </p>
        <p>Adding this email domain verification will prevent the following pending users from acquiring a seat:</p>

        <Card>
          <CardBody className='EnableDomainWhitelistingModal_seat-users-list pre-scrollable'>
            {getHavingOtherDomainThanListOrForm(this.props.invitedSeatUsers, this.props.domains, this.props.domain).map(({ email }) => <div key={email}>{email}</div>)}
          </CardBody>
        </Card>

        <p className='EnableDomainWhitelistingModal_confirmation-text'>Are you sure?</p>
      </Modal>
    );
  }
}
