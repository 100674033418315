import emailValidator from 'email-validator';
import { isDomainBlacklisted } from '../../selectors';
import { isInTheFuture } from '../selectors';

export function getEmailValidationMessage(email, domainWhitelistingEnabled, whitelistedDomains) {
  if (!emailValidator.validate(email)) {
    return 'Please enter a valid email address.';
  }

  if (domainWhitelistingEnabled && isDomainBlacklisted(email, whitelistedDomains)) {
    return 'This email domain is not allowed by your licensing administrator.';
  }

  return null;
}

export function getAvailableSeatCount(users, seatUsage, seatUsers, license) {
  const exportableSeatCount = license.seatCount - Object.values(license.exportedSeatCounts).reduce((sum, seatCount) => sum + seatCount);

  if (isInTheFuture(license.startDate)) {
    return exportableSeatCount - users.length;
  }
  else {
    const { reserved, total } = seatUsage.find(({ licenseType }) => licenseType === license.type);
    const onlineHostedSeatCountToBeExported = users.filter(user => hasOnlineHostedSeatReservation(user.email, seatUsers, license)).length;

    return Math.min(exportableSeatCount, total - reserved + onlineHostedSeatCountToBeExported) - users.length;
  }
}

function hasOnlineHostedSeatReservation(email, seatUsers, license) {
  return getHostedSeatReservation(email, seatUsers, license) && !getHostedSeatReservation(email, seatUsers, license).offline;
}

export function getHostedSeatReservation(email, seatUsers, license) {
  const seatUser = seatUsers.find(seatUser => seatUser.email === email);

  if (seatUser && seatUser.status === 'ACTIVE') {
    return seatUser.seatReservations.find(seatReservation => seatReservation.licenseType === license.type);
  }

  return undefined;
}
