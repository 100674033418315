import classNames from 'classnames';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { ActionsButton } from '../../../components/ActionsButton';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../components/Dropdown';
import InfoTooltip from '../../../components/InfoTooltip';
import LicenseTypeLabel from '../../../components/LicenseTypeLabel';
import Page from '../../../components/Page';
import RouterComponent from '../../../components/RouterComponent';
import TabBar from '../../../components/TabBar';
import Table, { TableCell, TableRow } from '../../../components/Table';
import UnitLabel from '../../../components/UnitLabel';
import { isStandalone } from '../../../enums/LicenseType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { withRouter } from '../../../utils/withRouter';
import Alerts from './Alerts';
import ExportDedicatedForm from './ExportDedicatedForm';
import ExportManagedForm from './ExportManagedForm';
import { filterOrders, getAvailableSeatCount, groupByOrders, hasExpired, sortLicenses, toEndOfDay } from './selectors';
import './styles.css';

const supportLevelLabels = {
  PROFESSIONAL: 'RebelCare Professional',
  ENTERPRISE: 'RebelCare Enterprise'
};

export default withRouter(class LicensePage extends RouterComponent {

  state = {
    licenses: [],
    alert: {
      active: null,
      email: null
    },
    modal: {
      active: null,
      license: null
    },
    seatUsage: [],
    fetchingSeatUsage: User.canEdit(),
    fetchingLicenses: true,

    managedExportEnabled: false
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    Api.get('/licenses', ({ list }) => {
      this.setState({ licenses: list, fetchingLicenses: false });
    });

    Api.get('/account', ({ managedExportEnabled }) => {
      this.setState({ managedExportEnabled });
    });

    if (User.canEdit()) {
      Api.get('/statistics', ({ seatUsage }) => {
        this.setState({ seatUsage, fetchingSeatUsage: false });
      });
    }
  }

  openModalForRow = ({ currentTarget }) => {
    this.setState(state => ({
      alert: {},
      modal: {
        active: currentTarget.getAttribute('data-modal-id'),
        license: state.licenses
          .filter(({ orderItemId }) => orderItemId === currentTarget.getAttribute('data-order-item-id'))
          .find(({ purpose }) => purpose === currentTarget.getAttribute('data-purpose'))
      }
    }));
  };

  onExportManagedModalSave = exportedTo => {
    this.closeModal();
    this.fetch();
    this.setState({ alert: { active: 'export', email: exportedTo } });
  };

  closeModal = () => {
    this.setState({ modal: {} });
  };

  getOrders() {
    return groupByOrders(sortLicenses(this.state.licenses));
  }

  getFilteredOrders(tab) {
    return filterOrders(this.getOrders(), tab);
  }

  isTabBarVisible() {
    return !this.state.fetchingLicenses && this.getFilteredOrders().length > 0 && this.getFilteredOrders('expired').length > 0;
  }

  render() {
    return (
      <Page
        className='LicensePage'
        alert={<Alerts alert={this.state.alert} />}
        title='Licenses'
      >
        {this.state.modal.active === 'exportDedicatedForm' && (
          <ExportDedicatedForm license={this.state.modal.license} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'exportManagedForm' && (
          <ExportManagedForm license={this.state.modal.license} onSave={this.onExportManagedModalSave} onCancel={this.closeModal} />
        )}
        {this.isTabBarVisible() && (
          <TabBar>
            <NavLink end to=''>
              Valid licenses
            </NavLink>
            <NavLink to='expired'>
              Expired licenses
            </NavLink>
          </TabBar>
        )}
        {(this.state.fetchingLicenses || this.state.fetchingSeatUsage) ?

          <div>{this.state.licenses.length === 0 ? 'No licenses' : 'Fetching data...'}</div> :

          (this.isTabBarVisible() ? this.getFilteredOrders(this.getRoutingKey()) : this.getOrders()).map(({ groupOrderId, licenses }, i) => {
            return (
              <Card key={i} className='LicensePage_order-panel'>
                <Table
                  header={
                    <Fragment>
                      <TableRow>
                        <th className='LicensePage_order-number' colSpan='6'>{groupOrderId === 'EVALUATION' ? 'Evaluation' : `Order #${groupOrderId}`}</th>
                      </TableRow>
                      <TableRow>
                        <th className='LicensePage_product-column'>Product</th>
                        <th className='LicensePage_seat-column'>Seats</th>
                        <th className='LicensePage_start-date-column'>Start date</th>
                        <th className='LicensePage_end-date-column'>End date</th>
                        <th className='LicensePage_actions-column' />
                      </TableRow>
                    </Fragment>
                  }
                  body={licenses
                    .map((license, i) => {
                      const {
                        orderId, orderItemId, type, seatCount, startDate, endDate, effectiveEndDate, purpose, supportLevel, exportedSeatCounts: { MANAGED, DEDICATED }
                      } = license;
                      const canExport = User.canEdit() && purpose !== 'COMMERCIAL_MIGRATION' && !hasExpired(endDate);
                      const hasExportedDedicated = DEDICATED > 0;
                      const shouldLabelAsExpired = hasExpired(endDate) && (this.getRoutingKey() !== 'expired' || !this.isTabBarVisible());
                      const shouldShowEffectiveEndDate = hasExpired(endDate) && !hasExpired(effectiveEndDate) && (this.getRoutingKey() !== 'expired' || !this.isTabBarVisible());
                      const canExportManaged = canExport && getAvailableSeatCount(this.state.seatUsage, license) > 0;

                      return (
                        <Fragment key={`${orderItemId} ${purpose}`}>
                          <TableRow className={classNames('LicensePage_row', { 'de-emphasized': shouldLabelAsExpired })}>
                            <TableCell><LicenseTypeLabel id={type} />{purpose === 'COMMERCIAL_MIGRATION' && ' (migration)'}{shouldLabelAsExpired && ' (expired)'}
                            </TableCell>
                            <TableCell>
                              {MANAGED === 0 ?

                                seatCount :

                                <span>
                                  <span>{seatCount - MANAGED} / {seatCount}</span>
                                  <InfoTooltip size='s' text={<span><UnitLabel value={MANAGED} unit='seat' /> have been exported for your Rebel Licenses On-Premise.</span>} />
                                </span>}
                            </TableCell>
                            <TableCell><DateTimeLabel timestamp={startDate} /></TableCell>
                            <TableCell>
                              {!shouldShowEffectiveEndDate && <DateTimeLabel timestamp={endDate} />}
                              {shouldShowEffectiveEndDate && (
                                <span>
                                  <strike>
                                    <DateTimeLabel timestamp={endDate} />
                                  </strike>
                                  <br />
                                  <DateTimeLabel timestamp={effectiveEndDate} />
                                  <InfoTooltip size='l' text='This license has been granted a grace period. Hosted seats are useable until the end of the grace period.' />
                                </span>
                              )}
                              {!hasExpired(endDate) && (
                                <div className='LicensePage_expires-in-label'>
                                  <DateTimeLabel timestamp={toEndOfDay(endDate)} displayAsFromNow />
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              <div className='pull-right'>
                                <div className='LicensePage_export-managed'>
                                  {canExport && purpose !== 'EVALUATION' && this.state.managedExportEnabled && (
                                    <Button
                                      type='secondary'
                                      className={classNames('LicensePage_export-managed-button', { disabled: !canExportManaged })}
                                      data-modal-id='exportManagedForm'
                                      disabled={!canExportManaged}
                                      data-order-item-id={orderItemId}
                                      data-purpose={purpose}
                                      onClick={this.openModalForRow}
                                    >
                                      Export for On-Premise
                                    </Button>
                                  )}
                                </div>
                                <div className='LicensePage_export-dedicated'>
                                  {(canExport || hasExportedDedicated) && isStandalone(type) && (
                                    <ActionsButton>
                                      {canExport && (
                                        <DropdownMenuButton
                                          data-modal-id='exportDedicatedForm'
                                          data-order-item-id={orderItemId}
                                          data-purpose={purpose}
                                          disabled={seatCount - MANAGED - DEDICATED === 0}
                                          onPress={this.openModalForRow}
                                        >
                                          Export individual licenses
                                        </DropdownMenuButton>
                                      )}
                                      {hasExportedDedicated && (
                                        <DropdownMenuButton routeTo={{ path: '/seat-users/individual', parameters: { orderId } }}>
                                          Users with individual licenses
                                        </DropdownMenuButton>
                                      )}
                                    </ActionsButton>
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                          {i === licenses.length - 1 && supportLevel && supportLevel !== 'STANDARD' && (
                            <TableRow>
                              <TableCell colSpan='6'>
                                {supportLevelLabels[supportLevel]} (<a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>)
                              </TableCell>
                            </TableRow>
                          )}
                        </Fragment>
                      );
                    })}
                />
              </Card>
            );
          })}
      </Page>
    );
  }
});
