import { Bar } from '@nivo/bar';
import { useTheme } from '@nivo/core';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import Api from '../../../../../utils/Api';
import EventEmitter from '../../../../../utils/EventEmitter';
import { formatNumberWithoutUnits, formatNumberWithUnits } from '../../../selectors';
import TeamRedeployTimeModal from '../../../TeamRedeployTimeModal';
import './styles.css';

const chartTransforms = {
  TIME: {
    toChartValue: value => moment.duration(value, 'seconds').asHours(),
    toChartLabel: _value => {
      const { value, unit } = formatNumberWithUnits(_value, 'hour');
      return `${value} ${unit}`;
    }
  },
  TIME_SIMPLE: {
    toChartValue: value => moment.duration(value, 'seconds').asHours(),
    toChartLabel: formatNumberWithoutUnits
  },
  REDEPLOYS: {
    toChartValue: _.identity,
    toChartLabel: _.identity
  }
};

export default class ProductMetricsChart extends Component {

  static propTypes = {
    metric: PropTypes.oneOf([ 'TIME', 'REDEPLOYS' ]),
    metricTransform: PropTypes.oneOf([ 'TIME', 'TIME_SIMPLE', 'REDEPLOYS' ]),
    granularity: PropTypes.oneOf([ 'DAY', 'WEEK', 'MONTH', null ]),
    team: PropTypes.string.isRequired,
    // start and end date are null when the user is in the middle of reconfiguring the date range.
    startDate: PropTypes.object, // TODO: apply moment prop types validator
    endDate: PropTypes.object,
    headerTitle: PropTypes.string,
    chartWidth: PropTypes.number.isRequired,
    chartHeight: PropTypes.number.isRequired,
    spinnerStyle: PropTypes.oneOf([ 'GREEN GEARS', 'NONE' ]),
    leftAxisTickValues: PropTypes.number,
    chartMargin: PropTypes.object
  };

  static defaultProps = {
    chartWidth: 850,
    chartHeight: 525,
    spinnerStyle: 'GREEN GEARS'
  };

  teamRedeployTimeUpdateListenerToken;

  state = {

    chart: {
      data: null,
      granularity: null,
      maxValue: 0,
      toLabel: null
    },

    initializingData: true,
    fetchingData: true
  };

  componentDidMount() {
    this.teamRedeployTimeUpdateListenerToken = EventEmitter.addListener(TeamRedeployTimeModal.savedEventId, this.fetchData);
    if (this.props.startDate !== null &&
      this.props.endDate !== null &&
      this.props.granularity !== null) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.teamRedeployTimeUpdateListenerToken.remove();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate !== null &&
      this.props.endDate !== null &&
      this.props.granularity !== null &&
      (
        prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate ||
        prevProps.team !== this.props.team ||
        prevProps.granularity !== this.props.granularity ||
        prevProps.metric !== this.props.metric ||
        prevProps.metricTransform !== this.props.metricTransform
      )
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ fetchingData: true });

    const { granularity, team, metric, metricTransform, startDate, endDate } = this.props;
    const toLabel = chartTransforms[metricTransform].toChartLabel;
    const toChartValue = chartTransforms[metricTransform].toChartValue;

    const params = {
      startDate: this.formatDate(startDate),
      endDate: this.formatDate(endDate),
      granularity,
      metric
    };

    (async () => {
      const teamDataPromise = this.getProductMetricsDataPromise({ ...params, teamToken: team });
      const totalDataPromise = team ? this.getProductMetricsDataPromise(params) : teamDataPromise;

      return { teamData: await teamDataPromise, totalData: await totalDataPromise };
    })().then(({ teamData, totalData }) => {
      this.setState({
        chart: {
          data: teamData.list.map(({ date, value }) => ({ date, value: toChartValue(value) })),
          granularity,
          maxValue: toChartValue(_(totalData.list).map('value').max()),
          toLabel
        },
        initializingData: false,
        fetchingData: false
      });
    });
  };

  getProductMetricsDataPromise(params) {
    return new Promise(resolve => {
      Api.get(`/reports/product-metrics?${queryString.stringify(params)}`, resolve);
    });
  }

  formatDate(moment) {
    return moment.format('YYYY-MM-DD');
  }

  formatLeftAxisLabel = value => {
    return this.state.chart.toLabel(value);
  };

  renderBottomAxisTick = ({ textAnchor, textBaseline, value, x, y }) => {
    const date = moment.utc(value);
    const { data, granularity } = this.state.chart;
    const showYear = moment.utc().year() !== date.year() || (moment.utc(data[0].date).year() !== moment.utc().year());
    let monthLabel;
    let dateLabel;
    let yearLabel;

    if (granularity === 'WEEK') {
      const endDate = moment.utc(value).add({ days: 6 });
      yearLabel = `20${_([ date, endDate ]).map(date => date.format('YY')).uniq().join('-')}`;
      monthLabel = _([ date, endDate ]).map(date => date.format('MMM')).uniq().join('-');
      dateLabel = `${date.date()}-${endDate.date()}`;
    }
    else {
      yearLabel = date.year();
      monthLabel = date.format('MMM');
      dateLabel = date.date();
    }

    return (
      <g transform={`translate(${x}, ${y})`} style={{ opacity: 1 }}>
        <line x1='0' x2='0' y1='0' y2='0' style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
        <text
          dominantBaseline={textBaseline} textAnchor={textAnchor} transform='translate(0,20) rotate(0)'
          style={{ fontFamily: 'sans-serif', fontSize: '11px', fill: 'rgb(51, 51, 51)' }}
        >
          <tspan x={0} dy={0} className={classNames('ProductMetricChart_label', { 'main-granularity': granularity === 'MONTH' })}>{monthLabel}</tspan>
          {granularity !== 'MONTH' && <tspan x={0} dy={17} className='ProductMetricChart_label main-granularity'>{dateLabel}</tspan>}
          {showYear && <tspan x={0} dy={17} className='ProductMetricChart_label'>{yearLabel}</tspan>}
        </text>
      </g>
    );
  };

  formatTooltip = ({ value, indexValue }) => {
    const theme = useTheme();
    const date = moment.utc(indexValue);
    const isCurrentYear = moment().year() === date.year();
    let dateLabel;

    if (this.state.chart.granularity === 'WEEK') {
      const endDate = moment.utc(date).add({ days: 6 });
      const isSameMonth = date.month() === endDate.month();
      const isSameYear = date.year() === endDate.year();

      const startDateFormat = `MMMM D${isSameYear ? '' : ', YYYY'}`;
      const endDateFormat = `${isSameMonth ? '' : 'MMMM '}D${isSameYear && isCurrentYear ? '' : ', YYYY'}`;

      dateLabel = `${date.format(startDateFormat)}–${endDate.format(endDateFormat)}`;
    }
    else {
      dateLabel = date.format(`MMMM${this.state.chart.granularity !== 'MONTH' ? ' D' : ''}${isCurrentYear ? '' : ', YYYY'}`);
    }

    // note: The outermost wrapper div used to be provided by the parent nivo component.
    return (
      <div
        style={{
          background: 'rgb(43, 46, 54)',
          color: 'inherit',
          fontSize: 'inherit',
          borderRadius: '2px',
          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
          padding: '5px 9px',
          opacity: 0.75
        }}
      >
        <div style={{ ...theme.tooltip.basic, padding: '6px' }}>
          <div className='ProductMetricChart_tooltip-text'>
            <div>{dateLabel}</div>
            <div className='ProductMetricChart_tooltip-text_value'>{this.state.chart.toLabel(value)}</div>
          </div>
        </div>
      </div>
    );
  };

  getBarPadding(chartWidthOuter, numberOfBars) {
    const chartWidthInner = chartWidthOuter - 100;
    // Calculate the bar padding such that we will always get the consistent bar width that we want.
    const BAR_WIDTH = 12;
    const widthPerColumn = chartWidthInner / numberOfBars;

    // Safeguard against degenerate graphs where there is a very large number of bars that may overlap.
    // Such as "all time" metrics with many months.
    const barWidth = Math.min(BAR_WIDTH, widthPerColumn * 0.9);
    return 1.0 - barWidth / widthPerColumn;
  }

  render() {
    return (
      <div className='ProductMetricsChart'>
        {!this.state.initializingData && (
          <>
            <div className='ProductMetricChart_header'>
              <span className='ProductMetricChart_header_title'>{this.props.headerTitle}</span>
              {this.state.fetchingData && this.props.spinnerStyle === 'GREEN GEARS' && (
                <Fragment>
                  <i className='fa fa-cog fa-spin ProductMetricChart_header_spinner_big' aria-hidden='true' />
                  <i className='fa fa-cog fa-spin ProductMetricChart_header_spinner_small' aria-hidden='true' />
                </Fragment>
              )}
            </div>
            <Bar
              data={this.state.chart.data}
              indexBy='date'
              width={this.props.chartWidth}
              height={this.props.chartHeight}
              enableLabel={false}
              motionStiffness={120}
              padding={this.getBarPadding(this.props.chartWidth, this.state.chart.data.length)}
              tooltip={this.formatTooltip}
              maxValue={this.state.chart.maxValue}
              axisBottom={{ renderTick: this.renderBottomAxisTick, tickSize: 0, tickPadding: 20 }}
              axisLeft={{ format: this.formatLeftAxisLabel, tickSize: 5, tickPadding: 5, tickValues: this.props.leftAxisTickValues }}
              margin={{ ...{ top: 10, right: 20, bottom: 60, left: 80 }, ...this.props.chartMargin }}
              theme={{ tooltip: { container: { background: '#2b2e36', opacity: 0.75 } } }}
              colors='#95c11f'
              borderRadius={6}
              gridYValues={this.props.leftAxisTickValues}
            />
          </>
        )}
      </div>
    );
  }
}
