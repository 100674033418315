import React, { Component, Fragment } from 'react';
import Page from '../../../components/Page';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { sendViewportPropertiesMetrics } from '../../../utils/ViewportHelper';
import Alerts from './Alerts';
import LatestTeams from './LatestTeams';
import Onboarding from './Onboarding';
import SeatUsage from './SeatUsage';
import './styles.css';
import Totals from './Totals';

export default class Dashboard extends Component {

  state = {
    timeSavedSeconds: null,
    seatUserCount: null,
    seatUsage: [],
    fetchingStatistics: true,

    invitedSeatUsers: [],
    fetchingInvitedSeatUsers: true,

    account: null,
    fetchingAccount: true,

    teams: [],
    fetchingTeams: true,

    latestTeamsElement: null,
    onboardingType: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    Api.get('/statistics', ({ timeSavedSeconds, seatUserCount, seatUsage }) => {
      this.setState({ timeSavedSeconds, seatUserCount, seatUsage, fetchingStatistics: false });
    });

    if (User.isTeamLead()) {
      Api.get('/seat-users/invited', ({ list }) => {
        this.setState({ invitedSeatUsers: list, fetchingInvitedSeatUsers: false });
      });
      Api.get('/account', account => {
        this.setState({ account, fetchingAccount: false });
      });
    }

    Api.get('/teams?with_products=true', ({ list }) => {
      this.setState({ teams: list, fetchingTeams: false });

      if (list.length === 0) {
        if (User.isAccountAdmin()) {
          this.setState({ onboardingType: 'noTeams' });
        }
      }
      else if (User.shouldBeOnboarded()) {
        this.setState({ onboardingType: User.isTeamLead() ? 'newTeamLead' : 'newAdmin' });
      }
    });
    sendViewportPropertiesMetrics('DASHBOARD');
  };

  exitOnboarding = () => {
    this.setState({ onboardingType: null });
    User.setOnboardedIfNeeded();
  };

  onLatestTeamsRef = element => {
    this.setState({ latestTeamsElement: element });
  };

  render() {
    return (
      <Page
        className='Dashboard'
        title='Dashboard'
        alert={!this.state.fetchingStatistics && !this.state.fetchingTeams && (
          <Alerts
            seatUsage={this.state.seatUsage}
            teams={this.state.teams}
            onRedeployTimeChange={this.fetchData}
          />
        )}
        noHeading
      >
        {this.state.fetchingStatistics || (User.isTeamLead() && (this.state.fetchingInvitedSeatUsers || this.state.fetchingAccount)) || this.state.fetchingTeams ?

          <h1 className='Dashboard_fetching'>Fetching data...</h1> :

          <Fragment>
            <Totals
              timeSavedSeconds={this.state.timeSavedSeconds}
              seatUserCount={this.state.seatUserCount}
              seatUsage={this.state.seatUsage}
              invitedSeatUsers={this.state.invitedSeatUsers}
              onRedeployTimeChange={this.fetchData}
            />
            <SeatUsage
              seatUsage={User.isTeamLead() ? this.state.account.seatUsage : this.state.seatUsage}
              teams={this.state.teams}
            />
            <LatestTeams
              list={this.state.teams}
              onAnyButtonPress={this.exitOnboarding}
              onSave={this.fetchData}
              onRef={this.onLatestTeamsRef}
            />
            {this.state.onboardingType && this.state.latestTeamsElement && (
              <Onboarding
                type={this.state.onboardingType}
                highlightedElement={this.state.latestTeamsElement}
                onExit={this.exitOnboarding}
              />
            )}
          </Fragment>}
      </Page>
    );
  }
}
