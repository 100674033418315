import PropTypes from 'prop-types';
import Trigger from 'rc-trigger';
import React from 'react';
import Popup from './Popup';

Tooltip.propTypes = {
  size: PropTypes.oneOf([ 's', 'm', 'l' ]),
  offset: PropTypes.array,
  text: PropTypes.node.isRequired
};

export default function Tooltip({ children, size, offset, text }) {
  return (
    <Trigger
      action='hover'
      destroyPopupOnHide
      mouseLeaveDelay={0}
      popupAlign={{ points: [ 'bc', 'tc' ], offset }}
      popup={<Popup size={size} arrowPosition='bottom' text={text} />}
      popupStyle={{ opacity: 1 }} // Workaround for tests.
    >
      {children}
    </Trigger>
  );
}
