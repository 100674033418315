import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card, { CardBody, CardHeading } from '../Card';
import User from '../../utils/User';
import InfoTooltip from '../InfoTooltip';
import './styles.css';

export function TeamPanelTeamUrlLabel() {
  return (
    <span>
      <span>Team URL</span>
      {User.canEdit() && (
        <InfoTooltip size='l' text='Invite developers who can then activate with this team URL. The team URL is used to activate JRebel & XRebel by the end user.' />
      )}
    </span>
  );
}

export default class TeamPanel extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    token: PropTypes.string,
    className: PropTypes.string,
    bodyAlwaysWithHoverStyle: PropTypes.bool,
    onHeadingPress: PropTypes.func,
    onBodyMouseOver: PropTypes.func,
    onBodyMouseLeave: PropTypes.func
  };

  static defaultProps = {
    bodyAlwaysWithHoverStyle: false,
    onBodyMouseOver: () => {
    },
    onBodyMouseLeave: () => {
    }
  };

  onHeadingPress = () => {
    if (this.props.onHeadingPress) {
      this.props.onHeadingPress(this.props.token);
    }
  };

  onBodyMouseOver = () => {
    this.props.onBodyMouseOver(this.props.token);
  };

  onBodyMouseLeave = () => {
    this.props.onBodyMouseLeave(this.props.token);
  };

  render() {
    return (
      <div className={classNames('TeamPanel', this.props.className)}>
        <Card>
          <CardHeading className={{ 'can-be-pressed': this.props.onHeadingPress }} onClick={this.onHeadingPress}>
            <h4>{this.props.name}</h4>
          </CardHeading>
          <CardBody
            className={{ 'with-hover-style': this.props.bodyAlwaysWithHoverStyle }}
            onMouseOver={this.onBodyMouseOver}
            onMouseLeave={this.onBodyMouseLeave}
          >
            {this.props.children}
          </CardBody>
        </Card>
      </div>
    );
  }
}
