import emailValidator from 'email-validator';
import _ from 'lodash';
import { getRedeployTimeValidationMessage, hasJRebelEnabled } from '../selectors';
import { getProductEnabledValidationMessage, getSeatLimitValidationMessage } from './ProductsPanel/selectors';

export function toFormState(team) {
  const form = _.cloneDeep(team);

  form.name = {
    value: team.name,
    errorMessage: null
  };

  form.teamLead = {
    value: team.teamLead,
    disabled: !team.teamLead,
    errorMessage: null
  };

  form.redeployTimeSeconds = team.redeployTimeSeconds;

  return form;
}

export function toRequestParams(form) {
  return {
    token: form.token || null,
    name: form.name.value || null,
    products: form.products.map(product => ({
      productType: product.productType,
      enabled: product.enabled,
      limit: product.enabled ? product.limit : null
    })),
    teamLead: form.teamLead.value || null
  };
}

export function isEdit(state) {
  return state.form.token;
}

export function hasTeamLeadChanged(state) {
  const teamLead = state.form.teamLead.value;

  return teamLead && state.originalForm.teamLead.value !== teamLead;
}

export function isTeamLeadEmailValid(state) {
  return state.form.teamLead.disabled || emailValidator.validate(state.form.teamLead.value);
}

export function isTeamLeadAccountAdmin(state) {
  return state.adminUsers
    .filter(({ username }) => username === state.form.teamLead.value)
    .some(({ role }) => role === 'ACCOUNT_ADMIN');
}

export function hasRedeployTimeChanged(state) {
  return state.originalForm.redeployTimeSeconds !== state.form.redeployTimeSeconds;
}

export function isFormValid({ form }) {
  return form.name.value &&
    (form.teamLead.disabled || form.teamLead.value) &&
    (!hasJRebelEnabled(form.products) || !getRedeployTimeValidationMessage(form.redeployTimeSeconds)) &&
    form.products.every(product => !getSeatLimitValidationMessage(form.products, product)) &&
    form.products.every(product => !getProductEnabledValidationMessage(form.products, product));
}

export function isFormValidForTeamLead(state) {
  const { form } = state;

  return form.name.value &&
    !getRedeployTimeValidationMessage(form.redeployTimeSeconds);
}
