import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

TabBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default function TabBar({ children }) {
  return (
    <ul className='TabBar'>
      {children.map((tab, i) => !tab ? undefined : <li key={i}>{tab}</li>)}
    </ul>
  );
}
