import PropTypes from 'prop-types';
import React from 'react';
import { productTypes } from '../../enums/ProductType';
import BrandLabel from '../BrandLabel';

const labels = {
  JREBEL: [ 'J', 'Rebel' ],
  XREBEL: [ 'X', 'Rebel' ]
};

ProductTypeLabel.propTypes = {
  id: PropTypes.oneOf(productTypes).isRequired,
  styled: PropTypes.bool
};

ProductTypeLabel.asString = id => labels[id].join('');

export default function ProductTypeLabel({ ...otherProps }) {
  return <BrandLabel labels={labels} {...otherProps} />;
}
