import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

Popup.propTypes = {
  size: PropTypes.oneOf([ 'xs', 's', 'm', 'l' ]),
  arrowPosition: PropTypes.oneOf([ 'top', 'bottom' ]).isRequired,
  type: PropTypes.oneOf([ 'normal', 'error', 'success' ]),
  text: PropTypes.node.isRequired,
  error: PropTypes.bool
};

export default function Popup({ size, arrowPosition, text, type = 'normal' }) {
  return (
    <div className={classNames('Popup', size, `arrow-at-${arrowPosition}`, type)}>
      <span className='Popup_text'>{text}</span>
    </div>
  );
}
