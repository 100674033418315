import emailValidator from 'email-validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import { InputField } from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import { formatAsHumanReadableList } from '../../selectors';
import { roleLabels } from './labels';

export default class AdminUserForm extends Component {

  static propTypes = {
    username: PropTypes.string,
    role: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    username: {
      value: this.props.username || '',
      errorMessage: null
    },
    role: this.props.role || 'ACCOUNT_ADMIN',

    activeModal: 'form',
    editMode: this.props.username !== undefined,
    saving: false,

    teams: []
  };

  onFormSubmit = e => {
    e.preventDefault();

    if (this.state.editMode) {
      this.saveEdit();
    }
    else {
      this.saveCreate();
    }
  };

  saveEdit = () => {
    if (this.state.role === 'VIEW_ONLY_ADMIN') {
      this.setState({ saving: true });

      Api.get(`/teams?${queryString.stringify({ team_lead_username: this.props.username })}`, ({ list }) => {
        if (list.length > 0) {
          this.setState({ teams: list, saving: false, activeModal: 'warning' });
        }
        else {
          this.saveEditAndCloseModal();
        }
      });
    }
    else {
      this.saveEditAndCloseModal();
    }
  };

  saveCreate = () => {
    if (!emailValidator.validate(this.state.username.value)) {
      this.setState(state => ({ username: { ...state.username, errorMessage: 'Please enter a valid email address' } }));
      return;
    }

    this.setState({ saving: true });

    Api.post('/admin-users', { username: this.state.username.value, role: this.state.role }, ({ error }) => {
      if (error === 'USERNAME_EXISTS') {
        this.setState(state => ({ saving: false, username: { ...state.username, errorMessage: 'Email address already in use, try another one' } }));
      }
      else {
        this.props.onSuccess(this.state.username.value);
      }
    });
  };

  saveEditAndCloseModal = () => {
    this.setState({ saving: true });

    Api.put('/admin-users/role', { username: this.props.username, role: this.state.role }, () => {
      this.props.onSuccess(this.props.username);
    });
  };

  onRoleChange = ({ currentTarget: { value } }) => {
    this.setState({ role: value });
  };

  onUsernameChange = ({ currentTarget: { value } }) => {
    this.setState({ username: { value, errorMessage: null } });
  };

  render() {
    return (
      <Fragment>
        {this.state.activeModal === 'form' && (
          <Modal
            title={this.state.editMode ? 'Change administrator role' : 'Invite administrator'}
            size='m'
            buttons={
              <div>
                <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
                <ModalButton
                  type='primary'
                  disabled={this.state.saving || this.state.role === this.props.role }
                  onClick={this.onFormSubmit}
                >
                  {this.state.editMode ? 'Save' : 'Invite'}
                </ModalButton>
              </div>}
            onClose={this.props.onCancel}
          >
            <InputField
              type='text'
              name='username'
              label='Email'
              value={this.state.username.value}
              errorMessage={this.state.username.errorMessage}
              onChange={this.onUsernameChange}
              disabled={this.state.editMode}
              autoFocus
            />
            <SelectField
              label='Role'
              name='exportedTo'
              value={this.state.role}
              onChange={this.onRoleChange}
              id='AdminUserForm.role'
              disabled={this.state.saving}
            >
              {Object.keys(roleLabels).filter(key => this.props.role === key || key !== 'TEAM_LEAD').map(key => <option key={key} value={key}>{roleLabels[key]}</option>)}
            </SelectField>
          </Modal>
        )}
        {this.state.activeModal === 'warning' && (
          <Modal
            title='Edit administrator role'
            size='m'
            buttons={<div>
              <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
              <ModalButton type='primary' disabled={this.state.saving} onClick={this.saveEditAndCloseModal}>Save</ModalButton>
            </div>}
            onClose={this.props.onCancel}
          >
            <p>
              <span><strong>{this.props.username}</strong> will be unassigned from the role of team lead of {formatAsHumanReadableList(_.map(this.state.teams, 'name'))} </span>
              <span>after changing role to <strong>{roleLabels[this.state.role]}</strong>.</span>
            </p>
          </Modal>
        )}
      </Fragment>
    );
  }
}
