import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../../components/Button';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import './styles.css';

export default class FeedbackFormModal extends Component {

  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    origin: PropTypes.string.isRequired
  };

  state = {
    saving: false,
    form: {
      score: '',
      answer: '',
      neverAskAgain: false
    }
  };

  // Can submit only if score or 'don`t ask again' is selected.
  onFormSubmit = e => {
    e.preventDefault();
    if ((this.state.form.score !== '' && this.state.form.score >= 0 && this.state.form.score <= 10) || this.state.form.neverAskAgain) {
      this.setState({ saving: true });
      Api.post('/admin-users/nps-feedback', this.state.form);
    }
    this.props.onSave();
  };

  setSelectedScore(value) {
    this.setState(state => ({ form: { ...state.form, score: value } }));
  }

  onAnswerChange = ({ currentTarget: { value } }) => {
    this.setState(state => ({ form: { ...state.form, answer: value } }));
  };

  onNeverAskAgainChange = ({ currentTarget: { checked } }) => {
    this.setState(state => ({ form: { ...state.form, neverAskAgain: checked } }));
  };

  renderScoreButtons() {
    return (
      <div className='FeedbackFormModal_score-buttons-wrapper'>
        {_.range(1, 11).map(value => {
          return (<Button
            type='button'
            className={classNames('FeedbackFormModal_score-button', { 'selected': this.state.form.score === value })}
            onClick={() => this.setSelectedScore(value)}
            key={value}
          >{value}</Button>);
        })}
      </div>
    );
  }

  render() {
    return (
      <div className='FeedbackFormModal'>
        <Modal
          title='Submit feedback'
          className='FeedbackFormModal_modal'
          size='l'
          buttons={<div>
            <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
            <ModalButton
              type='primary'
              disabled={this.state.form.score === '' && !this.state.form.neverAskAgain || this.state.saving}
              submit
            >
              Submit
            </ModalButton>
          </div>}
          onSubmit={this.onFormSubmit}
          onClose={this.props.onCancel}
        >
          <div className='FeedbackFormModal_modal_body'>
            <div className='FeedbackFormModal_nps'>
              <h4>Rate your experience of using Rebel Licenses.</h4>
              <div className='FeedbackFormModal_nps-score'>
                {this.renderScoreButtons()}
                <div className='FeedbackFormModal_score-label'>
                  <span>Terrible</span>
                  <span>Excellent</span>
                </div>
              </div>
            </div>

            <div className='FeedbackFormModal_answer scroll-grey'>
              <label htmlFor='answerText'>How can we improve your experience of using Rebel Licenses?</label>
              <textarea
                id='answerText'
                name='answer'
                value={this.state.form.answer}
                onChange={this.onAnswerChange}
                placeholder='Give us your Opinion (Optional)'
                maxLength='30000'
              />
            </div>

            <div className='FeedbackFormModal_never-ask-again'>
              <input
                type='checkbox'
                name='neverAskAgain'
                id='neverAskAgain'
                checked={this.state.form.neverAskAgain}
                onChange={this.onNeverAskAgainChange}
              />

              <label htmlFor='neverAskAgain'>Don't show this again</label>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
