import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../../../components/Button';
import Card, { CardBody } from '../../../../components/Card';
import { InputField } from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import ValidationTooltip from '../../../../components/ValidationTooltip';
import Api from '../../../../utils/Api';
import EventEmitter from '../../../../utils/EventEmitter';
import User from '../../../../utils/User';
import TeamRedeployTimeModal from '../../TeamRedeployTimeModal';
import ActualRoiPanel from './ActualRoiPanel';
import { getPeriodLengthDays, isAtLeastPeriodOld } from './ActualRoiPanel/selectors';
import RoiCalculatorPanel, { RoiSummary, SavingsNumbers } from './RoiCalculatorPanel';
import DataRecapTable from './RoiRecapTable';
import { getJRebelHostedSeatCount, getJRebelPriceYearly, getValidationMessage, isEveryInputValid, parseFloatOrReturnOriginal } from './selectors';
import './styles.css';
import ProjectedSavingsPanel from './ProjectedSavingsPanel';

const inputConstraints = {
  developerSalaryYearly: {
    min: 1000,
    max: 10000000,
    step: 100
  },
  developerCount: {
    min: 1,
    max: 10000,
    step: 1
  },
  redeployTimeMinutes: {
    min: 0.1,
    max: 60,
    step: 0.1
  },
  redeployCountHourly: {
    min: 1,
    step: 1
  }
};

let developerCountDefault = 10;
let isFirstView = false;

function getInputDefaults() {
  return {
    developerSalaryYearly: 80000, // Temporarily changed for JR-12442
    currency: 'USD',
    developerCount: developerCountDefault,
    redeployTimeMinutes: 2.7,
    redeployCountHourly: 4
  };
}

function getPersistedInputValues() {
  return JSON.parse(localStorage.getItem('roiCalculatorInputs'));
}

function setPersistedInputValues(values) {
  localStorage.setItem('roiCalculatorInputs', JSON.stringify(values));
}

if (!localStorage.getItem('roiCalculatorInputs')) {
  setPersistedInputValues(getInputDefaults());
  isFirstView = true;
}

class ReloadTimeConfigurePanel extends Component {

  static propTypes = {
    onRedeployTimeChange: PropTypes.func.isRequired
  };

  state = {
    activeModal: null
  };

  onConfigureButtonPress = () => {
    this.setState({ activeModal: 'teamRedeployTime' });
  };

  onModalSave = () => {
    this.closeModal();
    this.props.onRedeployTimeChange();
  };

  closeModal = () => {
    this.setState({ activeModal: null });
  };

  // TODO: how do we handle if a user can not edit?
  render() {
    return (
      <>
        {this.state.activeModal === 'teamRedeployTime' && (
          <TeamRedeployTimeModal origin='ROI_CALCULATOR' onSave={this.onModalSave} onCancel={this.closeModal} />
        )}
        <Card className='ReloadTimeConfigurePanel'>
          <div className='RoiCalculatorTab_panel-first_row_title'>
            <h5>Set team redeploy time</h5>
            <span>Enter inputs in configure to find accurate savings</span>
          </div>
          {User.canEdit() && (
            <div className='pull-right'>
              <button type='button' className='Button primary' onClick={this.onConfigureButtonPress}>Configure</button>
            </div>
          )}
        </Card>
      </>
    );
  }
}

export default class RoiCalculatorTab extends Component {

  inputElements = {};

  teamRedeployTimeModalSaveListenerToken;

  state = {
    inputs: getPersistedInputValues(),
    actualRoiProps: getPersistedInputValues(),
    calculatedRoi: {
      timeSavedPerDeveloperDailyMinutes: null,
      timeSavedYearlyHours: null,
      moneySavedYearly: null,
      jrebelPaysOffInDays: null
    },
    fetchingFirstCalculatedRoi: true,

    timeSavedSeconds: {
      total: null,
      weekly: null,
      monthly: null,
      quarterly: null,
      yearly: null
    },
    seatUserCount: {
      total: null,
      weekly: null,
      monthly: null,
      quarterly: null,
      yearly: null
    },
    reloadsCountInPeriod: null,
    fetchingReloadsCountInPeriod: true,
    seatUsage: [],
    fetchingStatistics: true,

    licenses: [],
    fetchingLicenses: true,

    accountCreatedAt: null,
    fetchingAccountCreatedAt: true,
    period: 'yearly'
  };

  componentDidMount() {
    this.fetch();
    this.teamRedeployTimeModalSaveListenerToken = EventEmitter.addListener(TeamRedeployTimeModal.savedEventId, this.fetch);
  }

  componentWillUnmount() {
    this.teamRedeployTimeModalSaveListenerToken.remove();
  }

  fetchAccountCreatedAt = () => {
    Api.get('/account', ({ createdAt }) => {
      const defaultPeriod = isAtLeastPeriodOld(this.props.accountCreatedAt, 'yearly') ? 'total' : 'yearly';

      this.setState({
        accountCreatedAt: createdAt,
        fetchingAccountCreatedAt: false,
        period: defaultPeriod
      });
      this.fetchProductMetricsReloadCountForPeriod(createdAt, defaultPeriod);
    });
  };

  /**
   * Queries and updates the number of reloads for the chosen period.
   */
  fetchProductMetricsReloadCountForPeriod(accountCreatedAt, period) {
    // TODO listen for update events.
    const days = getPeriodLengthDays(accountCreatedAt || this.state.accountCreatedAt, period);
    const end = moment.utc();
    const start = end.clone().subtract(days, 'days');

    const params = {
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
      granularity: 'MONTH', // TODO: query only the total for the period. Will likely require changes in ProductMetricsDao
      metric: 'REDEPLOYS'
    };

    Api.get(`/reports/product-metrics?${queryString.stringify(params)}`, reloads => {
      const total_reloads = _(reloads.list).map(x => x.value).sum();
      this.setState({ reloadsCountInPeriod: total_reloads, fetchingReloadsCountInPeriod: false });
    });
  }

  fetch = () => {
    Api.get('/licenses', ({ list }) => {
      this.setState({ licenses: list, fetchingLicenses: false });

      Api.get('/statistics', ({ timeSavedSeconds, seatUserCount, seatUsage }) => {
        const jrebelHostedSeatCount = getJRebelHostedSeatCount(seatUsage);

        if (jrebelHostedSeatCount > 0) {
          developerCountDefault = jrebelHostedSeatCount;
        }

        if (isFirstView) {
          this.updateInputsToNewValidState(getInputDefaults());
        }

        this.setState({ timeSavedSeconds, seatUserCount, seatUsage, fetchingStatistics: false });
        this.fetchAccountCreatedAt();
      });

      if (!isFirstView) {
        this.fetchCalculatedRoi(this.state.inputs);
      }
    });
  };

  onInputChange = ({ currentTarget: { name, value } }) => {
    const newStateOfInputs = { ...this.state.inputs };

    newStateOfInputs[name] = parseFloatOrReturnOriginal(value);

    if (isEveryInputValid(newStateOfInputs, inputConstraints)) {
      this.updateInputsToNewValidState(newStateOfInputs);
    }
    else {
      this.setState({ inputs: newStateOfInputs });
    }
  };

  updateInputsToNewValidState(newState) {
    setPersistedInputValues(newState);
    this.fetchCalculatedRoi(newState);
    this.setState({ inputs: newState, actualRoiProps: newState });
  }

  fetchCalculatedRoi(inputs) {
    Api.get(`/reports/roi?${queryString.stringify({ jrebelPriceYearly: getJRebelPriceYearly(this.state.licenses, inputs.currency), ...inputs })}`, response => {
      this.setState({ calculatedRoi: response, fetchingFirstCalculatedRoi: false });
    });
  }

  onRestoreDefaultsButtonPress = () => {
    this.updateInputsToNewValidState(getInputDefaults());
  };

  onExportToPdfButtonPress = () => {
    Api.initiateAuthenticatedDownload(`/reports/roi?${queryString.stringify({
      jrebelPriceYearly: getJRebelPriceYearly(this.state.licenses, this.state.inputs.currency),
      jrebelPriceYearlyUsd: getJRebelPriceYearly(this.state.licenses, 'USD'),
      organization: User.getOrganization(),
      reportType: 'COMPANY_SAVINGS',
      ...this.state.inputs
    })}`);
  };

  onPeriodChange = newPeriod => {
    this.setState({ period: newPeriod });
    if (!this.state.fetchingAccountCreatedAt) {
      this.fetchProductMetricsReloadCountForPeriod(this.state.accountCreatedAt, newPeriod);
    }
  };

  onInputRef = element => {
    if (element) {
      this.inputElements[element.name] = element;
    }
  };

  renderCompanySavingsInputLabel(primary, secondary) {
    return (
      <div className='CompanySavingsInputLabel'>
        <div className='CompanySavingsInputLabel_primary'>{primary}</div>
        <div className='CompanySavingsInputLabel_secondary'>{secondary}</div>
      </div>
    );
  }

  renderInput(label, name) {
    const validationMessage = getValidationMessage(name, this.state.inputs, inputConstraints);

    return (
      <span className='RoiCalculatorTab_input'>
        <ValidationTooltip target={this.inputElements[name]} text={validationMessage} />
        <label className='RoiCalculatorTab_panel_inputs_input_label' htmlFor={name}>{label}</label>
        <InputField
          type='number'
          name={name}
          title={label}
          value={this.state.inputs[name]}
          onChange={this.onInputChange}
          ref={this.onInputRef}
          hasError={validationMessage}
          required
          {...inputConstraints[name]}
        />
      </span>
    );
  }

  shouldHideCalculatedRoiPanelFooter() {
    return this.state.calculatedRoi.jrebelPaysOffInDays >= 365;
  }

  renderCompanySavingsPanel() {
    const HOURS_PER_WORKDAY = 5;
    const WORKDAYS_PER_YEAR = 261;
    const DAYS_PER_YEAR = 365;
    const activeDevelopers = this.state.inputs.developerCount || 1;
    const redeploysPerHours = this.state.inputs.redeployCountHourly || 1;
    const averageRedeployTime = this.state.inputs.redeployTimeMinutes || 0.1;
    const periodLength = getPeriodLengthDays(this.state.accountCreatedAt, this.state.period);
    const redeploysSaved = activeDevelopers * redeploysPerHours * HOURS_PER_WORKDAY * periodLength * (WORKDAYS_PER_YEAR / DAYS_PER_YEAR);
    const timeSaved = redeploysSaved * averageRedeployTime;

    const recapValues = [
      {
        icon: 'icon-rotating-arrows',
        label: 'Redeploys saved',
        value: redeploysSaved.toFixed(),
        tooltip: 'Based on "number of developers" and "redeploy frequency" inputs as well as estimated developer working hours.'
      },
      {
        icon: 'icon-person-head',
        label: 'Active developers',
        value: activeDevelopers.toFixed(),
        tooltip: 'This is just the "number of developers" input.'
      },
      {
        icon: 'icon-clock-full',
        label: 'Time saved in hours',
        extra: '(Total across developers)',
        value: (timeSaved / 60).toFixed(),
        tooltip: 'Based on all 3 Calculated savings inputs, as well as an estimate for developer working hours.'
      },
      {
        icon: 'icon-clock-dotted',
        label: 'Average redeploy time',
        extra: '(Time saved/redeploys)',
        value: averageRedeployTime.toFixed(),
        tooltip: 'This is just the "Build and redeploy time" input.'
      }
    ];

    return !this.state.fetchingFirstCalculatedRoi && !this.state.fetchingLicenses && !this.state.fetchingStatistics && (
      <RoiCalculatorPanel
        className='CompanySavingsPanel'
        heading='Company savings'
        headerLeftPart={(
          <div className='RoiSummaryWrapper pull-right'>
            <RoiSummary
              roiSummaryText='pays for itself in'
              roiSummaryDays={this.state.calculatedRoi.jrebelPaysOffInDays}
            />
          </div>
        )}
        headerRightPart={
          <>
            <Button type='primary' className='pull-right' onClick={this.onExportToPdfButtonPress}>Export to PDF</Button>
            <Button type='secondary' className='pull-right RestoreDefaults' onClick={this.onRestoreDefaultsButtonPress}>Restore defaults</Button>
          </>
        }
        hideCentralSection={false}
        centralSection={
          <>
            <div className='RoiCalculatorTab_panel_inputs pull-right'>
              <h5 className='RoiCalculatorTab_panel_inputs_heading'>Calculated savings</h5>
              {this.renderInput(this.renderCompanySavingsInputLabel('Number of developers', ''), 'developerCount')}
              {this.renderInput(this.renderCompanySavingsInputLabel('Build and redeploy time (min)', 'Build + server start time'), 'redeployTimeMinutes')}
              {this.renderInput(this.renderCompanySavingsInputLabel('Redeploy frequency', 'How often you redeploy per hour'), 'redeployCountHourly')}
            </div>
            <h5 className='ActualRoiPanel_recap_heading'>JRebel Saved you</h5>
            <DataRecapTable heading='JRebel saved you' values={recapValues} />
            <SavingsNumbers
              savingsHeading='You could save'
              timeSavedPerDeveloperDailyMinutes={this.state.calculatedRoi.timeSavedPerDeveloperDailyMinutes}
              timeSavedYearlyHours={this.state.calculatedRoi.timeSavedYearlyHours}
              moneySavedYearly={this.state.calculatedRoi.moneySavedYearly}
              currency={this.state.inputs.currency}
            />
          </>
        }
        savingsHeading='You could save'
        currency={this.state.inputs.currency}
        {...this.state.calculatedRoi}
      />
    );
  }

  renderActualSavings() {
    return (
      <ActualRoiPanel
        developerSalaryYearly={this.state.actualRoiProps.developerSalaryYearly}
        jrebelPriceYearly={getJRebelPriceYearly(this.state.licenses, this.state.actualRoiProps.currency)}
        currency={this.state.actualRoiProps.currency}
        timeSavedSeconds={this.state.timeSavedSeconds}
        seatUserCount={this.state.seatUserCount}
        seatUsage={this.state.seatUsage}
        onRedeployTimeChange={this.fetch}
        reloadsCountInPeriod={this.state.reloadsCountInPeriod}
        accountCreatedAt={this.state.accountCreatedAt}
        fetchingAccountCreatedAt={this.state.fetchingAccountCreatedAt}
        period={this.state.period}
        onPeriodChange={this.onPeriodChange}
        licenses={this.state.licenses}
      />
    );
  }

  renderProjectedSavings() {
    return (
      <ProjectedSavingsPanel
        developerSalaryYearly={this.state.actualRoiProps.developerSalaryYearly}
        jrebelPriceYearly={getJRebelPriceYearly(this.state.licenses, this.state.actualRoiProps.currency)}
        currency={this.state.actualRoiProps.currency}
        timeSavedSeconds={this.state.timeSavedSeconds}
        seatUserCount={this.state.seatUserCount}
        seatUsage={this.state.seatUsage}
        onRedeployTimeChange={this.fetch}
        accountCreatedAt={this.state.accountCreatedAt}
        fetchingAccountCreatedAt={this.state.fetchingAccountCreatedAt}
        period={this.state.period}
      />
    );
  }

  renderActualAndProjectedSavings() {
    return !this.state.fetchingLicenses && !this.state.fetchingStatistics && !this.state.fetchingReloadsCountInPeriod && (
      <Card className='RoiCalculatorTab_column full-width-column container-card top-row'>
        <div className='RoiCalculatorTab_column left-column top-row'>
          <ReloadTimeConfigurePanel onRedeployTimeChange={this.fetch} />
        </div>
        <div className='RoiCalculatorTab_column right-column top-row'>
          <Card className='RoiCalculatorTab_salary-panel'>
            <div className='RoiCalculatorTab_salary-row'>
              <div className='RoiCalculatorTab_panel-first_row_title'>
                <h5>Average developer salary</h5>
                <span>Enter inputs to find accurate savings</span>
              </div>
              <div className='RoiCalculatorTab_salary-row_form'>
                {this.renderInput('', 'developerSalaryYearly')}
                <SelectField name='currency' value={this.state.inputs.currency} onChange={this.onInputChange}>
                  <option value='USD'>USD</option>
                  <option value='EUR'>EUR</option>
                </SelectField>
              </div>
            </div>
          </Card>
        </div>
        <div className='RoiCalculatorTab_column left-column bottom-row'>
          {this.renderActualSavings()}
        </div>
        <div className='RoiCalculatorTab_column right-column bottom-row'>
          {this.renderProjectedSavings()}
        </div>
      </Card>
    );
  }

  renderCallToActionPanel() {
    return (
      <Card className='RoiCalculatorPanel RoiCalculatorTab_CallToAction_panel'>
        <CardBody>
          <div className='RoiCalculatorPanel_header'>
            <div className='RoiCalculatorPanel_header-left-part'>
              <h5 className='RoiCalculatorPanel_header-left-part_title'>Contact Us</h5>
            </div>
          </div>
          <div className='RoiCalculatorPanel_central-section'>
            <div className='RoiCalculatorTab_CallToAction '>
              <div className='RoiCalculatorTab_CallToAction_side RoiCalculatorTab_CallToAction_side-left'>
                <h5 className='RoiCalculatorTab_CallToAction_side_heading'>Contact the JRebel customer success team</h5>
                <div className='RoiCalculatorTab_CallToAction_side_body'>
                  <div>Want to get on-boarding help?</div>
                  <div>Interested in adding new JRebel Licenses?</div>
                </div>
                <div className='RoiCalculatorTab_CallToAction_side_link'>
                  <a href='mailto:salesoperations@perforce.com'>salesoperations@perforce.com</a>
                </div>
              </div>
              <div className='RoiCalculatorTab_CallToAction_side RoiCalculatorTab_CallToAction_side-right'>
                <h5 className='RoiCalculatorTab_CallToAction_side_heading'>Contact the JRebel customer success team</h5>
                <div className='RoiCalculatorTab_CallToAction_side_body'>
                  <div>Having issues with existing licenses?</div>
                  <div>Want help with calculating your savings?</div>
                </div>
                <div className='RoiCalculatorTab_CallToAction_side_link'>
                  <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <div className='RoiCalculatorTab'>
        {this.renderActualAndProjectedSavings()}
        <div className='RoiCalculatorTab_column full-width-column'>
          {this.renderCompanySavingsPanel()}
        </div>
        <div className='RoiCalculatorTab_column full-width-column last-row'>
          {this.renderCallToActionPanel()}
        </div>
      </div>
    );
  }
}
