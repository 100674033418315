import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';

Alerts.propTypes = {
  alert: PropTypes.shape({
    active: PropTypes.string,
    email: PropTypes.string
  })
};

function render(message) {
  return (
    <Alert type='ok'>
      {message}
    </Alert>
  );
}

export default function Alerts({ alert: { active, email } }) {
  switch (active) {
  case 'resend':
    return render(<span>Invitation email sent successfully to <AlertEmphasis>{email}</AlertEmphasis>.</span>);
  case 'block':
    return render(<span><AlertEmphasis>{email}</AlertEmphasis> has been blocked successfully.</span>);
  case 'unblock':
    return render(<span><AlertEmphasis>{email}</AlertEmphasis> has been unblocked successfully.</span>);
  case 'delete':
    return render(<span><AlertEmphasis>{email}</AlertEmphasis> has been deleted successfully.</span>);
  case 'exportDedicated':
    return render(<span><AlertEmphasis>Success!</AlertEmphasis> Users will receive emails with licenses attached for individual offline use.</span>);
  default:
    return <Fragment />;
  }
}
