import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import InfoTooltip from '../../../components/InfoTooltip';
import TabBar from '../../../components/TabBar';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { filterUsers } from './selectors';

const Tabs = ({ users }) => {
  const [ showForgotten, setShowForgotten ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    Api.get('/account', ({ forgetUsersEnabled }) => {
      setShowForgotten(forgetUsersEnabled);
    });
  }, []);

  const getUserCount = presentationMode => {
    return filterUsers(users, presentationMode, {}).length;
  };

  return (
    <TabBar>
      <NavLink end to={`./${location.search}`}>
        Users with seats ({getUserCount()})
      </NavLink>
      {getUserCount('offline') > 0 && (
        <NavLink to={`offline${location.search}`}>
          Offline users ({getUserCount('offline')})
        </NavLink>
      )}
      {getUserCount('invited') > 0 && (
        <NavLink to={`invited${location.search}`}>
          Invited users ({getUserCount('invited')})
        </NavLink>
      )}
      <NavLink to={`blocked${location.search}`}>
        Blocked users ({getUserCount('blocked')})
      </NavLink>
      {showForgotten && (
        <NavLink to={`forgotten${location.search}`}>
          Forgotten users ({getUserCount('forgotten')})
        </NavLink>
      )}
      {!User.isTeamLead() && getUserCount('individual') > 0 && (
        <NavLink to={`individual${location.search}`}>
          <span>Individual licenses ({getUserCount('individual')})</span>
          <InfoTooltip size='s' text='These users have been sent an individual license file' />
        </NavLink>
      )}
    </TabBar>
  );
};

Tabs.propTypes = {
  users: PropTypes.array.isRequired
};

export default Tabs;
