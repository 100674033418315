import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import './styles.css';

export default class DeleteLastWhitelistedDomainModal extends Component {

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired
  };

  state = {
    saving: false
  };

  onDeleteButtonPress = () => {
    this.setState({ saving: true });

    this.props.onSuccess();
  };

  render() {
    return (
      <Modal
        title='Delete domain & turn off whitelist'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onDeleteButtonPress}>Delete domain & turn off whitelist</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          Deleting <strong>{this.props.domain}</strong> domain will also turn off the whitelist.<br />
          Activation will be allowed with all email addresses.
        </p>
        <p className='DeleteLastWhitelistedDomainModal_confirmation-text'>Are you sure?</p>
      </Modal>
    );
  }
}
