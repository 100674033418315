import emailValidator from 'email-validator';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import EnableDomainWhitelistingModal from '../EnableDomainWhitelistingModal';
import { getHavingOtherDomainThanListOrForm } from '../selectors';

export default class AddWhitelistedDomainForm extends Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    domain: PropTypes.string,
    domains: PropTypes.arrayOf(
      PropTypes.shape({
        domain: PropTypes.string.isRequired
      })
    ).isRequired,
    domainWhitelistingEnabled: PropTypes.bool.isRequired
  };

  state = {
    saving: false,
    activeModal: 'form',
    invitedSeatUsers: [],
    domain: this.props.domain || '',
    errorMessage: null
  };

  onFormSubmit = e => {
    e.preventDefault();

    if (!emailValidator.validate(`dummy@${this.state.domain.replace(/\*/g, 'aa')}`)) {
      this.setState({ errorMessage: 'Please enter a valid email domain.' });
      return;
    }

    if (!this.props.domains.every(({ domain }) => domain !== this.state.domain)) {
      this.setState({ errorMessage: 'This email domain already exists, try another one.' });
      return;
    }

    this.setState({ saving: true });

    if (this.props.domains.length === 0) {
      Api.get('/seat-users/invited', ({ list }) => {
        if (getHavingOtherDomainThanListOrForm(list, this.props.domains, this.state.domain).length > 0) {
          this.setState({ activeModal: 'invitedSeatUserWarning', invitedSeatUsers: list, saving: false });
        }
        else {
          this.saveFormAndCloseModal();
        }
      });
    }
    else {
      this.saveFormAndCloseModal();
    }
  };

  saveFormAndCloseModal = () => {
    this.setState({ saving: true });

    Api.post('/whitelisted-domains', { domain: this.state.domain }, this.props.onSave);
  };

  onDomainChange = ({ currentTarget: { value } }) => {
    this.setState({ domain: value.toLowerCase() });
  };

  render() {
    return (
      <Fragment>
        {this.state.activeModal === 'form' && (
          <Modal
            title='Add domain to whitelist'
            size='m'
            buttons={<div>
              <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
              <ModalButton type='primary' disabled={this.state.saving} submit>Add domain</ModalButton>
            </div>}
            onSubmit={this.onFormSubmit}
            onClose={this.props.onCancel}
          >
            <Alert type='info'>
              <div><AlertEmphasis>Domain names can include asterisks for wildcard matching.</AlertEmphasis></div>
              <div>For example <AlertEmphasis>*yourdomain.com</AlertEmphasis> or <AlertEmphasis>yourdomain.*</AlertEmphasis>.</div>
            </Alert>
            <InputField
              type='text'
              name='domain'
              label='Email domain'
              value={this.state.domain}
              errorMessage={this.state.errorMessage}
              onChange={this.onDomainChange}
              autoFocus
            />
          </Modal>
        )}
        {this.state.activeModal === 'invitedSeatUserWarning' && (
          <EnableDomainWhitelistingModal
            onCancel={this.props.onCancel}
            onSave={this.saveFormAndCloseModal}
            domains={this.props.domains}
            invitedSeatUsers={this.state.invitedSeatUsers}
            domain={this.state.domain}
          />
        )}
      </Fragment>
    );
  }
}
