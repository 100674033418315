import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Alert from '../../../components/Alert';
import './styles.css';

export default class ExpandableAlert extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string
  };

  state = {
    expanded: false
  };

  onClick = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  };

  render() {
    return (
      <Alert
        type='instruction'
        icon={this.state.expanded ? 'chevron-down' : 'chevron-right'}
        className={classNames('ExpandableAlert', this.props.className)}
        onIconClick={this.onClick}
      >
        <div className='ExpandableAlert_title' onClick={this.onClick}>{this.props.title}</div>
        {this.state.expanded && (
          <div className='ExpandableAlert_content'>{this.props.children}</div>
        )}
      </Alert>
    );
  }
}
