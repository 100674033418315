import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Alert from '../../../../components/Alert';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import { InputField } from '../../../../components/InputField';
import LicenseTypeLabel from '../../../../components/LicenseTypeLabel';
import Modal, { ModalButton } from '../../../../components/Modal';
import SelectField from '../../../../components/SelectField';
import UnitLabel from '../../../../components/UnitLabel';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import { getAvailableSeatCount, isInTheFuture } from '../selectors';
import { filterAdminsAllowedToExport, getSeatCountErrorMessage, isFormValid } from './selectors';
import './styles.css';

export default class ExportManagedForm extends Component {

  static propTypes = {
    license: PropTypes.shape({
      type: PropTypes.string.isRequired,
      seatCount: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
      orderItemId: PropTypes.string.isRequired,
      exportedSeatCounts: PropTypes.object.isRequired
    }).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    activeModal: 'form',
    adminUsers: {
      list: [],
      fetching: true
    },
    seatUsage: {
      items: [],
      fetching: true
    },
    form: {
      exportedTo: '',
      firstName: '',
      lastName: '',
      seatCount: ''
    },
    saving: false
  };

  componentDidMount() {
    Api.get('/admin-users', ({ list }) => {
      this.setState(state => {
        state.adminUsers = { list, fetching: false };
        state.form.exportedTo = this.getDefaultExportedTo(list);
        return state;
      });
    });

    Api.get('/statistics', ({ seatUsage }) => {
      this.setState(state => ({ seatUsage: { items: seatUsage, fetching: false }, form: { ...state.form, seatCount: getAvailableSeatCount(seatUsage, this.props.license) } }));
    });
  }

  getDefaultExportedTo(list) {
    const adminUsernamesAllowedToExport = filterAdminsAllowedToExport(list).map(({ username }) => username);

    if (adminUsernamesAllowedToExport.includes(User.getUsername())) {
      return User.getUsername();
    }

    if (adminUsernamesAllowedToExport.length > 0) {
      return adminUsernamesAllowedToExport[0];
    }

    return '';
  }

  onConfirmButtonPress = () => {
    this.setState({ saving: true });

    Api.post('/licenses/exported/managed',
      {
        exportedTo: {
          email: this.state.form.exportedTo,
          firstName: this.state.form.firstName,
          lastName: this.state.form.lastName
        },
        seatCount: this.state.form.seatCount,
        orderItemId: this.props.license.orderItemId
      },

      () => this.props.onSave(this.state.form.exportedTo));
  };

  onFormSubmit = e => {
    e.preventDefault();

    this.setState({ activeModal: 'confirmation' });
  };

  onFieldChange = ({ currentTarget: { name, value } }) => {
    this.setState(state => ({ form: { ...state.form, [name]: isNaN(parseInt(value, 10)) ? value : parseInt(value, 10) } }));
  };

  getAvailableSeatCount() {
    return getAvailableSeatCount(this.state.seatUsage.items, this.props.license);
  }

  renderTitle() {
    return (
      <span>
        <span className='ExportManagedForm_light-title'>Export </span>
        <LicenseTypeLabel id={this.props.license.type} />
        <span className='ExportManagedForm_light-title'> licenses<br />for On-Premise (License Server)</span>
      </span>
    );
  }

  render() {
    return (
      <div className='ExportManagedForm'>
        {this.state.activeModal === 'confirmation' && (
          <Modal
            title={this.renderTitle()}
            size='m'
            buttons={
              <ModalButton type='primary' disabled={this.state.saving} className='pull-right' onClick={this.onConfirmButtonPress}>Export</ModalButton>}
            onClose={this.props.onCancel}
          >
            <div className='ExportManagedForm_text'>
              Exported licenses will be sent to <strong>{this.state.form.exportedTo}</strong>.
              <br />
              <br />
              If this is correct, press Export.
            </div>
          </Modal>
        )}

        {this.state.activeModal === 'form' && !this.state.seatUsage.fetching && !this.state.adminUsers.fetching && (
          <Modal
            title={this.renderTitle()}
            size='l'
            buttons={ /* TODO Add Cancel here as well? if yes then is confirm also triggered? */
              <ModalButton type='primary' disabled={!isFormValid(this.state.form, this.state.seatUsage.items, this.props.license)} submit>
                Export
              </ModalButton>
            }
            onSubmit={this.onFormSubmit}
            onClose={this.props.onCancel}
            confirmClose={true}
          >
            <p>
              You are about to export licenses for Rebel Licenses On-Premise (License Server).
              A bulk license file will be sent to the administrator email specified.
              These licenses are usable only during the license validity period.
            </p>
            <Alert type='info'>
              This action can only be reverted by contacting <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>.
            </Alert>
            <div className='ExportManagedForm_available-seats'>
              <UnitLabel valueClass='ExportManagedForm_available-seats_number' value={this.getAvailableSeatCount()} unit='seat' />
              <span> available for </span>
              <LicenseTypeLabel id={this.props.license.type} />
              {isInTheFuture(this.props.license.startDate) && <span> from <DateTimeLabel timestamp={this.props.license.startDate} /></span>}
              <span> until </span><DateTimeLabel timestamp={this.props.license.endDate} />
            </div>
            <div className={classNames('ExportManagedForm_fields', { hide: this.getAvailableSeatCount() === 0 })}>
              <SelectField // TODO: update this?
                label='Select administrator who will receive an email with the license file'
                name='exportedTo'
                value={this.state.form.exportedTo}
                onChange={this.onFieldChange}
              >
                {filterAdminsAllowedToExport(this.state.adminUsers.list).map(({ username }, i) => <option key={i} value={username}>{username}</option>)}
              </SelectField>
              <InputField
                type='text'
                label='First name'
                name='firstName'
                value={this.state.form.firstName}
                onChange={this.onFieldChange}
                autoFocus
              />
              <InputField
                type='text'
                label='Last name'
                name='lastName'
                value={this.state.form.lastName}
                onChange={this.onFieldChange}
              />
              <InputField
                type='number'
                label='Number of seats'
                name='seatCount'
                value={this.state.form.seatCount}
                min={1}
                max={this.getAvailableSeatCount()}
                step={1}
                onChange={this.onFieldChange}
                errorMessage={getSeatCountErrorMessage(this.state.form.seatCount, this.state.seatUsage.items, this.props.license)}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
