import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClipboardButton from 'react-clipboard.js';
import ReactTimeout from 'react-timeout';
import Api from '../utils/Api';

export default ReactTimeout(class CopyTokenUrlButton extends Component {

  static propTypes = {
    component: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    onShowFeedback: PropTypes.func.isRequired,
    onCancelFeedback: PropTypes.func.isRequired
  };

  state = {
    timeoutId: null
  };

  onPress = () => {
    this.props.onShowFeedback();
    this.props.clearTimeout(this.state.timeoutId);

    this.setState({ timeoutId: this.props.setTimeout(this.onTimeout, 2000) });

    Api.post(`/teams/${this.props.token}/events/copy/team-url`, { origin: this.props.origin });
  };

  onTimeout = () => {
    this.setState({ timeoutId: null });
    this.props.onCancelFeedback();
  };

  render() {
    const { component, token, ...otherProps } = this.props;

    return (
      <ClipboardButton
        component={component}
        data-clipboard-text={`${window.location.origin}/${token}`}
        onSuccess={this.onPress}
        {...otherProps}
      >
        {this.props.children}
      </ClipboardButton>
    );
  }
});
