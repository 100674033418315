import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import Api from '../../../utils/Api';
import _ from 'lodash';
import User from '../../../utils/User';
import Button from '../../../components/Button';
import SsoForm from './SsoForm';
import SsoList from './SsoList';
import Alert from '../../../components/Alert';
import { SsoNotification } from './labels';
import './styles.css';

const SsoSettingsPage = () => {
  const [ list, setList ] = useState([]);
  const [ fetching, setFetching ] = useState(true);
  const [ addForm, setAddForm ] = useState(false);
  const [ notification, setNotification ] = useState(null);
  const [ uuid, setUuid ] = useState(null);
  const ssoChannel = new BroadcastChannel('sso');

  const fetch = () => {
    Api.get('/sso', ({ list }) => {
      setList(list);
      setFetching(false);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const listener = message => {
    if (message.data && message.data.uuid && message.data.uuid === uuid) {
      const ssoStatus = message.data.status;
      if (ssoStatus === 'VERIFIED') {
        setNotification(SsoNotification.VEFRIFIED);
        Api.post(`/sso/${message.data.uuid}/activate`, null, ({ error }) => {
          if (!error) {
            setNotification(SsoNotification.ACTIVATED);
          }
          fetch();
        });
      }
      else {
        fetch();
      }
    }
  };

  const initSsoChannelListener = () => {
    ssoChannel.addEventListener('message', listener);
  };

  useEffect(() => {
    initSsoChannelListener();
    return () => {
      ssoChannel.removeEventListener('message', listener);
    };
  });

  const onAddButtonPress = () => {
    setNotification(null);
    setAddForm(true);
  };

  const closeModal = () => {
    setAddForm(false);
  };

  const onModalSave = () => {
    closeModal();
    fetch();
  };

  const sortedSsos = _(list)
    .orderBy([ 'updatedAt' ], [ 'desc' ])
    .value();

  const verify = ((uuid, callback) => {
    Api.post(`/sso/${uuid}/verifying`, null, () => {
      window.open(`/sso/verify/${uuid}`, '_blank');
      setUuid(uuid);
      if (callback) {
        callback();
      }
      setNotification(SsoNotification.VERIFYING);
    });
  });

  return (
    <Page
      className='SsoSettingsPage'
      title='SSO Configuration'
      titleDescription={
        <div className='SsoSettingsPage_heading-description'>
          Allow users to log in using Single Sign-On
          <a className='SsoSettingsPage_manual-link-text' href='https://manuals.jrebel.com/zt-license-management/settings/' target='_blank'>Learn More</a>
        </div>
      }
      alert={notification && <Alert type='ok'>{notification}</Alert>}
      buttons={User.isAccountAdmin() && (
        <Button type='primary' onClick={onAddButtonPress}>Add SSO</Button>
      )}
    >
      {addForm && (
        <SsoForm
          sso={{}}
          onSave={onModalSave}
          onCancel={closeModal}
          onVerify={verify}
          onShowNotification={setNotification}
        />
      )}

      <SsoList list={sortedSsos} onListUpdate={fetch} fetching={fetching} onVerify={verify} onShowNotification={setNotification} />
    </Page>
  );
};

export default SsoSettingsPage;
