import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Page from '../../components/Page';
import Api from '../../utils/Api';
import { notificationNameLabels, reminderNameLabels } from '../AdminPages/labels';
import './styles.css';

const UnsubscribePage = () => {
  const [ name, setName ] = useState(null);
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const notificationName = searchParams.get('notificationName');
  const reminderName = searchParams.get('reminderName');
  const user = searchParams.get('user') ? searchParams.get('user') : 'admin';

  useEffect(() => {
    switch (user) {
    case 'admin':
      if (token && notificationName) {
        Api.post(
          '/admin-users/unsubscribe/notification',
          { notificationName, token },
          () => setName(notificationName));
      }
      else if (token && reminderName) {
        Api.post(
          '/admin-users/unsubscribe/reminder',
          { reminderName, token },
          () => setName(reminderName));
      }
      else {
        navigate('/user-settings');
      }
      break;
    case 'invited':
      if (token && reminderName) {
        Api.post(
          '/seat-users/invited/unsubscribe',
          { name: reminderName, token },
          () => setName(reminderName));
      }
      break;
    case 'seat':
      if (token && reminderName) {
        Api.post(
          '/seat-users/unsubscribe',
          { name: reminderName, token },
          () => setName(reminderName));
      }
      break;
    default:
      navigate('/user-settings');
    }
  }, []);

  return (
    <Page className='UnsubscribePage' title='Unsubscribe from email notifications' noHeading>
      {name ? (
        <div className='UnsubscribePage_box'>
          <div className='UnsubscribePage_message'>
            <h1>We will not notify you about "{notificationNameLabels[name] ||
              reminderNameLabels[name] || name}" anymore.</h1>
            <div className='UnsubscribePage_help-text'>
              <span>Need help? </span><a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>
            </div>
          </div>
        </div>
      ) : (
        <h1>Please wait...</h1>
      )}
    </Page>
  );

};

export default UnsubscribePage;
