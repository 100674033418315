import _ from 'lodash';
import React from 'react';
import { ActionsButton } from '../../../components/ActionsButton';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { DropdownMenuButton } from '../../../components/Dropdown';
import InfoTooltip from '../../../components/InfoTooltip';
import Page from '../../../components/Page';
import RouterComponent from '../../../components/RouterComponent';
import { TableCell } from '../../../components/Table';
import { productTypeOfStandalone } from '../../../enums/LicenseType';
import { sortByProductType, sortProductTypes } from '../../../enums/ProductType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { withRouter } from '../../../utils/withRouter';
import SeatUserInviteForm from '../SeatUserInviteForm';
import { isDomainBlacklisted } from '../selectors';
import Alerts from './Alerts';
import BlockModal from './BlockModal';
import DeleteModal from './DeleteModal';
import ResendModal from './ResendModal';
import { filterUsers, getClosestLastSeen, getFarthestOfflineUntil } from './selectors';
import SeatUserTable from './SeatUserTable';
import Tabs from './Tabs';
import UnblockModal from './UnblockModal';

export default withRouter(class SeatUserPage extends RouterComponent {

  fetchCounter = 0;
  redirected = false;

  state = {
    modal: {
      active: null,
      user: null
    },

    alert: {
      active: this.getRouteStateParameter('alert'),
      email: null
    },

    domainWhitelistingEnabled: null,
    seatUsers: [],
    invitedSeatUsers: [],
    dedicatedLicenses: [],
    teams: [],
    licensedProducts: [],
    whitelistedDomains: [],
    fetching: true
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fetching && !this.state.fetching && this.getRouteParameter('teamName') && this.getRouteParameter('origin') === 'EMAIL') {
      const token = this.state.teams.find(({ name }) => name === this.getRouteParameter('teamName')).token;
      Api.post(`/teams/${token}/events/press/users-invite`, { eventUiOrigin: 'TEAM_USERS_PAGE', userActionOrigin: 'EMAIL' });
    }
  }

  fetch() {
    this.get('/account', ({ domainWhitelistingEnabled }) => {
      this.setState({ domainWhitelistingEnabled });
    });

    this.get('/seat-users', ({ list }) => {
      this.setState({ seatUsers: list });
    });

    this.get('/seat-users/invited', ({ list }) => {
      this.setState({ invitedSeatUsers: list });
    });

    if (!User.isTeamLead()) {
      this.get('/licenses/exported/dedicated', ({ list }) => {
        this.setState({ dedicatedLicenses: list });
      });
    }

    this.get('/teams', ({ list }) => {
      const teamName = this.getRouteParameter('teamName');

      if (teamName && !list.find(({ name }) => name === teamName)) {
        this.routeWithStateParameters('/teams', { redirectCause: 'teamNotFound' });

        this.redirected = true;
      }
      else {
        this.setState({ teams: list });
      }
    });

    this.get('/account/licensed-products', ({ list }) => {
      this.setState({ licensedProducts: list });
    });

    this.get('/whitelisted-domains', ({ list }) => {
      this.setState({ whitelistedDomains: list });
    });
  }

  get(url, callback) {
    this.fetchCounter++;

    Api.get(url, response => {
      if (!this.redirected) {
        callback(response);

        if (!this.redirected && --this.fetchCounter === 0 && this.state.fetching) {
          this.setState({ fetching: false });
        }
      }
    });
  }

  onInviteUsersButtonPress = () => {
    this.removeAlert();
    const token = this.state.teams.find(({ name }) => name === this.getRouteParameter('teamName')).token;
    this.updateRouteParameter('modal', 'inviteForm');

    Api.post(`/teams/${token}/events/press/users-invite`, { eventUiOrigin: 'TEAM_USERS_PAGE', userActionOrigin: 'GUI' });
  };

  onOpenModalButtonPress = ({ currentTarget }) => {
    this.removeAlert();
    this.setState({
      modal: {
        active: currentTarget.getAttribute('data-modal-id'),
        user: this.getUsers().find(({ email, status }) => email === currentTarget.getAttribute('data-email') && status === currentTarget.getAttribute('data-status'))
      }
    });
  };

  closeModal = () => {
    this.setState({ modal: {} });
    this.updateRouteParameters({ modal: undefined, origin: undefined });
  };

  closeModalAndRefetch = () => {
    this.closeModal();
    this.fetch();
  };

  showAlert(name, email) {
    this.setState({ alert: { active: name, email } });
  }

  removeAlert() {
    this.setState({ alert: {} });
  }

  onResendSuccess = email => {
    this.closeModal();
    this.showAlert('resend', email);
  };

  onBlockSuccess = email => {
    this.closeModalAndRefetch();
    this.showAlert('block', email);
  };

  onUnblockSuccess = email => {
    this.closeModalAndRefetch();
    this.showAlert('unblock', email);
  };

  onDeleteSuccess = email => {
    this.closeModalAndRefetch();
    this.showAlert('delete', email);
  };

  getUsers() {
    if (this.state.fetching) {
      return [];
    }

    return [
      ..._(this.state.seatUsers)
        .map(seatUser => ({
          ...seatUser,
          seats: seatUser.seatReservations
        })),

      ...this.state.invitedSeatUsers
        .map(({ email, teamToken }) => ({
          email,
          status: 'INVITED',
          teamToken,
          domainBlacklisted: this.state.domainWhitelistingEnabled && isDomainBlacklisted(email, this.state.whitelistedDomains),
          seats: [ { teamName: this.state.teams.find(({ token }) => token === teamToken).name } ]
        })),

      ..._(this.state.dedicatedLicenses)
        .groupBy(({ licenseUser: { email } }) => email)
        .map((licenses, email) => ({
          email,
          status: 'INDIVIDUAL',
          seats: licenses.map(dedicatedLicense => ({
            ...dedicatedLicense,
            productType: productTypeOfStandalone(dedicatedLicense.type)
          }))
        }))
        .value()
    ];
  }

  sortUsers(users) {
    const noSorting = [ () => '' ];
    let sortBy;

    switch (this.getRoutingKey()) {
    case 'team':
      sortBy = [
        ({ status }) => status === 'INVITED' || '',
        user => getFarthestOfflineUntil(user) || '',
        user => getClosestLastSeen(user) || ''
      ];
      break;
    case 'offline':
      sortBy = [ user => getFarthestOfflineUntil(user) ];
      break;
    case 'invited':
      sortBy = noSorting;
      break;
    case 'blocked':
      sortBy = [ ({ statusChangedAt }) => statusChangedAt ];
      break;
    case 'forgotten':
      sortBy = noSorting;
      break;
    case 'individual':
      sortBy = noSorting;
      break;
    default:
      sortBy = [
        user => getFarthestOfflineUntil(user) || '',
        user => getClosestLastSeen(user) || ''
      ];
    }

    return _(users)
      .map(user => Object.assign({}, user))
      .sortBy(sortBy)
      .reverse()
      .map(user => {
        user.seats = sortByProductType(user.seats);
        return user;
      })
      .value();
  }

  getPageTitle() {
    if (this.getRoutingKey() === 'team' && !this.state.fetching) {
      return `Users of ${this.getRouteParameter('teamName')}`;
    }

    if (this.hasRouteParameter('orderId')) {
      if (this.getRouteParameter('orderId') === 'EVALUATION') {
        return 'Individual licenses of your evaluation';
      }

      return `Individual licenses of order #${this.getRouteParameter('orderId')}`;
    }

    return 'Users';
  }

  getDomainBlacklistedText() {
    return 'This user cannot activate due to email domain verification. Please invite the user with his company email.';
  }

  // eslint-disable-next-line react/display-name
  renderActionsCell = ({ email, status, domainBlacklisted }) => {
    if (!User.canEdit()) {
      return <TableCell />;
    }

    const buttons = [];

    if (status === 'INVITED') {
      buttons.push(
        <DropdownMenuButton
          key='resend'
          data-modal-id='resend'
          data-email={email}
          data-status={status}
          disabled={domainBlacklisted}
          onPress={this.onOpenModalButtonPress}
        >
          <span>Resend invite</span>
          {domainBlacklisted && <InfoTooltip size='l' text={this.getDomainBlacklistedText()} />}
        </DropdownMenuButton>);
    }

    if (status === 'ACTIVE' || status === 'INVITED') {
      buttons.push(<DropdownMenuButton key='block' data-modal-id='block' data-email={email} data-status={status} onPress={this.onOpenModalButtonPress}>Block</DropdownMenuButton>);
    }

    if (status === 'ACTIVE' || status === 'BLOCKED') {
      buttons.push(
        <DropdownMenuButton key='delete' data-modal-id='delete' data-email={email} data-status={status} onPress={this.onOpenModalButtonPress}>Delete</DropdownMenuButton>);
    }

    if (status === 'BLOCKED') {
      buttons.push(
        <DropdownMenuButton
          key='unblock'
          data-modal-id='unblock'
          data-email={email}
          data-status={status}
          onPress={this.onOpenModalButtonPress}
        >
          Unblock
        </DropdownMenuButton>);
    }

    return <TableCell>{buttons.length > 0 && <ActionsButton className='pull-right'>{buttons}</ActionsButton>}</TableCell>;
  };

  render() {
    return (
      <Page
        className='SeatUserPage'
        alert={<Alerts alert={this.state.alert} />}
        title={this.getPageTitle()}
        buttons={this.getRoutingKey() === 'team' && User.canEdit() && (
          <Button type='primary' onClick={this.onInviteUsersButtonPress}>Invite users</Button>
        )}
      >
        {this.getRouteParameter('modal') === 'inviteForm' && this.hasRouteParameter('teamName') && !this.state.fetching && (
          <SeatUserInviteForm
            team={{
              token: this.state.teams.find(({ name }) => name === this.getRouteParameter('teamName')).token,
              name: this.getRouteParameter('teamName')
            }}
            onSave={this.closeModalAndRefetch}
            onCancel={this.closeModal}
          />
        )}
        {this.state.modal.active === 'resend' && (
          <ResendModal user={this.state.modal.user} onSuccess={this.onResendSuccess} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'block' && (
          <BlockModal user={this.state.modal.user} onSuccess={this.onBlockSuccess} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'unblock' && (
          <UnblockModal user={this.state.modal.user} onSuccess={this.onUnblockSuccess} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'delete' && (
          <DeleteModal user={this.state.modal.user} onSuccess={this.onDeleteSuccess} onCancel={this.closeModal} />
        )}
        {this.getRoutingKey() !== 'team' && !this.hasRouteParameter('orderId') && !this.state.fetching && (
          <Tabs users={this.getUsers()} />
        )}

        <Card>
          <SeatUserTable
            fetching={this.state.fetching}
            users={this.sortUsers(filterUsers(this.getUsers(), this.getRoutingKey(), this.getRouteParameters()))}
            filterValues={{
              teamName: _(this.state.teams).map(({ name }) => name).sort().value(),
              productType: sortProductTypes(this.state.licensedProducts)
            }}
            domainBlacklistedText={this.getDomainBlacklistedText()}
            renderActionsCell={this.renderActionsCell}
          />
        </Card>
      </Page>
    );
  }
});
