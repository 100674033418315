import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../../components/Alert';
import Bubble from '../../../../components/Bubble';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import { InputField } from '../../../../components/InputField';
import LicenseTypeLabel from '../../../../components/LicenseTypeLabel';
import Modal, { ModalButton } from '../../../../components/Modal';
import RouterComponent from '../../../../components/RouterComponent';
import UnitLabel from '../../../../components/UnitLabel';
import Api from '../../../../utils/Api';
import { withRouter } from '../../../../utils/withRouter';
import { isDomainBlacklisted } from '../../selectors';
import { hasExpired, isInTheFuture } from '../selectors';
import { getAvailableSeatCount, getEmailValidationMessage, getHostedSeatReservation } from './selectors';
import './styles.css';

export default withRouter(class ExportDedicatedForm extends RouterComponent {

  static propTypes = {
    license: PropTypes.shape({
      type: PropTypes.string.isRequired,
      seatCount: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
      orderItemId: PropTypes.string.isRequired,
      exportedSeatCounts: PropTypes.object.isRequired
    }).isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    domainWhitelistingEnabled: {
      value: null,
      fetching: true
    },
    seatUsage: {
      items: [],
      fetching: true
    },
    seatUsers: {
      list: [],
      fetching: true
    },
    whitelistedDomains: {
      list: [],
      fetching: true
    },
    dedicatedLicenses: {
      list: [],
      fetching: true
    },
    user: {
      firstName: '',
      lastName: '',
      email: ''
    },
    users: [],
    emailErrorMessage: null,
    showDomainWarning: false,
    saving: false
  };

  componentDidMount() {
    Api.get('/account', ({ domainWhitelistingEnabled }) => {
      this.setState({ domainWhitelistingEnabled: { value: domainWhitelistingEnabled, fetching: false } });
    });

    Api.get('/whitelisted-domains', ({ list }) => {
      this.setState({ whitelistedDomains: { list, fetching: false } });
    });

    Api.get('/seat-users', ({ list }) => {
      this.setState({ seatUsers: { list, fetching: false } });
    });

    Api.get('/statistics', ({ seatUsage }) => {
      this.setState({ seatUsage: { items: seatUsage, fetching: false } });
    });

    Api.get('/licenses/exported/dedicated', ({ list }) => {
      this.setState({ dedicatedLicenses: { list, fetching: false } });
    });
  }

  onFieldChange = ({ currentTarget: { name, value } }) => {
    this.setState(state => {
      if (name === 'email') {
        return { emailErrorMessage: null, user: { ...state.user, [name]: value.toLowerCase() } };
      }

      return { user: { ...state.user, [name]: value } };
    });
  };

  onSubmitButtonPress = () => {
    this.setState({ saving: true });

    Api.post('/licenses/exported/dedicated', {
      users: this.state.users,
      orderItemId: this.props.license.orderItemId
    }, () => {
      this.routeWithStateParameters('/seat-users/individual', { alert: 'exportDedicated' });
    });
  };

  onFormSubmit = e => {
    e.preventDefault();

    if (getEmailValidationMessage(this.state.user.email, this.state.domainWhitelistingEnabled.value, this.state.whitelistedDomains.list)) {
      this.setState(state => ({ emailErrorMessage: getEmailValidationMessage(state.user.email, state.domainWhitelistingEnabled.value, state.whitelistedDomains.list) }));

      if (this.state.domainWhitelistingEnabled.value && isDomainBlacklisted(this.state.user.email, this.state.whitelistedDomains.list)) {
        this.setState({ showDomainWarning: true });
      }

      return;
    }

    this.setState((state, props) => {
      const users = [ ...state.users ];

      if (!users.some(({ email }) => email === state.user.email)) {
        if (getAvailableSeatCount(users.concat([ state.user ]), state.seatUsage.items, state.seatUsers.list, props.license) < 0) {
          return { emailErrorMessage: 'There are no more available seats to export from this license' };
        }

        users.push(state.user);
      }

      return { users, user: { firstName: '', lastName: '', email: '' } };
    });
  };

  onDeleteUser = ({ currentTarget }) => {
    this.setState(state => {
      const users = [ ...state.users ];
      users.splice(users.findIndex(({ email }) => email === currentTarget.getAttribute('data-email')), 1);

      return { users, emailErrorMessage: null };
    });
  };

  getOverlappingDedicatedLicense(email) {
    const license = this.props.license;

    return this.state.dedicatedLicenses.list
      .filter(({ licenseUser }) => licenseUser.email === email)
      .filter(({ type }) => type === license.type)
      .filter(({ endDate }) => !hasExpired(endDate))
      .find(dedicatedLicense => this.hasOverlap(dedicatedLicense, license));
  }

  hasOverlap(license1, license2) {
    return _([ license1.startDate, license2.startDate ]).max() < _([ license1.endDate, license2.endDate ]).min();
  }

  isAddButtonDisabled() {
    return !this.state.user.firstName ||
      !this.state.user.lastName ||
      !this.state.user.email ||
      this.state.domainWhitelistingEnabled.fetching ||
      this.state.whitelistedDomains.fetching ||
      this.state.seatUsers.fetching ||
      this.state.dedicatedLicenses.fetching;
  }

  renderBubbleWarning(email) {
    if (getHostedSeatReservation(email, this.state.seatUsers.list, this.props.license)) {
      return 'This user has already taken a regular seat. Exporting a seat for individual use will block him from taking a regular seat.';
    }

    if (this.getOverlappingDedicatedLicense(email, this.state.dedicatedLicenses.list, this.props.license)) {
      const { startDate, endDate } = this.getOverlappingDedicatedLicense(email, this.state.dedicatedLicenses.list, this.props.license);
      return (
        <Fragment>
          <span>This user already has a seat for individual use from </span>
          <span><DateTimeLabel timestamp={startDate} /> until <DateTimeLabel timestamp={endDate} /></span>
          <span>, which overlaps with the current seat.</span>
        </Fragment>
      );
    }

    return null;
  }

  render() {
    return (
      <div className='ExportDedicatedForm'>
        {!this.state.seatUsage.fetching && (
          <Modal
            title={
              <span>
                <span className='ExportDedicatedForm_light-title'>Export individual </span>
                <LicenseTypeLabel id={this.props.license.type} />
                <span className='ExportDedicatedForm_light-title'> licenses</span>
              </span>
            } /* TODO Add Cancel here as well? if yes then is confirm also triggered? */
            size='l'
            buttons={<ModalButton type='primary' disabled={this.state.saving || this.state.users.length === 0} onClick={this.onSubmitButtonPress}>Export</ModalButton>}
            onSubmit={this.onFormSubmit}
            onClose={this.props.onCancel}
            confirmClose={true}
          >
            <p>
              You are about to export individual license files.
              A file will be sent to entered email addresses which can be used to enable <LicenseTypeLabel id={this.props.license.type} /> offline.
              The seat will be tied to a single user for the entire validity period and can only be reassigned
              by contacting <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>.
              Time savings data is not gathered for that seat.
              It is not possible to block or automatically renew individual licenses.
            </p>
            <Alert type='info'>
              <span>If you are having trouble activating JRebel or XRebel, please read through our </span>
              <a href='https://manuals.jrebel.com/zt-license-management/troubleshooter/#proxy-troubleshooter' target='_blank' rel='noopener noreferrer'>
                Proxy Configuration Guide
              </a>.
            </Alert>
            <div className='ExportDedicatedForm_available-seats'>
              <UnitLabel
                valueClass='ExportDedicatedForm_available-seats_number'
                value={getAvailableSeatCount(this.state.users, this.state.seatUsage.items, this.state.seatUsers.list, this.props.license)}
                unit='seat'
              />
              <span> available for </span>
              <LicenseTypeLabel id={this.props.license.type} />
              {isInTheFuture(this.props.license.startDate) && <span> from <DateTimeLabel timestamp={this.props.license.startDate} /></span>}
              <span> until </span><DateTimeLabel timestamp={this.props.license.endDate} />
            </div>

            {this.state.showDomainWarning && (
              <Alert type='warn'>
                <AlertEmphasis>Only following email domains are allowed: </AlertEmphasis>
                {this.state.whitelistedDomains.list.map(({ domain }) => domain).join(', ')}
              </Alert>
            )}

            <div className='ExportDedicatedForm_fields'>
              <InputField
                type='text'
                label='First name'
                name='firstName'
                value={this.state.user.firstName}
                onChange={this.onFieldChange}
                autoFocus
              />
              <InputField
                type='text'
                label='Last name'
                name='lastName'
                value={this.state.user.lastName}
                onChange={this.onFieldChange}
              />
              <InputField
                type='text'
                label='Email'
                placeholder='Email address'
                name='email'
                value={this.state.user.email}
                onChange={this.onFieldChange}
                errorMessage={this.state.emailErrorMessage}
              />
            </div>

            <ModalButton type='primary' disabled={this.isAddButtonDisabled()} submit>Add</ModalButton>

            <div className='ExportDedicatedForm_bubbles'>
              {this.state.users.map(({ firstName, lastName, email }) => (
                <Bubble key={email} data-email={email} text={`${firstName} ${lastName} (${email})`} onRemove={this.onDeleteUser} warningTooltip={this.renderBubbleWarning(email)} />
              ))}
            </div>
          </Modal>
        )}
      </div>
    );
  }
});
