import moment from 'moment';
import PropTypes from 'prop-types';

DateTimeLabel.propTypes = {
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  withJustNow: PropTypes.bool,
  displayAsTimestamp: PropTypes.bool,
  displayAsFromNow: PropTypes.bool
};

/**
 * @returns string
 */
export default function DateTimeLabel({ timestamp = null, withJustNow = false, displayAsTimestamp = false, displayAsFromNow = false }) {
  if (!timestamp) {
    return null;
  }

  const momentTimestamp = moment.utc(timestamp);

  if (withJustNow && momentTimestamp.isAfter(moment().subtract({ hours: 2 }))) {
    return 'just now';
  }

  if (displayAsFromNow) {
    return momentTimestamp.fromNow();
  }

  if (displayAsTimestamp) {
    return momentTimestamp.format('D.M.YYYY HH:mm');
  }

  return momentTimestamp.format('MMM D, YYYY');
}
