import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import InfoTooltip from '../../../../components/InfoTooltip';
import { InputField } from '../../../../components/InputField';
import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import ValidationTooltip from '../../../../components/ValidationTooltip';
import { sortByProductType } from '../../../../enums/ProductType';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import { getRedeployTimeValidationMessage } from '../../selectors';
import {
  getAvailableSeatCountForLicenseType,
  getProductEnabledValidationMessage,
  getSeatLimitValidationMessage,
  getSeatsLeftForProduct,
  hasRebelSuiteSeatsForProduct
} from './selectors';
import './styles.css';

export default class ProductsPanel extends Component {

  static propTypes = {
    products: PropTypes.array.isRequired,
    onProductsChange: PropTypes.func.isRequired,
    onRedeployTimeChange: PropTypes.func.isRequired,
    redeployTimeSeconds: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
  };

  inputElements = {};

  state = {
    seatUsage: []
  };

  componentDidMount() {
    if (!User.isTeamLead()) {
      Api.get('/statistics', ({ seatUsage }) => {
        this.setState({ seatUsage });
      });
    }
  }

  onFieldChange = ({ currentTarget }) => {
    const products = _.cloneDeep(this.props.products);
    const value = currentTarget.type === 'checkbox' ? currentTarget.checked : currentTarget.value;
    const targetProductType = currentTarget.getAttribute('data-product-type');

    _(products).find(({ productType }) => productType === targetProductType)[currentTarget.name] = value;

    if (currentTarget.name === 'enabled') {
      products.forEach(product => product.lastTouched = product.productType === targetProductType);
    }

    this.props.onProductsChange(products);
  };

  onInputRef = element => {
    if (element) {
      this.inputElements[`${element.getAttribute('data-product-type')}.${element.name}`] = element;
    }
  };

  getSeatsLeftTooltipText() {
    const rebelSuiteSeats = getAvailableSeatCountForLicenseType(this.state.seatUsage, 'REBEL_SUITE');
    return `Includes ${rebelSuiteSeats} RebelSuite seat${rebelSuiteSeats === 1 ? '' : 's'}, that allow${rebelSuiteSeats === 1 ? 's' : ''}
      to activate both JRebel and XRebel by a single person.`;
  }

  render() {
    const productsToShow = this.props.products
      .filter(({ productType }) => (!User.isTeamLead() && this.state.seatUsage.length > 0) || (User.isTeamLead() && productType === 'JREBEL'));

    return productsToShow.length > 0 && (
      <Table
        className='TeamFormProductsPanel'
        header={
          <TableRow>
            <th>Product</th>
            {!User.isTeamLead() && (
              <Fragment>
                <th>Enable</th>
                <th>
                  <span>Seat limit</span>
                  <InfoTooltip size='l' text='Limits how many seats can be used by the team. Leave empty for unlimited usage.' />
                </th>
                <th>Seats left</th>
              </Fragment>
            )}
            <th>
              <span>Default redeploy time</span>
              <InfoTooltip size='l' text='Default redeploy time to be used for analytics and ROI calculations for developers who do not configure a custom redeploy time.' />
            </th>
          </TableRow>
        }
        body={sortByProductType(productsToShow).map(product => {
          const { productType, limit, enabled } = product;

          return (
            <TableRow key={productType} className='TeamFormProductsPanel_product'>
              <TableCell className='TeamFormProductsPanel_product_name'>
                <ProductTypeLabel id={productType} />
              </TableCell>

              {!User.isTeamLead() && (
                <Fragment>
                  <TableCell className='TeamFormProductsPanel_product_enabled'>
                    <label>
                      <input
                        type='checkbox'
                        name='enabled'
                        checked={enabled}
                        data-product-type={productType}
                        onChange={this.onFieldChange}
                        ref={this.onInputRef}
                      />
                      <ValidationTooltip target={this.inputElements[`${productType}.enabled`]} text={getProductEnabledValidationMessage(this.props.products, product)} />
                    </label>
                  </TableCell>
                  <TableCell className='TeamFormProductsPanel_product_seat-limit'>
                    <InputField
                      type='text'
                      name='limit'
                      value={limit || ''}
                      disabled={!enabled}
                      data-product-type={productType}
                      idKey={productType}
                      onChange={this.onFieldChange}
                      hasError={getSeatLimitValidationMessage(this.props.products, product)}
                      ref={this.onInputRef}
                    />
                    <ValidationTooltip target={this.inputElements[`${productType}.limit`]} text={getSeatLimitValidationMessage(this.props.products, product)} />
                  </TableCell>
                  <TableCell className='TeamFormProductsPanel_product_seats-left'>
                    {getSeatsLeftForProduct(this.state.seatUsage, productType)}
                    {hasRebelSuiteSeatsForProduct(this.state.seatUsage, productType) && <InfoTooltip size='l' text={this.getSeatsLeftTooltipText()} />}
                  </TableCell>
                </Fragment>
              )}

              <TableCell className='TeamFormProductsPanel_product_redeploy_time'>
                {productType === 'JREBEL' && (
                  <Fragment>
                    <InputField
                      type='text'
                      name='redeployTime'
                      value={this.props.redeployTimeSeconds || ''}
                      disabled={!enabled}
                      data-product-type={productType}
                      idKey={productType}
                      onChange={this.props.onRedeployTimeChange}
                      hasError={enabled && getRedeployTimeValidationMessage(this.props.redeployTimeSeconds)}
                      ref={this.onInputRef}
                    />
                    <span> seconds</span>
                    <ValidationTooltip
                      target={this.inputElements[`${productType}.redeployTime`]}
                      text={enabled ? getRedeployTimeValidationMessage(this.props.redeployTimeSeconds) : null}
                    />
                  </Fragment>
                )}
              </TableCell>

            </TableRow>
          );
        })}
      />
    );
  }
}
