import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ComponentPropsWithoutRef } from 'react';
import InfoTooltip from '../InfoTooltip';
import './styles.css';

interface InputFieldProps extends ComponentPropsWithoutRef<'input'> {
  label?: string,
  errorMessage: string | undefined | null,
  hasError?: boolean,
  tooltip?: any,
  idKey?: string | number
}

// eslint-disable-next-line react/display-name
export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props: InputFieldProps, ref) => {
  const {
    label, name, placeholder, errorMessage, hasError = !!errorMessage, className, tooltip,
    idKey, ...otherProps
  } = props;
  if (props.type === 'password') {
    otherProps.autoComplete = 'off';
  }

  const inputFieldId = `${name}${idKey ? `-${idKey}` : ''}`;

  return (
    <div className={classNames('InputField', { 'has-error': hasError, 'has-feedback': errorMessage })}>
      <div>
        {label && <label className='InputField_label' htmlFor={inputFieldId}>{label}</label>}
        {tooltip && !errorMessage && <InfoTooltip {...tooltip} />}
      </div>
      <div className='InputField_input-wrapper'>
        <input
          className={classNames('InputField_input', className)}
          placeholder={placeholder || label}
          name={name}
          id={inputFieldId}
          ref={ref}
          maxLength={255}
          {...otherProps}
        />
      </div>
      {errorMessage && <label className='InputField_error' htmlFor={inputFieldId}>{errorMessage}</label>}
    </div>
  );
});

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  tooltip: PropTypes.object
};
