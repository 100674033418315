import _ from 'lodash';

export function getTotalSeats(seatUsage) {
  return _(seatUsage).sumBy('total');
}

export function getTotalReservedSeats(seatUsage) {
  return _(seatUsage).sumBy('reserved');
}

export function getTotalFreeSeats(seatUsage) {
  return getTotalSeats(seatUsage) - getTotalReservedSeats(seatUsage);
}

export function getTotalTimeSavedPerSeatUser(timeSavedSeconds, seatUserCount) {
  return seatUserCount.total > 0 ? timeSavedSeconds.total / seatUserCount.total : 0;
}
