import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './styles.css';
import UnitLabel from '../../../../../components/UnitLabel';
import { calculateRoiOld, getDaysUntilPeriodOld } from '../ActualRoiPanel/selectors';
import RoiCalculatorPanel, { SavingsNumbers } from '../RoiCalculatorPanel';
import { getJRebelHostedSeatCount, shouldShowSavingsSummaryForActualSavings } from '../selectors';
import UsageGauge, { calculateLicenseRatio, thresholdToColor, thresholdToSelector } from './UsageGauge';

function projectRoi(roi, actualUsage, totalLicenses) {
  const ratio = totalLicenses / actualUsage;
  return {
    ...roi,
    timeSavedYearlyHours: roi.timeSavedYearlyHours * ratio,
    moneySavedInPeriod: roi.moneySavedInPeriod * ratio,
    moneySavedYearly: roi.moneySavedYearly * ratio,
    jrebelPaidOffInDays: roi.jrebelPaidOffInDays / ratio
  };
}

export default class ProjectedSavingsPanel extends Component {

  static propTypes = {
    developerSalaryYearly: PropTypes.number.isRequired,
    jrebelPriceYearly: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    timeSavedSeconds: PropTypes.object.isRequired,
    seatUserCount: PropTypes.object.isRequired,
    seatUsage: PropTypes.array.isRequired,
    onRedeployTimeChange: PropTypes.func.isRequired,
    accountCreatedAt: PropTypes.string,
    fetchingAccountCreatedAt: PropTypes.bool.isRequired,
    period: PropTypes.string.isRequired
  };

  renderBadRoiMessage() {
    const daysUntilWeekOld = getDaysUntilPeriodOld(this.props.accountCreatedAt, 'weekly');

    if (daysUntilWeekOld > 0) {
      return <span>You just joined Rebel Licenses. We'll be able to show your savings after <UnitLabel value={daysUntilWeekOld} unit='day' />.</span>;
    }

    if (getJRebelHostedSeatCount(this.props.seatUsage) === 0) {
      return <span>No JRebel usage data available.</span>;
    }

    return <span>It looks like your JRebel licenses are not being used to their full potential.</span>;
  }

  renderUsageThresholdMessage(used, available) {
    const MESSAGES = [
      <div key='0'> Sorry!<br /> You are not using 100% of your licenses </div>,
      <div key='1'> Sorry!<br /> You are not using 100% of your licenses </div>,
      <div key='2'> Congratulations!<br /> You are using 100% of your licenses </div>
    ];
    const threshold = calculateLicenseRatio(used, available);
    const thresholdIndex = thresholdToSelector(threshold);
    const color = thresholdToColor(threshold);

    return (
      <div className='ProjectedSavingsPanel_usage_message' style={{ color, borderColor: color }}>
        {MESSAGES[thresholdIndex]}
      </div>
    );
  }

  render() {
    if (this.props.fetchingAccountCreatedAt) {
      return null;
    }

    const { accountCreatedAt, period, developerSalaryYearly, jrebelPriceYearly, seatUsage, timeSavedSeconds, seatUserCount, currency } = this.props;
    const roi = calculateRoiOld(accountCreatedAt, period, developerSalaryYearly, jrebelPriceYearly, seatUsage, timeSavedSeconds, seatUserCount);
    const hostedSeatsUsed = seatUserCount[period];
    const hostedSeatsAvailable = getJRebelHostedSeatCount(seatUsage);
    const projectedRoi = projectRoi(roi, hostedSeatsUsed, hostedSeatsAvailable);

    return (
      <RoiCalculatorPanel
        className='ProjectedSavingsPanel'
        heading='Projected savings'
        hideCentralSection={false}
        centralSection={
          <>
            <UsageGauge usedLicenses={hostedSeatsUsed} totalLicenses={hostedSeatsAvailable} />
            {this.renderUsageThresholdMessage(hostedSeatsUsed, hostedSeatsAvailable)}
            {shouldShowSavingsSummaryForActualSavings(roi, developerSalaryYearly, seatUserCount[period]) &&
              <SavingsNumbers
                savingsHeading='With 100% allocation JRebel would save you'
                timeSavedPerDeveloperDailyMinutes={projectedRoi.timeSavedPerDeveloperDailyMinutes}
                timeSavedYearlyHours={projectedRoi.timeSavedYearlyHours}
                moneySavedYearly={projectedRoi.moneySavedYearly}
                currency={currency}
              />
            }
          </>
        }
        currency={currency}
        {...roi}
      />
    );
  }
}
