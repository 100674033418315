import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import Page from '../../../components/Page';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Toggle from '../../../components/Toggle';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import AddWhitelistedDomainForm from '../AddWhitelistedDomainForm';
import EnableDomainWhitelistingModal from '../EnableDomainWhitelistingModal';
import { getHavingOtherDomainThanListOrForm } from '../selectors';
import DeleteLastWhitelistedDomainModal from './DeleteLastWhitelistedDomainModal';
import DeleteWhitelistedDomainModal from './DeleteWhitelistedDomainModal';
import DisableDomainWhitelistingModal from './DisableDomainWhitelistingModal';
import './styles.css';

export default class EmailDomainWhitelistPage extends Component {

  state = {
    list: [],
    fetchingList: true,
    domainWhitelistingEnabled: false,
    fetchingDomainWhitelistingEnabled: true,
    activeModal: null
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    Api.get('/account', ({ domainWhitelistingEnabled }) => {
      this.setState({ domainWhitelistingEnabled, fetchingDomainWhitelistingEnabled: false });
    });

    Api.get('/whitelisted-domains', ({ list }) => {
      this.setState({ list, fetchingList: false });
    });
  };

  onCreateButtonPress = () => {
    this.setState({ activeModal: 'addWhitelistedDomainForm' });
  };

  closeModal = () => {
    this.setState({ activeModal: null });
  };

  closeModalAndRefetch = () => {
    this.closeModal();
    this.fetch();
  };

  updateDomainWhitelisting = enabled => {
    Api.put('/account/domain-whitelisting-enabled', { domainWhitelistingEnabled: enabled }, this.fetch);
  };

  onToggleChange = enabled => {
    if (enabled) {
      Api.get('/seat-users/invited', ({ list }) => {
        if (getHavingOtherDomainThanListOrForm(list, this.state.list).length > 0) {
          this.setState({ activeModal: 'invitedSeatUserWarningEnable', invitedSeatUsers: list, saving: false });
        }
        else {
          this.enableDomainWhitelistingAndCloseModal();
        }
      });
    }
    else {
      this.setState({ activeModal: 'disableDomainWhitelistingWarning' });
    }
  };

  enableDomainWhitelistingAndCloseModal = () => {
    this.closeModal();
    this.updateDomainWhitelisting(true);
  };

  disableDomainWhitelistingAndCloseModal = () => {
    this.closeModal();
    this.updateDomainWhitelisting(false);
  };

  onDeleteButtonPress = ({ currentTarget }) => {
    if (this.state.domainWhitelistingEnabled && this.state.list.length === 1) {
      this.setState({ activeModal: 'deleteLastDomainWhitelistingWarning', domain: currentTarget.getAttribute('data-domain') });
    }
    else if (this.state.domainWhitelistingEnabled) {
      this.setState({ activeModal: 'deleteDomainWhitelistingWarning', domain: currentTarget.getAttribute('data-domain') });
    }
    else {
      this.deleteDomainAndCloseModal(currentTarget.getAttribute('data-domain'));
    }
  };

  deleteDomainAndCloseModal = domain => {
    Api.del('/whitelisted-domains', { domain }, this.closeModalAndRefetch);
  };

  sortedDomains() {
    return _(this.state.list)
      .sortBy('createdAt')
      .reverse()
      .value();
  }

  render() {
    return (
      <Page
        className='EmailDomainWhitelistPage'
        title='Email domain whitelist'
        headingWidget={!this.state.fetchingDomainWhitelistingEnabled && !this.state.fetchingList && (
          <Toggle enabled={this.state.domainWhitelistingEnabled} editable={User.canEdit()} onChange={this.state.list.length > 0 ? this.onToggleChange : this.onCreateButtonPress} />
        )}
        buttons={User.canEdit() && (
          <Button type='primary' onClick={this.onCreateButtonPress}>
            Add domain
          </Button>
        )}
      >
        {!this.state.fetchingDomainWhitelistingEnabled && !this.state.fetchingList && !this.state.domainWhitelistingEnabled && this.state.list.length > 0 && (
          <Alert type='info'>
            <AlertEmphasis>All email addresses can be used to activate products currently.</AlertEmphasis>
          </Alert>
        )}
        {this.state.activeModal === 'addWhitelistedDomainForm' && (
          <AddWhitelistedDomainForm
            onCancel={this.closeModal}
            onSave={this.closeModalAndRefetch}
            domains={this.state.list}
            domainWhitelistingEnabled={this.state.domainWhitelistingEnabled}
          />
        )}
        {this.state.activeModal === 'invitedSeatUserWarningEnable' && (
          <EnableDomainWhitelistingModal
            onCancel={this.closeModal}
            onSave={this.enableDomainWhitelistingAndCloseModal}
            domains={this.state.list}
            invitedSeatUsers={this.state.invitedSeatUsers}
          />
        )}
        {this.state.activeModal === 'disableDomainWhitelistingWarning' && (
          <DisableDomainWhitelistingModal onSuccess={this.disableDomainWhitelistingAndCloseModal} onCancel={this.closeModal} />
        )}
        {this.state.activeModal === 'deleteLastDomainWhitelistingWarning' && (
          <DeleteLastWhitelistedDomainModal onSuccess={() => this.deleteDomainAndCloseModal(this.state.domain)} onCancel={this.closeModal} domain={this.state.domain} />
        )}
        {this.state.activeModal === 'deleteDomainWhitelistingWarning' && (
          <DeleteWhitelistedDomainModal
            onSuccess={() => this.deleteDomainAndCloseModal(this.state.domain)}
            onCancel={this.closeModal}
            domain={this.state.domain}
            remainingDomains={this.state.list.filter(({ domain }) => domain !== this.state.domain)}
          />
        )}

        <Card>
          <Table
            header={this.state.list.length !== 0 && (
              <TableRow>
                <th className='EmailDomainWhitelistPage_domain-column'>Domain</th>
                <th className='EmailDomainWhitelistPage_created-column'>Added</th>
                <th className='EmailDomainWhitelistPage_actions-column' />
              </TableRow>
            )}
            body={
              <Fragment>
                {this.state.fetchingList && (
                  <TableRow className='EmailDomainWhitelistPage_fetching'>
                    <TableCell colSpan={3}>Fetching data...</TableCell>
                  </TableRow>
                )}
                {this.state.list.length === 0 && !this.state.fetchingList && (
                  <TableRow className='EmailDomainWhitelistPage_no-results'>
                    <TableCell colSpan={3}>
                      <div className='EmailDomainWhitelistPage_no-results-message'>
                        <div className='EmailDomainWhitelistPage_no-results-image' />
                        <div className='EmailDomainWhitelistPage_no-results-text'>
                          <h2>No domains added yet</h2>
                          {User.canEdit() && <div>Click Add domain to add some</div>}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {this.state.list.length > 0 && !this.state.fetchingList && this.sortedDomains().map(({ domain, createdAt, createdBy }) => {
                  return (
                    <TableRow key={domain}>
                      <TableCell className='EmailDomainWhitelistPage_domain'>{domain}</TableCell>
                      <TableCell>
                        <DateTimeLabel timestamp={createdAt} />
                        <span className='EmailDomainWhitelistPage_created-by'> by {createdBy}</span>
                      </TableCell>
                      <TableCell>
                        {User.canEdit() && (
                          <Button type='secondary' className='EmailDomainWhitelistPage_delete-button' data-domain={domain} onClick={this.onDeleteButtonPress}>
                            Delete
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            }
          />
        </Card>
      </Page>
    );
  }
}
