import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';

export default class DisableDomainWhitelistingModal extends Component {

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false
  };

  onDisableButtonPress = () => {
    this.setState({ saving: true });

    this.props.onSuccess();
  };

  render() {
    return (
      <Modal
        title='Turn off whitelist'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onDisableButtonPress}>Turn off whitelist</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>Activation will be allowed with all email addresses.</p>
        <p>Are you sure?</p>
      </Modal>
    );
  }
}
