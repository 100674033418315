import request from 'superagent/lib/client';
import EventEmitter from './EventEmitter';

const LOCAL_STORAGE_KEY = {
  accessToken: 'api.accessToken'
};

function getAccessToken() {
  return localStorage.getItem(LOCAL_STORAGE_KEY.accessToken);
}

function rememberAccessToken(token) {
  localStorage.setItem(LOCAL_STORAGE_KEY.accessToken, token);
}

function forgetAccessToken() {
  localStorage.removeItem(LOCAL_STORAGE_KEY.accessToken);
}

function execute(preparedRequest, callback) {
  if (getAccessToken()) {
    preparedRequest = preparedRequest.set('Authorization', `Bearer ${getAccessToken()}`);
  }

  preparedRequest.end((error, response) => {
    if (error) {
      handleError(response);
    }
    else {
      if (callback) {
        callback(response.text ? JSON.parse(response.text) : {});
      }
    }
  });
}

/**
 * See execute() but does not process the response. For use with binary data and files.
 * @param preparedRequest A superagent request object.
 * @param callback  On success
 */
function executeRaw(preparedRequest, callback) {
  if (getAccessToken()) {
    preparedRequest = preparedRequest.set('Authorization', `Bearer ${getAccessToken()}`);
  }

  preparedRequest.end((error, response) => {
    if (error) {
      handleError(response);
    }
    else {
      if (callback) {
        callback(response);
      }
    }
  });
}

function handleError(response) {
  if (!response || !response.statusCode) {
    alert('Server connection failed. Please check you internet connection.');
  }
  else if (response.statusCode === 401 || response.statusCode === 403) {
    EventEmitter.emit('api.unauthorized');
  }
  else {
    alert('This should not have happened. Please contact support-rebel@perforce.com');
  }
}

function addContextPath(url) {
  return `/api${url}`;
}

/**
 *
 * @param contentDisposition {string} The value of the http Content-Disposition header
 * @return {string|null}  The filename if set, null otherwise. It may be an empty string if set explicitly.
 */
function filenameFromContentDisposition(contentDisposition) {
  const filenameField = contentDisposition.split(';')
    .map(v => v.trimStart())
    .find(field => field.startsWith('filename'));

  if (!filenameField) {
    return null;
  }

  const firstEquals = filenameField.indexOf('=');
  if (firstEquals === -1 || firstEquals >= filenameField.length) {
    return null;
  }
  let filename = filenameField.substring(firstEquals + 1, filenameField.length);

  // This allows for reliably locating the trailing quote of a quoted filename value, but may lead to loss of trailing or leading whitespace if the value is not quoted.
  filename = filename.trim();
  // if name is quoted remove quotes.
  if (filename.startsWith('"') && filename.endsWith('"')) {
    filename = filename.substring(1, filename.length - 1);
  }
  return filename;
}

export default class Api {

  static forgetAccessToken = forgetAccessToken;

  static auth(params, authMethod, successCallback, failedCallback) {
    request.post(addContextPath(`/auth/${authMethod}`))
      .send(params)
      .accept('json')
      .type('form')
      .end((error, response) => {
        if (error) {
          if (response && response.statusCode === 400) {
            if (response.text) {
              const parsedResponse = JSON.parse(response.text);
              failedCallback(parsedResponse);
            }
            else {
              failedCallback({});
            }
          }
          else {
            handleError(response);
          }
        }
        else {
          const parsedResponse = JSON.parse(response.text);
          rememberAccessToken(parsedResponse.accessToken);
          successCallback(parsedResponse);
        }
      });
  }

  static post(url, params, callback) {
    execute(request.post(addContextPath(url)).accept('json').type('json').send(params), callback);
  }

  static put(url, params, callback) {
    execute(request.put(addContextPath(url)).accept('json').type('json').send(params), callback);
  }

  static del(url, params, callback) {
    execute(request.delete(addContextPath(url)).accept('json').type('json').send(params), callback);
  }

  static get(url, callback) {
    execute(request.get(addContextPath(url)).accept('json'), callback);
  }

  static initiateDownload(url) {
    window.open(addContextPath(url));
  }

  static initiateAuthenticatedDownload(url) {
    executeRaw(request.get(addContextPath(url)).responseType('blob'), response => {
      const contentDisposition = response.headers['content-disposition'];
      const filename = filenameFromContentDisposition(contentDisposition);

      const objectUrl = URL.createObjectURL(response.body);
      const link = window.document.createElement('a');
      if (filename) {
        link.download = filename;
      }

      link.href = objectUrl;
      link.style.display = 'none';
      window.document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        window.document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      }, 1000);
    });
  }
}
