import _ from 'lodash';
import { licenseTypesSupportingProduct } from '../../../../enums/LicenseType';

const usdRates = {
  EUR: 0.82,
  USD: 1.0
};

export function getJRebelHostedSeatCount(seatUsage) {
  return _(seatUsage).filter(({ licenseType }) => licenseTypesSupportingProduct('JREBEL').indexOf(licenseType) >= 0).map('hosted').sumBy('total');
}

export function getJRebelHostedSeatsUsedCount(seatUsage) {
  return _(seatUsage).filter(({ licenseType }) => licenseTypesSupportingProduct('JREBEL').indexOf(licenseType) >= 0).map('hosted').sumBy('reserved');
}

export function getJRebelPriceYearly(licenses, currency) {
  return (licenses.length === 0 ||
    licenses.some(({ startDate }) => startDate > '2022-06-30') ? 595 :
    licenses.some(({ startDate }) => startDate > '2018-04-01') ? 550 : 475
  ) * usdRates[currency];
}

export function getValidationMessage(name, inputs, constraints) {
  if (!constraints[name]) {
    return null;
  }

  const value = inputs[name];
  const { min, max, step } = constraints[name];

  if (value === '') {
    return 'Please fill in a number.';
  }

  if (max !== undefined && value > max) {
    return `Cannot be higher than ${max}.`;
  }

  if (min !== undefined && value < min) {
    return `Cannot be lower than ${min}.`;
  }

  if (step !== undefined && step === 0.1 && !_.isInteger(value * 10)) {
    return `Should be defined with precision of ${step}.`;
  }

  if (step !== undefined && step >= 1 && !_.isInteger(value)) {
    return 'Should be an integer value.';
  }

  if ((name === 'redeployTimeMinutes' || name === 'redeployCountHourly') && inputs.redeployTimeMinutes * inputs.redeployCountHourly >= 60) {
    return 'Redeploy time * restarts per hour must be less than 60 minutes.';
  }

  return null;
}

export function isEveryInputValid(inputs, constraints) {
  return Object.keys(inputs).every(name => !getValidationMessage(name, inputs, constraints));
}

export function parseFloatOrReturnOriginal(value) {
  return isNaN(parseFloat(value)) ? value : parseFloat(value);
}

export const shouldShowSavingsSummaryForActualSavings = (roi, averageSalary, userCount) => {
  // JR-12442 50% of developer salary.
  return userCount > 0 && roi.moneySavedYearly / userCount > 0.5 * averageSalary;
};
