export const notificationNameLabels = {
  TEAM_LIMIT_EXCEEDED: 'Team seat limit reached',
  ACCOUNT_OUT_OF_LICENSES: 'Out of licenses',
  INVALID_SEAT_USER_DOMAIN: 'Invalid email for activation'
};

export const reminderNameLabels = {
  ACTIVATE_ACCOUNT: 'Account not activated',
  ADD_TEAMS: 'No teams added',
  INVITE_USERS: 'No users invited',
  ACTIVATE_PRODUCT: 'Product not activated'
};
