import _ from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';
import { React } from 'react';
import './styles.css';

UsageGauge.propTypes = {
  usedLicenses: PropTypes.number.isRequired,
  totalLicenses: PropTypes.number.isRequired
};

const LOW_THRESHOLD = 0.5;
const MEDIUM_THRESHOLD = 0.9;
const HIGH_THRESHOLD = 1.0;

const ALL_THRESHOLDS = [ LOW_THRESHOLD, MEDIUM_THRESHOLD, HIGH_THRESHOLD ];

const LOW_COLOR = '#F16063';
const MEDIUM_COLOR = '#F59E0B';
const HIGH_COLOR = '#66CB9F';

const THRESHOLD_COLORS = [ LOW_COLOR, MEDIUM_COLOR, HIGH_COLOR ];
/**
 * Finds the threshold region index for the current threshold in ALL_THRESHOLDS.
 * @param threshold
 * @return {number} 0=low, 1=med, 2=high
 */
export function thresholdToSelector(threshold) {
  let selected = 0;
  for (const referenceThreshold of ALL_THRESHOLDS) {
    if (threshold <= referenceThreshold) {
      return selected;
    }
    else {
      selected++;
    }
  }
}

export function thresholdToColor(licenseRatio) {
  return THRESHOLD_COLORS[thresholdToSelector(licenseRatio)];
}

/**
 *
 * @param {number} licenseRatio
 * @returns {number}
 */
function thresholdToAngle(licenseRatio) {
  // 0 .. 1 -> 0 .. Math.PI
  return licenseRatio * Math.PI;
}

/**
 * Increases the length of the first and last segment to compensate for the thickness of the dial line.
 * @param {number[]} thresholds
 * @param {number} thickness .. of the dials outer segmented circle in relation to the radius of the gauge..
 */
function compensateForGaugeLineThickness(thresholds, thickness) {
  thresholds[0] += thickness;
  thresholds[thresholds.length - 1] += thickness;
  return thresholds;
}

/**
 *
 * @param {number[]} dialThresholds, in range 0..1 corresponding to 0 to 180 degrees on the dial.
 * @returns {number[]}
 */
function calculateBottomBarThresholds(dialThresholds) {
  const thresholds = _.map(dialThresholds, threshold => 1 - (Math.cos(thresholdToAngle(threshold))));
  return compensateForGaugeLineThickness(thresholds, 0.15);
}

/**
 *
 * @param {number[]} thresholds
 * @returns {number[]}
 */
function thresholdsToRatios(thresholds) {
  let previousThreshold = 0;
  return _.map(thresholds, threshold => {
    const ratio = threshold - previousThreshold;
    previousThreshold = threshold;
    return ratio;
  });
}

/**
 *
 * @param {number} angle 0 to PI. the deflection of the speedometer dial.
 * @return {number} -90 to 90. the angle in degrees and adjusted to the offsets for chart display
 */
function adjustAngleForDisplay(angle) {
  return (angle - 0.5 * Math.PI) * 180 / Math.PI;
}

const usageBarNumber = (number, threshold) => {
  const base = 0;
  const max = 240;
  // * 0.5 is for range reduction from 0 ... 2 to 0 ... 1
  // The 0.17 is related to the correction to line thickness and the 0.15 in calculateBottomBarThresholds.
  // But in this usage 0.17 seems to give better visual results.
  const correction = 1 / (2.0 + 0.17);
  const position = base + (threshold * correction) * max;
  return (<span key={number} className='UsageGauge_bar_numbers_number' style={{ left: `${position}px` }}>{number}</span>);
};

/**
 *
 * @param linearThresholds The fractions shown
 * @param projectedThresholds The actual position matching the colored bars.
 * @return {JSX.Element}
 * @constructor
 */
const UsageBarNumbers = (linearThresholds, projectedThresholds) => {
  const numberData = _.zip(linearThresholds, projectedThresholds);
  const numberElements = numberData.map(
    ([ threshold, projected ]) => {
      return (usageBarNumber((threshold * 100), projected));
    }
  );
  return (
    <div className='UsageGauge_bar_numbers'>
      {numberElements}
    </div>
  );
};

export function calculateLicenseRatio(used, total) {
  if (total === 0 || used >= total) {
    return 1.0;
  }
  return used / total;
}

export default function UsageGauge({ usedLicenses, totalLicenses }) {
  const licenseRatio = calculateLicenseRatio(usedLicenses, totalLicenses);
  const pointerColor = thresholdToColor(licenseRatio);
  const pointerAngle = adjustAngleForDisplay(thresholdToAngle(licenseRatio));

  const [ lowRatio, mediumRatio, high_Ratio ] = thresholdsToRatios([ LOW_THRESHOLD, MEDIUM_THRESHOLD, HIGH_THRESHOLD ]);

  const dialChartData = [
    {
      'id': 'low',
      'label': '',
      'value': 100 * lowRatio,
      'color': LOW_COLOR
    },
    {
      'id': 'med',
      'label': '',
      'value': 100 * mediumRatio,
      'color': MEDIUM_COLOR
    },
    {
      'id': 'high',
      'label': '',
      'value': 100 * high_Ratio,
      'color': HIGH_COLOR
    }
  ];

  const pointerChartData = [
    {
      'id': 'Used Licenses',
      'label': 'Used Licenses',
      'value': 1,
      'color': '#fff'
    }
  ];

  const barThresholds = calculateBottomBarThresholds([ LOW_THRESHOLD, MEDIUM_THRESHOLD, HIGH_THRESHOLD ]);
  const [ bottomLowRatio, bottomMediumRatio, bottomHighRatio ] = thresholdsToRatios(barThresholds);
  const dialBottomBarData = [
    {
      'id': '',
      'low': bottomLowRatio,
      'lowColor': LOW_COLOR,
      'med': bottomMediumRatio,
      'medColor': MEDIUM_COLOR,
      'high': bottomHighRatio,
      'highColor': HIGH_COLOR
    }
  ];

  const usageBarNumbers = UsageBarNumbers(
    [ 0, ...ALL_THRESHOLDS ],
    [ 0, ...barThresholds ]
  );

  return (
    <div>
      <div className='UsageGauge_speedometer'>

        <div className='UsageGauge_ratio'>
          <div className='UsageGauge_ratio_numbers'>
            {usedLicenses}/{totalLicenses}
          </div>
          <div className='UsageGauge_ratio_text'>
            Active Users
          </div>
        </div>

        <div className='UsageGauge_pie UsageGauge_pie_dial'>
          <ResponsivePie
            isInteractive={false}
            fit={false}
            colors={{ datum: 'data.color' }}
            data={dialChartData}
            startAngle={-90}
            endAngle={90}
            innerRadius={0.88}
            padAngle={0.8}
            cornerRadius={10}
            borderWidth={0}
            layers={[ 'arcs' ]}
          />
        </div>

        <div className='UsageGauge_pie UsageGauge_pie_pointer'>
          <ResponsivePie
            isInteractive={false}
            fit={false}
            colors={{ datum: 'data.color' }}
            data={pointerChartData}
            startAngle={pointerAngle - 4}
            endAngle={pointerAngle + 4}
            innerRadius={0.86}
            padAngle={0}
            cornerRadius={10}
            borderWidth={4}
            borderColor={pointerColor}
            layers={[ 'arcs' ]}
          />
        </div>

        <div className='UsageGauge_pie UsageGauge_pie_inner_semicircle' />

        <div className='UsageGauge_bar'>
          <ResponsiveBar
            isInteractive={false}
            enableGridX={false}
            enableGridY={false}
            enableLabel={false}
            data={dialBottomBarData}
            keys={[ 'low', 'med', 'high' ]}
            colors={[ LOW_COLOR, MEDIUM_COLOR, HIGH_COLOR ]}
            borderRadius={2}
            padding={0}
            innerPadding={2}
            layout='horizontal'
          />
          {usageBarNumbers}
        </div>
      </div>
    </div>
  );
}
