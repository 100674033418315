import Api from './Api';

function getViewportProperties() {
  return {
    'innerHeight': window.innerHeight,
    'innerWidth': window.innerWidth,
    // outer can tell us how much space is actually taken up by browser chrome or side panels.
    'outerHeight': window.outerHeight,
    'outerWidth': window.outerWidth,
    // windows ui scale, browser zoom level.
    // Large values may indicate people zooming in and small values zooming out.
    // zooming in will reduce the logical inner height and width.
    'devicePixelRatio': window.devicePixelRatio
  };
}

// Most of these should be valid always,
// however to defend against some browser quirk or esoteric usage causing issues (which we then alert() the user about, we are implement these paranoid checks.
function validate(x) {
  if (!x ||
    !x.innerHeight || x.innerHeight < 50 || x.innerHeight > 65000 ||
    !x.innerWidth || x.innerWidth < 50 || x.innerWidth > 65000 ||
    !x.outerHeight || x.outerHeight < 50 || x.outerHeight > 65000 ||
    !x.outerWidth || x.outerWidth < 50 || x.outerWidth > 65000 ||
    !x.devicePixelRatio
  ) {
    return null;
  }
  return x;
}

/** Null if throttling, passed in object otherwise. */
function throttle(x) {
  const sent = Date.parse(localStorage.getItem('viewport-metrics-sent'));
  const current = new Date().getTime();
  if (sent && (current - sent) < 3600_000) {
    return null;
  }
  else {
    localStorage.setItem('viewport-metrics-sent', new Date().toISOString());
    return x;
  }
}

/**
 * Will send statistics about the users viewport if we have not done so recently
 */
export function sendViewportPropertiesMetrics(origin) {
  const viewportProperties = throttle(validate(getViewportProperties()));
  if (viewportProperties !== null) {
    viewportProperties.origin = origin;
    Api.post('/viewport', viewportProperties);
  }
}
