import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';

MoneyLabel.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired
};

export default function MoneyLabel({ value, currency }) {
  return currencyFormatter.format(Math.floor(value), { code: currency, thousand: ',', precision: 0, format: '%s%v' });
}
