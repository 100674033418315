import classNames from 'classnames';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { ActionsButton } from '../../../components/ActionsButton';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../components/Dropdown';
import Page from '../../../components/Page';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import AdminUserForm from './AdminUserForm';
import BlockModal from './BlockModal';
import DisableTwoStepAuthenticationModal from './DisableTwoStepAuthenticationModal';
import { roleLabels, statusLabels } from './labels';
import Alerts from './Alerts';
import ResendModal from './ResendModal';
import ResetModal from './ResetModal';
import './styles.css';
import UnblockModal from './UnblockModal';

export default class AdminUserPage extends Component {

  state = {
    adminUsers: [],
    fetchingAdminUsers: true,

    twoStepAuthenticationEnabled: false,
    fetchingTwoStepAuthenticationEnabled: true,

    modal: {
      active: null,
      username: null,
      role: null
    },

    alert: {
      active: null,
      username: null
    }
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    Api.get('/admin-users', ({ list }) => {
      this.setState({ adminUsers: list, fetchingAdminUsers: false });
    });

    this.fetchTwoStepAuthentication();
  }

  fetchTwoStepAuthentication() {
    Api.get('/account', ({ twoStepAuthenticationEnabled }) => {
      this.setState({
        twoStepAuthenticationEnabled,
        fetchingTwoStepAuthenticationEnabled: false
      });
    });
  }

  isCurrentUser(username) {
    return username === User.getUsername();
  }

  onTwoStepAuthenticationButtonPress = () => {
    this.removeAlert();

    if (this.state.twoStepAuthenticationEnabled) {
      this.setState({ modal: { active: 'disableTwoStepAuthentication' } });
    }
    else {
      Api.put('/account/two-step-authentication-enabled', { twoStepAuthenticationEnabled: true }, () => {
        this.setState({ twoStepAuthenticationEnabled: true });
        this.showAlert('twoStepAuthentication');
      });
    }
  };

  onOpenModalButtonPress = ({ currentTarget }) => {
    this.removeAlert();
    this.setState({
      modal: {
        active: currentTarget.getAttribute('data-modal-id'),
        username: currentTarget.getAttribute('data-username'),
        role: currentTarget.getAttribute('data-role')
      }
    });
  };

  closeModal = () => {
    this.setState({ modal: {} });
  };

  closeModalAndRefetch = () => {
    this.closeModal();
    this.fetch();
  };

  showAlert(name, username) {
    this.setState({ alert: { active: name, username } });
  }

  removeAlert() {
    this.setState({ alert: {} });
  }

  onCreateSuccess = username => {
    this.closeModalAndRefetch();
    this.showAlert('create', username);
  };

  onEditSuccess = username => {
    this.closeModalAndRefetch();
    this.showAlert('edit', username);
  };

  onResetSuccess = username => {
    this.closeModal();
    this.showAlert('reset', username);
  };

  onResendSuccess = username => {
    this.closeModal();
    this.showAlert('resend', username);
  };

  onBlockSuccess = username => {
    this.closeModalAndRefetch();
    this.showAlert('block', username);
  };

  onUnblockSuccess = username => {
    this.closeModalAndRefetch();
    this.showAlert('unblock', username);
  };

  onDisableTwoStepAuthenticationSuccess = () => {
    this.closeModal();
    this.setState({ twoStepAuthenticationEnabled: false });
    this.showAlert('twoStepAuthentication');
  };

  sortList(list, currentUsername) {
    return _(list)
      .sortBy('lastLogin')
      .reverse()
      .sortBy(({ status }) => {
        switch (status) {
        case 'ACTIVE':
          return 0;
        case 'PENDING':
          return 1;
        case 'BLOCKED':
          return 2;
        default:
          return 99;
        }
      })
      .sortBy(({ username }) => username === currentUsername ? 0 : 1)
      .value();
  }

  renderActionsCell = user => {
    const { username, status, role } = user;

    if (User.canEdit() || this.isCurrentUser(username)) {
      return (
        <TableCell>
          <ActionsButton>
            {!this.isCurrentUser(username) && status !== 'BLOCKED' && (
              <DropdownMenuButton data-modal-id='edit' data-username={username} data-role={role} onPress={this.onOpenModalButtonPress}>Change role</DropdownMenuButton>
            )}
            {this.isCurrentUser(username) && (
              <DropdownMenuButton data-modal-id='reset' data-username={username} onPress={this.onOpenModalButtonPress}>Reset password</DropdownMenuButton>
            )}
            {!this.isCurrentUser(username) && status === 'PENDING' && (
              <DropdownMenuButton data-modal-id='resend' data-username={username} onPress={this.onOpenModalButtonPress}>Resend invite</DropdownMenuButton>
            )}
            {!this.isCurrentUser(username) && status === 'ACTIVE' && (
              <DropdownMenuButton data-modal-id='block' data-username={username} data-role={role} onPress={this.onOpenModalButtonPress}>Block</DropdownMenuButton>
            )}
            {!this.isCurrentUser(username) && status === 'BLOCKED' && (
              <DropdownMenuButton data-modal-id='unblock' data-username={username} data-role={role} onPress={this.onOpenModalButtonPress}>Unblock</DropdownMenuButton>
            )}
          </ActionsButton>
        </TableCell>
      );
    }

    return <TableCell />;
  };

  renderNoResults() {
    return (<div className='AdminUserPageTable_no-results-message'>
      <a className='AdminUserPageTable_no-results-image' />
      <div className='AdminUserPageTable_no-results-text'>
        <h2>No administrators added yet</h2>
        <p>Add administrators to start distributing your licenses</p>
      </div>
    </div>);
  }

  render() {
    return (
      <Page
        className='AdminUserPage'
        alert={<Alerts alert={this.state.alert} twoStepAuthenticationEnabled={this.state.twoStepAuthenticationEnabled} />}
        title='Administrators'
        buttons={User.canEdit() && (
          <Fragment>
            <Button type='primary' className='AdminUserPage_button' data-modal-id='create' onClick={this.onOpenModalButtonPress}>
              Invite administrator
            </Button>

            {!this.state.fetchingTwoStepAuthenticationEnabled && (
              <Button type='default' className='AdminUserPage_button' onClick={this.onTwoStepAuthenticationButtonPress}>
                {`${this.state.twoStepAuthenticationEnabled ? 'Disable' : 'Enable'} two-step authentication`}
              </Button>
            )}
          </Fragment>
        )}
      >
        {this.state.modal.active === 'create' && <AdminUserForm onSuccess={this.onCreateSuccess} onCancel={this.closeModal} />}
        {this.state.modal.active === 'edit' && (
          <AdminUserForm username={this.state.modal.username} role={this.state.modal.role} onSuccess={this.onEditSuccess} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'reset' && <ResetModal username={this.state.modal.username} onSuccess={this.onResetSuccess} onCancel={this.closeModal} />}
        {this.state.modal.active === 'resend' && <ResendModal username={this.state.modal.username} onSuccess={this.onResendSuccess} onCancel={this.closeModal} />}
        {this.state.modal.active === 'block' && (
          <BlockModal username={this.state.modal.username} role={this.state.modal.role} onSuccess={this.onBlockSuccess} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'unblock' && (
          <UnblockModal username={this.state.modal.username} role={this.state.modal.role} onSuccess={this.onUnblockSuccess} onCancel={this.closeModal} />
        )}
        {this.state.modal.active === 'disableTwoStepAuthentication' && (
          <DisableTwoStepAuthenticationModal onSuccess={this.onDisableTwoStepAuthenticationSuccess} onCancel={this.closeModal} />
        )}

        <Card>
          <Table
            header={
              <Fragment>
                <TableRow>
                  <th className='AdminUserPage_email-column'>Email</th>
                  <th className='AdminUserPage_column'>Role</th>
                  <th className='AdminUserPage_column'>Last active</th>
                  <th className='AdminUserPage_column'>Status</th>
                  <th />
                </TableRow>
              </Fragment>
            }
            body={this.state.adminUsers.length === 0 ?

              <TableRow className='AdminUserPage_no-results'>
                <TableCell colSpan='10'>
                  {this.state.fetchingAdminUsers ? 'Fetching data...' : this.renderNoResults()}
                </TableCell>
              </TableRow> :

              this.sortList(this.state.adminUsers, User.getUsername()).map((user, i) => {
                const { username, lastLogin, status, role } = user;
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <span className={classNames({ 'AdminUserPage_current-user': this.isCurrentUser(username) })}>{username}</span>
                      <span>{this.isCurrentUser(username) ? ' (You)' : ''}</span>
                    </TableCell>
                    <TableCell>{roleLabels[role]}</TableCell>
                    <TableCell><DateTimeLabel timestamp={lastLogin} displayAsFromNow /></TableCell>
                    <TableCell>{statusLabels[status]}</TableCell>
                    {this.renderActionsCell(user)}
                  </TableRow>
                );
              })}
          />
        </Card>
      </Page>
    );
  }
}
