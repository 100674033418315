import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DateTimeLabel from '../../../components/DateTimeLabel';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import { getFarthestOfflineUntil, hasOfflineProducts } from './selectors';

export default class BlockModal extends Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false
  };

  onOkButtonPress = () => {
    this.setState({ saving: true });

    if (this.props.user.status === 'INVITED') {
      Api.post(`/teams/${this.props.user.teamToken}/seat-users/invited/blocked`, { email: this.props.user.email }, this.onSuccess);
    }
    else {
      Api.put('/seat-users/blocked', { email: this.props.user.email, blocked: true }, this.onSuccess);
    }
  };

  onSuccess = () => {
    this.props.onSuccess(this.props.user.email);
  };

  isPendingBlock() {
    return hasOfflineProducts(this.props.user);
  }

  render() {
    return (
      <Modal
        title={this.isPendingBlock() ? 'Block offline user' : 'Block user'}
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onOkButtonPress}>Block</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        {this.isPendingBlock() ?

          <div>
            <p>
              <span>User <strong>{this.props.user.email}</strong> will be blocked. </span>
              <span>
                Any offline seats assigned to this user will be made available on <strong><DateTimeLabel timestamp={getFarthestOfflineUntil(this.props.user)} /></strong>.
              </span>
            </p>
            <p>Are you sure you want block this user?</p>
          </div> :

          <div>
            <p>
              You are about to block user <strong>{this.props.user.email}</strong>.
            </p>
            <p>
              Doing this will automatically release all seats assigned to the user. The user will not be able to activate new seats.
            </p>
            <p>Are you sure?</p>
          </div>}
      </Modal>
    );
  }
}
