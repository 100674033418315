import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '../Tooltip';
import './styles.css';

WarningTooltip.propTypes = {
  to: PropTypes.string,
  iconClassName: PropTypes.string
};

function renderIcon() {
  return <i className='fa fa-exclamation-triangle' aria-hidden='true' />;
}

export default function WarningTooltip({ to, iconClassName, ...otherProps }) {
  const iconProps = { className: classNames('WarningTooltip_icon', iconClassName) };

  return (
    <Tooltip offset={[ 0, -11 ]} {...otherProps}>
      {to ? <NavLink to={to} {...iconProps}>{renderIcon()}</NavLink> : <span {...iconProps}>{renderIcon()}</span>}
    </Tooltip>
  );
}
