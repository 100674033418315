import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { SsoNotification } from '../labels';
import './styles.css';

const DeleteModal = ({ sso, onSuccess, onCancel, onShowNotification }) => {

  const [ deleting, setDeleting ] = useState(false);

  const onOkButtonPress = () => {
    setDeleting(true);

    Api.del(`/sso/${sso.uuid}`, null, () => {
      onShowNotification(SsoNotification.DELETED);
      onSuccess();
    });
  };

  return (
    <Modal
      title={<div className='SsoDeleteModal_header'>Delete SSO</div>}
      buttons={
        <Fragment>
          <div>
            <ModalButton type='danger' className='secondary' onClick={onCancel}>Cancel</ModalButton>
            <ModalButton type='danger' disabled={deleting} className='SsoDeleteModal_button' onClick={onOkButtonPress}>Delete</ModalButton>
          </div>
        </Fragment>}
      onClose={onCancel}
    >
      <p>You are about to delete this SSO configuration.
        {sso.status === 'ACTIVE' && ' Removing this active configuration will enable password authentication.'}
      </p>
    </Modal>
  );
};

DeleteModal.prototype = {
  sso: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onShowNotification: PropTypes.func.isRequired
};

export default DeleteModal;
