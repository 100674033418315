import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';

export default class DisableTwoStepAuthenticationModal extends Component {

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false
  };

  onDisableTwoStepAuthenticationButtonPress = () => {
    this.setState({ saving: true });

    Api.put('/account/two-step-authentication-enabled', { twoStepAuthenticationEnabled: false }, () => {
      this.props.onSuccess(this.props.username);
    });
  };

  render() {
    return (
      <Modal
        title='Disable two-step authentication'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onDisableTwoStepAuthenticationButtonPress}>Disable</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          You are about to disable two-step authentication.
        </p>
        <p>Are you sure?</p>
      </Modal>
    );
  }
}
