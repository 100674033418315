import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import { formatAsHumanReadableList } from '../../selectors';

export default class BlockModal extends Component {

  static propTypes = {
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false,
    teams: []
  };

  componentDidMount() {
    if (this.props.role !== 'VIEW_ONLY_ADMIN') {
      Api.get(`/teams?${queryString.stringify({ team_lead_username: this.props.username })}`, ({ list }) => {
        this.setState({ teams: list });
      });
    }
  }

  onBlockButtonPress = () => {
    this.setState({ saving: true });

    Api.put('/admin-users/status', { username: this.props.username, status: 'BLOCKED' }, () => {
      this.props.onSuccess(this.props.username);
    });
  };

  render() {
    return (
      <Modal
        title={`Block ${this.props.role === 'TEAM_LEAD' ? 'team lead' : 'administrator'}`}
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onBlockButtonPress}>Block</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          You are about to block <strong>{this.props.username}</strong>.
        </p>
        {this.state.teams.length > 0 ?
          <p>
            <strong>{this.props.username}</strong> is the team lead of {formatAsHumanReadableList(_.map(this.state.teams, 'name'))}.
            <br />
            <span>{this.state.teams.length > 1 ? 'Those teams' : 'This team'}</span>
            <span> will have no team lead after blocking and <strong>{this.props.username}</strong></span>
            <span> will not be able to sign in to Rebel Licenses any longer.</span>
          </p> :

          <p>
            This user will not be able to sign in to Rebel Licenses any longer.
          </p>}

        <p>Are you sure?</p>
      </Modal>
    );
  }
}
