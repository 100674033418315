export const statusLabels = {
  ACTIVE: 'Active',
  PENDING: 'Invite pending',
  BLOCKED: 'Blocked'
};

export const roleLabels = {
  ACCOUNT_ADMIN: 'Administrator',
  VIEW_ONLY_ADMIN: 'View only',
  TEAM_LEAD: 'Team lead'
};
