import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import Api from '../../utils/Api';
import EventEmitter from '../../utils/EventEmitter';
import User from '../../utils/User';
import { NotificationUpdatedEventId } from '../AdminPages/NotificationPage';
import './styles.css';
import { AccountStatus, Notification } from '../../sharedTypes';
import NavButton from '../../components/NavButton';
import FeedbackFormModal from '../AdminPages/FeedbackFormModal';
import { HeaderDropdownButton } from '../../components/HeaderDropdown';
import { DropdownMenuButton, DropdownMenuItem } from '../../components/Dropdown';

const Header = ({ staticPage, hiddenMenuPages }: { staticPage: boolean, hiddenMenuPages: string[] }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [ accountStatus, setAccountStatus ] = useState<AccountStatus | null>(null);
  const [ notifications, setNotifications ] = useState({ activeCount: 0, newCount: 0 });
  const [ showFeedbackForm, setShowFeedbackForm ] = useState(false);

  const avatarBackgroundColor = useMemo(() => {
    const avatarBackgroundColors = [ 'redBg', 'blueBg', 'greenBg', 'orangeBg' ];
    const userNameHash = User.getUsername()?.split('') // Get random color unique to User as it otherwise changes on reload.
      .map(char => char.charCodeAt(0))            // Replace with something less hacky if/when better global state management is implemented
      .reduce((a, b) => a + b, 0);
    return avatarBackgroundColors[userNameHash ? userNameHash % avatarBackgroundColors.length : 1];
  }, []);

  const isHiddenMenuPage = () => {
    return hiddenMenuPages
      .map(path => matchPath({ path, end: true }, location.pathname))
      .find(_.identity);
  };

  const updateNotificationCount = (notifications: Notification[]) => {
    const activeList = notifications.filter(({ status }) => status !== 'ARCHIVED');
    const newCount = activeList.filter(({ status }) => status === 'NEW').length;

    setNotifications({ activeCount: activeList.length, newCount });
  };

  const fetch = () => {
    Api.get('/account', ({ status }: { status: AccountStatus }) => {
      setAccountStatus(status);
    });

    if (User.isAccountAdmin()) {
      Api.get('/notifications', ({ list }: { list: Notification[] }) => updateNotificationCount(list));
    }
  };

  const onLogoutButtonPress = () => {
    User.forget();

    if (!staticPage) {
      navigate('/login', { state: { redirectCause: 'logout' } });
    }
    else {
      navigate('/');
    }
  };

  const renderNotificationCount = () => {
    const { activeCount, newCount } = notifications;

    if (activeCount) {
      return <div className={classNames('Header_notification-count', { new: newCount })}>{activeCount}</div>;
    }

    return null;
  };

  useEffect(() => {
    if (User.isAuthenticated()) {
      fetch();
    }
  }, []);

  useEffect(() => {
    const authenticatedListenerToken = User.addAuthenticatedListener(fetch);
    return () => authenticatedListenerToken.remove();
  }, []);

  useEffect(() => {
    const notificationsListenerToken = EventEmitter.addListener(NotificationUpdatedEventId, updateNotificationCount);
    return () => notificationsListenerToken.remove();
  }, []);

  function renderAvatar() {
    return (<>
      <span className={classNames('Avatar', avatarBackgroundColor)}>{User.getInitials()}</span>
      <i className='icon icon-chevron' aria-hidden='true' />
    </>);
  }

  return (
    <div className='Header'>
      <Container>
        {showFeedbackForm && ( // TODO do we care about 'don't show again' option here?
          <FeedbackFormModal onSave={() => setShowFeedbackForm(false)} onCancel={() => setShowFeedbackForm(false)} origin='HEADER' />
        )}
        <div className='Header_menu_left-block'>
          <div className='Header_logo'>
            <a className='Header_logo-icon' href='/' />
          </div>
          {!staticPage && User.isAuthenticated() && accountStatus && !isHiddenMenuPage() && (
            <ul>
              <li>
                <NavLink to='/dashboard'>Dashboard</NavLink>
              </li>
              {!User.isTeamLead() && (
                <>
                  <li>
                    <NavLink to='/licenses'>Licenses</NavLink>
                  </li>
                  <li>
                    <NavLink to='/teams'>Teams</NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink to='/seat-users'>Users</NavLink>
              </li>
              {!User.isTeamLead() && (/* TODO: Add Admins under users CLS-790 Temporary show Admins/Whitelist for table changes with CLS-782*/
                <>
                  <li>
                    <NavLink to='/admin-users'>Administrators</NavLink>
                  </li>
                  <li>
                    <NavLink to='/analytics'>Analytics</NavLink>
                  </li>
                  {accountStatus !== 'EVALUATION_ONGOING' && accountStatus !== 'EVALUATION_ENDED' && (
                    <li>
                      <NavLink to='/email-domain-whitelist'>Email domain whitelist</NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink to='/sso-settings'>SSO</NavLink>
                  </li>
                </>
                /* {accountStatus !== 'EVALUATION_ONGOING' && accountStatus !== 'EVALUATION_ENDED' && (
                  <li> TODO: Add under Org settings Tab in Settings page CLS-786
                    <NavLink to='/email-domain-whitelist'>Email domain whitelist</NavLink>
                  </li>
                )} */
              )}
            </ul>
          )}
        </div>
        <div className='Header_menu_right-block'>
          <ul>
            {!staticPage && User.isAuthenticated() && accountStatus && !isHiddenMenuPage() && User.isAccountAdmin() && (
              <li>
                <NavButton to='/notifications' className='Header_Notifications headerIcon'>
                  <i className='icon icon-notification' aria-hidden='true' />{renderNotificationCount()}
                </NavButton>
              </li>
            )}
            <li>
              <HeaderDropdownButton className='Header_Help headerIcon' icon={<i className='icon icon-help' aria-hidden='true' />}>
                <DropdownMenuItem href='https://manuals.jrebel.com/zt-license-management/' target='_blank' rel='noopener noreferrer'>Help</DropdownMenuItem>
                <DropdownMenuItem href='mailto:support-rebel@perforce.com'>Contact Support</DropdownMenuItem>
                {!staticPage && User.isAuthenticated() && accountStatus && !isHiddenMenuPage() && User.canEdit() && (
                  <DropdownMenuButton onPress={() => setShowFeedbackForm(true)}>Send feedback</DropdownMenuButton>
                )}
                <DropdownMenuItem href='/tos.pdf' target='_blank' rel='noopener noreferrer' topSeparator={true}>Terms of use</DropdownMenuItem>
                <DropdownMenuItem href='https://jrebel.com/products/license-management/changelog' target='_blank' rel='noopener noreferrer'>Release notes</DropdownMenuItem>
              </HeaderDropdownButton>
            </li>
            {User.isAuthenticated() && (
              <li>
                <HeaderDropdownButton className='Header_Avatar headerIcon' icon={renderAvatar()}>
                  <DropdownMenuItem className='menuItemWithSubtext'><b>{User.getOrganization()}</b><h6>{User.getUsername()}</h6></DropdownMenuItem>
                  {!staticPage && accountStatus && !isHiddenMenuPage() && User.canEdit() && (
                    <DropdownMenuButton routeTo={{ path: '/user-settings' }} className='topSeparator'>Settings</DropdownMenuButton>
                  )}
                  <DropdownMenuButton onPress={onLogoutButtonPress}>Sign out</DropdownMenuButton>
                </HeaderDropdownButton>
              </li>
            )}
          </ul>
        </div>

      </Container>
    </div>
  );

};

Header.propTypes = {
  staticPage: PropTypes.bool.isRequired,
  hiddenMenuPages: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired
};

export default Header;
