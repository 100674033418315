import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Tooltip from '../../../../../components/Tooltip';

DataRecapTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired, /* string allows more easily specifying the precision and rounding */
    tooltip: PropTypes.string
  }))
};

export default function DataRecapTable({ values }) {
  const formattedValues = _.map(values, ({ label, value, icon, extra, tooltip }, index) => {
    if (tooltip) {
      return (
        <Tooltip text={tooltip} offset={0}>
          <div key={index} className='DataRecapTable_item'>
            <i className={`DataRecapTable_item_icon icon ${icon}`} aria-hidden='true' />
            <div className='DataRecapTable_item_label'>{label}<br />{extra}</div>
            <div className='DataRecapTable_item_value'>{value}</div>
          </div>
        </Tooltip>
      );
    }
    else {
      return (
        <div key={index} className='DataRecapTable_item'>
          <i className={`DataRecapTable_item_icon icon ${icon}`} aria-hidden='true' />
          <div className='DataRecapTable_item_label'>{label}<br />{extra}</div>
          <div className='DataRecapTable_item_value'>{value}</div>
        </div>
      );
    }
  });
  return (
    <div className='DataRecapTable'>
      {formattedValues}
    </div>
  );
}
