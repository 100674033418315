import React from 'react';
import Alert, { AlertEmphasis } from '../Alert';
import './styles.css';

export default function TwoStepAuthenticationSignInAlert() {
  return (
    <Alert type='info' className='TwoStepAuthenticationSignInAlert'>
      <div className='TwoStepAuthenticationSignInAlert-message'>
        <AlertEmphasis>Check your email for sign-in link.</AlertEmphasis>
      </div>
      <div className='TwoStepAuthenticationSignInAlert-message'>Your account has 2-step authentication turned on.</div>
      <div className='TwoStepAuthenticationSignInAlert-message'>
        <a
          href='https://manuals.jrebel.com/zt-license-management/administrators/#managing-two-step-authentication'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn more
        </a>
      </div>
      <div>
        <AlertEmphasis>You can close this tab now.</AlertEmphasis>
      </div>
    </Alert>
  );
}
