import moment from 'moment';
import { getJRebelHostedSeatCount } from '../selectors';

const HOURS_PER_WORKDAY = 5;
const WORKDAYS_PER_YEAR = 261;
const DAYS_PER_YEAR = 365;

// TODO: CLS-680, currently unused to maintain consistency with the PDF reports.
/**
 * An updated and simplified ROI calculation as specified in issue CLS-667
 * Calculates ROI based on historical usage data.
 *
 * see: CLS-667 Time Savings Calculations-1
 *
 * @param {moment.MomentInput} accountCreatedAt
 * @param {'total' | 'yearly' | 'monthly' | 'quarterly' | 'weekly'} period
 * @param {number} developerSalaryYearly
 * @param {number} jrebelPriceYearly
 * @param {[{licenseType: 'JREBEL_STANDALONE' | 'REBEL_SUITE', hosted: {total: number}}]} seatUsage
 * @param {{total:number, yearly:number, monthly:number, quarterly:number, weekly:number }} timeSavedSeconds
 * @param {{total:number, yearly:number, monthly:number, quarterly:number, weekly:number }} seatUserCount
 */
export function calculateRoiActualSavings(accountCreatedAt, period, developerSalaryYearly, jrebelPriceYearly, seatUsage, timeSavedSeconds, seatUserCount) {
  // Available licenses
  const jrebelHostedSeatCount = getJRebelHostedSeatCount(seatUsage);

  const seatUserCountInPeriod = seatUserCount[period];

  // total time in hours saved in said period
  // TODO: evaluate what to do if savings > 0 and users = 0. Currently clamping to 0 to match existing behavior.
  const timeSavedPeriodHours = seatUserCountInPeriod === 0 ? 0 : getTimeSavedInPeriodHours(timeSavedSeconds, period);

  const periodLengthDays = getPeriodLengthDays(accountCreatedAt, period);

  const timeSavedPerDeveloperDailyHours = Math.min(
    seatUserCountInPeriod === 0 ? 0 : timeSavedPeriodHours / (periodLengthDays * seatUserCountInPeriod * WORKDAYS_PER_YEAR / DAYS_PER_YEAR),
    HOURS_PER_WORKDAY - 0.01
  );
  const timeSavedPerDeveloperDailyMinutes = moment.duration(timeSavedPerDeveloperDailyHours, 'hours').asMinutes();
  const developerSalaryHourly = developerSalaryYearly / (WORKDAYS_PER_YEAR * HOURS_PER_WORKDAY);

  // const timeSavedYearlyHours = getTimeSavedInPeriodHours(timeSavedSeconds, 'yearly'); // actual savings in the past year
  const timeSavedYearlyHours = timeSavedPerDeveloperDailyHours * WORKDAYS_PER_YEAR * seatUserCountInPeriod; // estimate from current period usage.

  const jrebelCostYearly = jrebelPriceYearly * jrebelHostedSeatCount;
  const jrebelCostInPeriod = jrebelCostYearly * (periodLengthDays / DAYS_PER_YEAR);

  const redeployCostPreventedYearly = timeSavedYearlyHours * developerSalaryHourly;
  const redeployCostPreventedPeriod = timeSavedPeriodHours * developerSalaryHourly;
  const moneySavedYearly = redeployCostPreventedYearly - jrebelCostYearly;
  const moneySavedInPeriod = redeployCostPreventedPeriod - jrebelCostInPeriod;

  const jrebelPaidOffInDays = moneySavedYearly <= 0 ? Number.MAX_VALUE : (jrebelCostYearly * DAYS_PER_YEAR / redeployCostPreventedYearly);

  return {
    timeSavedPerDeveloperDailyMinutes: Math.round(timeSavedPerDeveloperDailyMinutes),
    timeSavedYearlyHours: Math.round(timeSavedYearlyHours),
    moneySavedInPeriod: Math.round(Math.max(0, moneySavedInPeriod)),
    moneySavedYearly: Math.round(Math.max(0, moneySavedYearly)),
    jrebelPaidOffInDays: Math.ceil(jrebelPaidOffInDays)
  };
}

export function calculateRoiOld(accountCreatedAt, period, developerSalaryYearly, jrebelPriceYearly, seatUsage, timeSavedSeconds, seatUserCount) {
  return calculateRoi({ accountCreatedAt, period }, { developerSalaryYearly, jrebelPriceYearly, seatUsage, timeSavedSeconds, seatUserCount });
}

// TODO: take named parameters in stead of big opaque bags of values.
export function calculateRoi(state, props) {
  const accountCreatedAt = state.accountCreatedAt;
  const period = state.period || props.period;
  const jrebelHostedSeatCount = getJRebelHostedSeatCount(props.seatUsage);
  const timeSavedHoursInPeriod = getTimeSavedInPeriodHours(props.timeSavedSeconds, period);
  const seatUserCountInPeriod = props.seatUserCount[period];
  const periodLengthDays = getPeriodLengthDays(accountCreatedAt, period);
  const timeSavedPerDeveloperDailyHours = Math.min(
    seatUserCountInPeriod === 0 ? 0 : timeSavedHoursInPeriod / (periodLengthDays * seatUserCountInPeriod * WORKDAYS_PER_YEAR / DAYS_PER_YEAR),
    HOURS_PER_WORKDAY - 0.01
  );
  const extraDevelopersNeeded = timeSavedPerDeveloperDailyHours / (HOURS_PER_WORKDAY - timeSavedPerDeveloperDailyHours);
  const potentialMoneyLossYearly = (props.developerSalaryYearly * seatUserCountInPeriod * timeSavedPerDeveloperDailyHours) / (HOURS_PER_WORKDAY - timeSavedPerDeveloperDailyHours);
  const jrebelCostYearly = props.jrebelPriceYearly * jrebelHostedSeatCount;

  return {
    timeSavedPerDeveloperDailyMinutes: Math.round(moment.duration(timeSavedPerDeveloperDailyHours, 'hours').asMinutes()),
    timeSavedYearlyHours: Math.round(timeSavedPerDeveloperDailyHours * WORKDAYS_PER_YEAR * seatUserCountInPeriod),
    moneySavedInPeriod: Math.round((seatUserCountInPeriod * extraDevelopersNeeded * props.developerSalaryYearly * periodLengthDays) / DAYS_PER_YEAR),
    moneySavedYearly: Math.max(0, Math.round(potentialMoneyLossYearly - jrebelCostYearly)),
    jrebelPaidOffInDays: extraDevelopersNeeded === 0 ? Number.MAX_VALUE :
      Math.ceil((props.jrebelPriceYearly * DAYS_PER_YEAR) / (extraDevelopersNeeded * props.developerSalaryYearly))
  };
}

export function getTimeSavedInPeriodHours(timeSavedSeconds, period) {
  return moment.duration(timeSavedSeconds[period], 'seconds').asHours();
}

export function getDaysUntilPeriodOld(timestamp, period) {
  return getPeriodLengthDays(timestamp, period) - getDaysOld(timestamp);
}

export function isAtLeastPeriodOld(timestamp, period) {
  return moment.utc().isAfter(moment.utc(timestamp).add({ days: getPeriodLengthDays(timestamp, period) }));
}

/**
 * Gets the period represented by the period string, falls back to age of timestamp.
 * @param timestamp Moment, the age of this timestamp will be used as the fallback if the period does not match.
 * @param period string, one of 'weekly', 'monthly','quarterly', 'yearly' or unused.
 * @returns {*|number|number}
 */
export function getPeriodLengthDays(timestamp, period) {
  switch (period) {
  case 'weekly':
    return 7;
  case 'monthly':
    return 30;
  case 'quarterly':
    return 90;
  case 'yearly':
    return 365;
  default:
    return getDaysOld(timestamp);
  }
}

function getDaysOld(timestamp) {
  return moment.utc().diff(timestamp, 'days');
}
