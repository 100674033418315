import PropTypes from 'prop-types';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import SelectField from '../../../../../components/SelectField';
import './styles.css';

const RouteParameterSelect = ({ label, routeParameter, values, labelMapper, defaultSelection, emphasizeSelected }) => {

  const [ searchParams, setSearchParams ] = useSearchParams();

  const onChange = ({ currentTarget: { name, value } }) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };

  return (
    <div className='RouteParameterSelect'>
      <SelectField // TODO update with react-select CLS-790
        label={label}
        className={{ 'RouteParameterSelect_is-selected': emphasizeSelected && searchParams.get(routeParameter) !== null }}
        name={routeParameter}
        onChange={onChange}
        value={searchParams.get(routeParameter) || defaultSelection}
      >
        {values.map(v => <option key={v} value={v}>{labelMapper(v)}</option>)}
      </SelectField>
    </div>
  );

};

RouteParameterSelect.propTypes = {
  label: PropTypes.string.isRequired,
  routeParameter: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  labelMapper: PropTypes.func.isRequired,
  defaultSelection: PropTypes.string.isRequired,
  emphasizeSelected: PropTypes.bool
};
export default RouteParameterSelect;
