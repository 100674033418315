import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
}

function describeSvgArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  return [
    'M', start.x, start.y,
    'A', radius, radius, 0, endAngle - startAngle <= 180 ? '0' : '1', 0, end.x, end.y
  ].join(' ');
}

UsageCircle.propTypes = {
  radius: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  usage: PropTypes.number.isRequired,
  total: PropTypes.number,
  lowUsageThreshold: PropTypes.number,
  numberClassName: PropTypes.string,
  withNumber: PropTypes.bool
};

export default function UsageCircle({ radius, strokeWidth, usage, total, lowUsageThreshold, numberClassName, withNumber = false }) {
  let hasTotal = true;

  if (total === undefined) {
    total = usage;
    hasTotal = false;
  }

  return (
    <svg
      className={classNames('UsageCircle', {
        'empty': usage === 0,
        'full': total > 0 && usage >= total,
        'zero-total': total === 0,
        'low-usage': lowUsageThreshold && usage / total < lowUsageThreshold
      })}
      width={radius * 2} height={radius * 2}
    >
      <circle
        className='UsageCircle_base'
        cx={radius} cy={radius}
        r={radius - strokeWidth / 2}
        strokeWidth={strokeWidth}
      />

      <path
        className='UsageCircle_arc'
        strokeWidth={strokeWidth}
        d={describeSvgArc(radius, radius, radius - strokeWidth / 2, 0, 360 * usage / Math.max(1, total))}
      />

      {withNumber && (
        <text
          className={classNames('UsageCircle_number', numberClassName)}
          x={radius} y={radius}
        >
          {usage + (hasTotal ? `/${total}` : '')}
        </text>
      )}
    </svg>
  );
}
