import Api from './Api';
import EventEmitter from './EventEmitter';

const AUTHENTICATED_EVENT = 'user.authenticated';
export const LOCAL_STORAGE_KEY = {
  username: 'user.username',
  role: 'user.role',
  organization: 'user.organization',
  onboardingStatus: 'user.onboardingStatus',
  managedExportEnabled: 'user.managedExportEnabled',
  npsAskForFeedback: 'user.npsAskForFeedback',
  ssoRedirect: 'ssoRedirect'
};

class User {

  static authenticate(params, authMethod, callback) {
    Api.auth(params, authMethod, ({ twoStep, redirectUrl, user }) => {
      if (twoStep) {
        callback('twoStep');
      }
      else if (authMethod === 'sso') {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
        else {
          callback('unavailable');
        }
      }
      else {
        this.remember(user);
        if (authMethod === 'support-login') {
          localStorage.setItem(LOCAL_STORAGE_KEY.npsAskForFeedback, 'false');
        }
        else {
          this.setNPSNotificationSetting();
        }
        callback('ok');
      }
    }, ({ errorCode }) => {
      if (errorCode) {
        callback(errorCode);
      }
      else {
        callback('failed');
      }
    });
  }

  static authenticateWithPassword(username, password, callback) {
    this.authenticate({ username, password }, 'password', callback);
  }

  static authenticateWithSso(username, callback) {
    this.authenticate({ username }, 'sso', callback);
  }

  static authenticateWithTwoStepAuthenticationToken(token, callback) {
    this.authenticate({ token }, 'two-step', callback);
  }

  static authenticateWithSupportLoginToken(token, callback) {
    this.authenticate({ token }, 'support-login', callback);
  }

  static authenticateWithSsoRedirect(callback) {
    if (this.isSsoRedirect()) {
      Api.get('/sso/user', ({ user }) => {
        this.remember(user);
        callback('ok');
        localStorage.setItem(LOCAL_STORAGE_KEY.ssoRedirect, 'false');
      });
    }
  }

  static remember({ username, role, organization, onboardingStatus, managedExportEnabled }) {
    localStorage.setItem(LOCAL_STORAGE_KEY.username, username);
    localStorage.setItem(LOCAL_STORAGE_KEY.role, role);
    localStorage.setItem(LOCAL_STORAGE_KEY.organization, organization);
    localStorage.setItem(LOCAL_STORAGE_KEY.onboardingStatus, onboardingStatus);
    localStorage.setItem(LOCAL_STORAGE_KEY.managedExportEnabled, managedExportEnabled);

    EventEmitter.emit(AUTHENTICATED_EVENT);
  }

  static forget() {
    localStorage.removeItem(LOCAL_STORAGE_KEY.username);
    localStorage.removeItem(LOCAL_STORAGE_KEY.role);
    localStorage.removeItem(LOCAL_STORAGE_KEY.organization);
    localStorage.removeItem(LOCAL_STORAGE_KEY.onboardingStatus);
    localStorage.removeItem(LOCAL_STORAGE_KEY.managedExportEnabled);
    localStorage.removeItem(LOCAL_STORAGE_KEY.npsAskForFeedback);
    Api.forgetAccessToken();
  }

  static setOnboardedIfNeeded() {
    if (User.isOnboarded()) {
      return;
    }

    Api.post('/admin-users/onboarding-status/done', null, () => {
      localStorage.setItem(LOCAL_STORAGE_KEY.onboardingStatus, 'DONE');
    });
  }

  static isOnboarded() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.onboardingStatus) === 'DONE';
  }

  static shouldBeOnboarded() {
    return this.canEdit() && !this.canExportManagedLicenses() && !this.isOnboarded();
  }

  static canExportManagedLicenses() {
    return this.isAccountAdmin() && localStorage.getItem(LOCAL_STORAGE_KEY.managedExportEnabled) === 'true';
  }

  // TODO: If more UI notifications are created, probably better to put in User payload as a list.
  static setNPSNotificationSetting() {
    Api.get('/admin-users/ui-notification-setting/NPS_ASK_FOR_FEEDBACK', ({ enabled }) => {
      localStorage.setItem(LOCAL_STORAGE_KEY.npsAskForFeedback, enabled);
    });
  }

  static isNPSNotificationEnabled() {
    return this.canEdit() && localStorage.getItem(LOCAL_STORAGE_KEY.npsAskForFeedback) === 'true';
  }

  static getUsername() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.username);
  }

  static getSsoRedirect() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.ssoRedirect);
  }

  static isTeamLead() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.role) === 'TEAM_LEAD';
  }

  static isAccountAdmin() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.role) === 'ACCOUNT_ADMIN';
  }

  static isAccountViewAdmin() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.role) === 'VIEW_ONLY_ADMIN';
  }

  static canEdit() {
    return this.isAccountAdmin() || this.isTeamLead();
  }

  static getOrganization() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.organization);
  }

  static isAuthenticated() {
    return this.getUsername() !== null;
  }

  static isSsoRedirect() {
    return this.getSsoRedirect() === 'true';
  }

  static getInitials() { // TODO do we want to add email front splitting for cases like John.Doe@... -> JD
    const userName = this.getUsername()?.split('@')[0];
    if (userName) {
      if (userName.length > 1) {
        // Some special characters are encoded to use more than one 'unit' of a string.
        // https://mathiasbynens.be/notes/javascript-unicode
        const userNameCharacters = Array.from(userName);
        return (userNameCharacters[0] + userNameCharacters[1]).toLocaleUpperCase();
      }
      else {
        return userName.toLocaleUpperCase();
      }
    }
  }

  static addAuthenticatedListener(callback) {
    return EventEmitter.addListener('user.authenticated', callback);
  }

  static addSessionEndedListener(callback) {
    return EventEmitter.addListener('api.unauthorized', callback);
  }
}

User.addSessionEndedListener(User.forget);

export default User;
