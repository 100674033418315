import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, ReactNode } from 'react';
import './styles.css';

export function AlertEmphasis({ children }: { children: ReactNode }) {
  return <span className='AlertEmphasis'>{children}</span>;
}

Alert.propTypes = {
  type: PropTypes.oneOf([ 'error', 'critical', 'warn', 'info', 'ok', 'instruction' ]).isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  onIconClick: PropTypes.func
};

const typeToIcon = {
  error: 'times-circle',
  critical: 'exclamation-triangle',
  warn: 'exclamation-triangle',
  info: 'info-circle',
  ok: 'check-circle'
};

interface AlertProps {
  type: string,
  icon?: string,
  className?: string
  children: ReactNode,
  onIconClick: MouseEventHandler<HTMLElement>,
}

// Expressing the precise invariants of the default value resolution for icon is quite subtle. Seems to require an unreasonable amount of type algebra.
// It looks like there is a way should we really desire it: https://github.com/microsoft/TypeScript/issues/18758
// @ts-ignore
export default function Alert({ type, className, children, icon = typeToIcon[type], onIconClick = () => {} }: AlertProps) {

  return (
    <div className={classNames('Alert', type, className)}>
      <i className={`Alert_icon fa fa-${icon}`} aria-hidden='true' onClick={onIconClick} />
      <div className='Alert_content'>{children}</div>
    </div>
  );
}
