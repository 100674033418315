import { getAvailableSeatCount } from '../selectors';

export function isFormValid(form, seatUsage, license) {
  return form.exportedTo && form.firstName && form.lastName && !getSeatCountErrorMessage(form.seatCount, seatUsage, license);
}

export function getSeatCountErrorMessage(seatCount, seatUsage, license) {
  if (seatCount > getAvailableSeatCount(seatUsage, license)) {
    return 'Cannot export more seats than available';
  }

  if (seatCount < 1) {
    return 'Please enter a number bigger than zero';
  }

  return null;
}

export function filterAdminsAllowedToExport(adminUsers) {
  return adminUsers
    .filter(({ status }) => status === 'ACTIVE')
    .filter(({ role }) => role === 'ACCOUNT_ADMIN');
}
