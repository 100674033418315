import PropTypes from 'prop-types';
import React from 'react';
import { licenseTypes, productTypeOfStandalone } from '../../enums/LicenseType';
import BrandLabel from '../BrandLabel';
import ProductTypeLabel from '../ProductTypeLabel';

const labels = {
  REBEL_SUITE: [ '', 'RebelSuite' ]
};

LicenseTypeLabel.propTypes = {
  id: PropTypes.oneOf(licenseTypes).isRequired,
  styled: PropTypes.bool
};

export default function LicenseTypeLabel({ id, ...otherProps }) {
  return productTypeOfStandalone(id) ? <ProductTypeLabel id={productTypeOfStandalone(id)} {...otherProps} /> : <BrandLabel id={id} labels={labels} {...otherProps} />;
}
