import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page';
import ProductTypeLabel from '../../components/ProductTypeLabel';
import Api from '../../utils/Api';
import TokenLinkResultMessage from './TokenLinkResultMessage';

const ProductInstallationConfirmationPage = () => {
  const { token } = useParams();
  const [ success, setSuccess ] = useState(null);
  const [ productType, setProductType ] = useState(null);
  const [ fetching, setFetching ] = useState(true);

  useEffect(() => {
    Api.post('/product-installations/confirmations', { token }, response => {
      setFetching(false);
      setSuccess(response.success);
      setProductType(response.productType);
    });
  }, []);

  const getSuccessMessageBody = () => {
    switch (productType) {
    case 'JREBEL':
      return <span>You can now start using <ProductTypeLabel id={productType} styled /></span>;
    case 'XREBEL':
      return <span>Restart your application server to start using <ProductTypeLabel id={productType} styled /></span>;
    default:
      return null;
    }
  };

  const renderMessage = () => {
    if (success) {
      return <TokenLinkResultMessage title='Email verification successful!' body={getSuccessMessageBody()} negative={false} />;
    }
    else {
      return (
        <TokenLinkResultMessage
          title='Email verification failed.'
          body='This activation verification link has expired. Please reactivate the product to receive a new verification email and try again.'
          negative
        />
      );
    }
  };

  return (
    <Page className='ProductInstallationConfirmationPage' title='Email verification' noHeading>
      {fetching ? <h1>Please wait...</h1> : renderMessage()}
    </Page>
  );
};

export default ProductInstallationConfirmationPage;
