import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './styles.css';

type ContainerProps = {
  children: ReactNode,
  className?: string,
}

export default function Container({ children, className, ...otherProps }: ContainerProps) {
  return (
    <div className={classNames('Container', className)} {...otherProps}>
      {children}
    </div>
  );
}
