import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';

Alerts.propTypes = {
  alert: PropTypes.shape({
    active: PropTypes.string,
    email: PropTypes.string
  })
};

function render(message) {
  return (
    <Alert type='ok'>
      {message}
    </Alert>
  );
}

export default function Alerts({ alert: { active, email } }) {
  switch (active) {
  case 'export':
    return render(
      <span>
        <AlertEmphasis>{email}</AlertEmphasis> will receive an email with a bulk license file attached which can be imported to Rebel Licenses On-Premise (License Server).
      </span>);
  default:
    return <Fragment />;
  }
}
