import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import { licenseTypesSupportingProduct, supportedProductsTypes } from '../../../../enums/LicenseType';
import { isInt } from '../../selectors';

function isNotFilled(value) {
  return value === '' || value === undefined;
}

function isAtLeastOneProductEnabled(products) {
  return products.some(({ enabled }) => enabled);
}

function isLimitBiggerThanZero({ enabled, limit }) {
  return !enabled || isNotFilled(limit) || (isInt(limit) && parseInt(limit, 10) > 0);
}

function isLimitBiggerOrEqualToUsage({ limit, usage, enabled }) {
  if (!enabled && usage > 0) {
    return false;
  }

  return usage === 0 || isNotFilled(limit) || (isInt(limit) && parseInt(limit, 10) >= usage);
}

export function getSeatLimitValidationMessage(products, product) {
  if (!isLimitBiggerThanZero(product)) {
    return 'Please enter a number bigger than zero.';
  }

  if (!isLimitBiggerOrEqualToUsage(product)) {
    const { productType, usage, enabled } = product;

    if (enabled) {
      return `Limit cannot be lower than ${usage}. There are currently ${usage} active ${ProductTypeLabel.asString(productType)} users.`;
    }
  }

  return null;
}

export function getProductEnabledValidationMessage(products, product) {
  if (product.lastTouched && !isAtLeastOneProductEnabled(products)) {
    return 'At least one product has to be enabled.';
  }

  if (!isLimitBiggerOrEqualToUsage(product)) {
    const { productType, usage, enabled } = product;

    if (!enabled) {
      return `Cannot disable ${ProductTypeLabel.asString(productType)}, ${usage} active ${usage === 1 ? 'user' : 'users'}.`;
    }
  }

  return null;
}

export function getSeatsLeftForProduct(seatUsage, product) {
  return licenseTypesSupportingProduct(product)
    .map(licenseType => getAvailableSeatCountForLicenseType(seatUsage, licenseType))
    .reduce((a, b) => a + b);
}

export function hasRebelSuiteSeatsForProduct(seatUsage, product) {
  if (supportedProductsTypes.REBEL_SUITE.indexOf(product) >= 0) {
    return getAvailableSeatCountForLicenseType(seatUsage, 'REBEL_SUITE') > 0;
  }
  return false;
}

export function getAvailableSeatCountForLicenseType(seatUsage, license) {
  const usage = seatUsage.find(({ licenseType }) => licenseType === license);
  return usage ? usage.total - usage.reserved : 0;
}
