export const SsoNotification = {
  SAVE_DRAFT: 'SSO saved as draft',
  ACTIVATED: 'SSO successfully enabled. Email authentication has been disabled.',
  DEACTIVATED: 'SSO successfully disabled. Email authentication has been enabled.',
  VERIFYING: 'SSO verification initiated in a new tab.',
  VEFRIFIED: 'SSO successfully verified.',
  DELETED: 'SSO successfully deleted.',

  FAILED_TO_ACTIVATE: 'Failed to enable SSO.',
  CLIENT_NAME_NOT_UNIQUE: 'Client name is already in use by some other SSO configuration.',
  SSO_DUPLICATE_ACTIVATION: 'Can\'t enable multiple SSOs simultaneously.',
  UNKNOWN_ERROR: 'Unknown error.'
};

export const translateErrorCode = code => {
  switch (code) {
  case 'SSO_DUPLICATE_ACTIVATION':
    return SsoNotification.SSO_DUPLICATE_ACTIVATION;
  case 'SSO_ACTIVATION_FAILED':
    return SsoNotification.FAILED_TO_ACTIVATE;
  case 'SSO_CLIENT_NAME_NOT_UNIQUE':
    return SsoNotification.CLIENT_NAME_NOT_UNIQUE;
  default:
    return SsoNotification.UNKNOWN_ERROR;
  }
};

export const translateStatusCode = status => {
  switch (status) {
  case 'DRAFT':
    return 'Draft';
  case 'VERIFYING':
    return 'Verification Pending';
  case 'VERIFIED':
    return 'Verified';
  case 'ACTIVE':
    return 'Active';
  default:
    return status;
  }
};
