import _ from 'lodash';
import moment from 'moment';
import { hasJRebelEnabled } from '../../selectors';

export function shouldConfigureTeamRedeployTime(teams) {
  return teams.filter(team => hasJRebelEnabled(team.products)).some(({ redeployTimeSeconds }) => !redeployTimeSeconds);
}

export function hasNoSeatsReserved(seatUsage) {
  return seatUsage.every(({ reserved }) => reserved === 0);
}

export function getMaxEndDate(licenses) {
  return _(licenses).map(({ endDate }) => endDate).max();
}

export function getMinStartDateOfFutureLicenses(licenses) {
  return _(licenses)
    .filter(({ startDate }) => moment.utc().isBefore(startDate))
    .map(({ startDate }) => startDate)
    .min();
}

export function hasFutureLicenses(licenses) {
  return getMinStartDateOfFutureLicenses(licenses) !== undefined;
}
