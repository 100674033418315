import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { getTeamActiveSeatUsers } from '../../selectors';
import './styles.css';
import Card, { CardBody } from '../../../../components/Card';

export default class DeleteModal extends Component {

  static propTypes = {
    team: PropTypes.shape({
      token: PropTypes.string.isRequired,
      name: PropTypes.shape({
        value: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    seatUsers: [],
    saving: false
  };

  componentDidMount() {
    Api.get('/seat-users', ({ list }) => {
      this.setState({ seatUsers: list });
    });
  }

  getTeamActiveSeatUsers() {
    return getTeamActiveSeatUsers(this.state.seatUsers, this.props.team.name.value);
  }

  onOkButtonPress = () => {
    this.setState({ saving: true });

    Api.del('/teams', { token: this.props.team.token }, this.props.onSuccess);
  };

  render() {
    return (
      <Modal
        title='Delete team'
        size='l'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onOkButtonPress}>Delete</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>You are about to delete the team <strong>{this.props.team.name.value}</strong>.</p>
        {this.getTeamActiveSeatUsers().length > 0 ?

          <div>
            <p>This action cannot be reversed. Are you sure you want to continue?</p>
            <p>
              <span>Deleting this team will automatically revoke access for the following </span>
              <span>{this.getTeamActiveSeatUsers().length} {this.getTeamActiveSeatUsers().length === 1 ? 'user' : 'users'}:</span>
            </p>
            <Card>
              <CardBody className='DeleteModal_seat-user-list pre-scrollable'>
                {this.getTeamActiveSeatUsers().map(({ email }) => <div key={email}>{email}</div>)}
              </CardBody>
            </Card>
          </div> :

          <p>Are you sure you want to delete this team?</p>
        }
      </Modal>
    );
  }
}
