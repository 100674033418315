import React from 'react';
import Container from '../../components/Container';
import './styles.css';

export default function Footer() {
  return (
    <div className='Footer'>
      <Container>
        <div className='Footer_text-box'>
          <h6>JREBEL BY <a href='https://perforce.com' target='_blank' rel='noopener noreferrer'>PERFORCE</a> © 2024 PERFORCE SOFTWARE, INC.</h6>
        </div>
      </Container>
    </div>
  );
}
