import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';
import React from 'react';

UnitLabel.propTypes = {
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  valueClass: PropTypes.string
};

export default function UnitLabel({ value, unit, valueClass }) {
  const flooredValue = Math.floor(value);
  return (
    <span>
      <span className={valueClass}>{currencyFormatter.format(flooredValue, { thousand: ',', precision: 0, format: '%v' })}</span>
      <span> {unit}{flooredValue === 1 ? '' : 's'}</span>
    </span>
  );
}
