import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Dropdown } from '../Dropdown';

HeaderDropdownButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node.isRequired
};

interface HeaderDropdownButtonProps {
  children: ReactNode,
  className?: string,
  icon: ReactNode
}

export function HeaderDropdownButton({ children, className, icon }: HeaderDropdownButtonProps) {
  const classNameForComponent = classNames('HeaderDropdownButton', className);

  return (
    <Dropdown className={classNameForComponent} icon={icon} popupOffset={[ 0, 22 ]} popupPosition='fixed'>
      {children}
    </Dropdown>
  );
}
