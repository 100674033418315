import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withRouter } from '../../../../utils/withRouter';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import RedeployMetricLabel from '../../../../components/RedeployMetricLabel';
import RouterComponent from '../../../../components/RouterComponent';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import WarningTooltip from '../../../../components/WarningTooltip';
import User from '../../../../utils/User';
import { formatDuration } from '../../selectors';
import { getClosestLastSeen, getOfflineProductCount, hasOfflineProducts } from '../selectors';
import RouteParameterSelect from './RouteParameterSelect';
import './styles.css';

const timeSavedPeriods = {
  weekly: 'Last 7 days',
  monthly: 'Last 30 days',
  yearly: 'Last 365 days',
  total: 'All time'
};

const defaultTimeSavedPeriod = 'monthly';

export default withRouter(class SeatUserTable extends RouterComponent {

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    filterValues: PropTypes.shape({
      teamName: PropTypes.array.isRequired,
      productType: PropTypes.array.isRequired
    }).isRequired,
    domainBlacklistedText: PropTypes.string.isRequired,
    renderActionsCell: PropTypes.func.isRequired
  };

  renderTeamHeaderCell() {
    return (
      <th className='SeatUserPageTable_teams-column'>
        {this.props.filterValues.teamName.length <= 1 && <div>Team</div>}
        <div>
          {this.props.filterValues.teamName.length > 1 && (
            <RouteParameterSelect
              label='Team'
              routeParameter='teamName'
              values={[ '', ...this.props.filterValues.teamName ]}
              labelMapper={value => value || 'All teams'}
              defaultSelection=''
              emphasizeSelected
            />
          )}
        </div>
      </th>
    );
  }

  renderProductHeaderCell() {
    return (
      <th className='SeatUserPageTable_products-column'>
        {this.props.filterValues.productType.length <= 1 && <div>Product</div>}
        <div>
          {this.props.filterValues.productType.length > 1 && (
            <RouteParameterSelect
              label='Product'
              routeParameter='productType'
              values={[ '', ...this.props.filterValues.productType ]}
              labelMapper={value => value ? ProductTypeLabel.asString(value) : 'All products'}
              defaultSelection=''
              emphasizeSelected
            />
          )}
        </div>
      </th>
    );
  }

  renderTimeSavedHeaderCell() {
    return (
      <th className='SeatUserPageTable_time-saved-column'>
        <div>
          <RouteParameterSelect
            label='Time saved'
            routeParameter='timeSavedPeriod'
            values={Object.keys(timeSavedPeriods)}
            labelMapper={value => timeSavedPeriods[value]}
            defaultSelection={defaultTimeSavedPeriod}
          />
        </div>
      </th>
    );
  }

  renderUserCell({ email, domainBlacklisted }) {
    return (
      <TableCell className='SeatUserPageTable_user-label'>
        {User.canEdit() && domainBlacklisted && (
          <WarningTooltip iconClassName='SeatUserPageTable_domain-blacklisted-warning' size='l' text={this.props.domainBlacklistedText} />
        )}
        <span>{email}</span>
      </TableCell>
    );
  }

  renderTeamCell(user) {
    return <TableCell>{_(user.seats).map(({ teamName }) => teamName).uniq().map((teamName, i) => <div key={i}>{teamName}</div>).value()}</TableCell>;
  }

  renderProductCell(user) {
    return (
      <TableCell>
        {user.seats.map(({ productType, offlineUntil }, i) => {
          return (
            <Fragment key={i}>
              <div>
                {productType && (
                  <ProductTypeLabel id={productType} />
                )}
                {offlineUntil && (
                  <span className='SeatUserPageTable_products-column_offline-until'>
                    Offline until <DateTimeLabel timestamp={offlineUntil} />
                  </span>
                )}
              </div>
            </Fragment>
          );
        })}
      </TableCell>
    );
  }

  renderTimeSavedCell(user) {
    if (user.seats.some(({ productType }) => productType === 'JREBEL')) {
      const timeSaved = formatDuration(user.timeSavedSeconds[this.getRouteParameter('timeSavedPeriod') || defaultTimeSavedPeriod]);
      return <TableCell><RedeployMetricLabel value={timeSaved.value} text={timeSaved.unit} /></TableCell>;
    }

    return <TableCell />;
  }

  getRedeployTimeText(user) {
    switch (user.redeployTimeType) {
    case 'CONFIGURED':
      return 'user set';
    case 'DEFAULT':
      return 'default';
    case 'TEAM_DEFAULT':
      return 'team default';
    default:
      return '';
    }
  }

  renderRedeployTimeCell(user) {
    if (user.seats.some(({ productType }) => productType === 'JREBEL')) {
      return <TableCell><RedeployMetricLabel value={user.redeployTimeSeconds || ''} text={this.getRedeployTimeText(user)} /></TableCell>;
    }

    return <TableCell />;
  }

  getLastActives(user) {
    if (getOfflineProductCount(user) === 0) {
      return [ getClosestLastSeen(user) ];
    }

    if (getOfflineProductCount(user) === user.seats.length) {
      return [];
    }

    return user.seats
      .map(({ offline, lastSeen }) => offline ? null : lastSeen);
  }

  renderLastActiveCell(user) {
    return (
      <TableCell>
        {this.getLastActives(user).map((lastActive, i) => {
          return <div key={i}>{lastActive ? <DateTimeLabel timestamp={lastActive} withJustNow /> : <br />}</div>;
        })}
      </TableCell>
    );
  }

  renderHead() {
    switch (this.getRoutingKey()) {
    case 'team':
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          <th className='SeatUserPageTable_column'>Status</th>
          {this.renderProductHeaderCell()}
          <th className='SeatUserPageTable_last-active-column'>Last active</th>
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    case 'offline':
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          {this.renderTeamHeaderCell()}
          {this.renderProductHeaderCell()}
          {this.renderTimeSavedHeaderCell()}
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    case 'invited':
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          {this.renderTeamHeaderCell()}
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    case 'blocked':
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          {this.renderTeamHeaderCell()}
          {this.renderProductHeaderCell()}
          <th className='SeatUserPageTable_blocked-column'>Blocked since</th>
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    case 'forgotten':
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          {this.renderTeamHeaderCell()}
          {this.renderProductHeaderCell()}
          <th className='SeatUserPageTable_date-column'>Forgotten on</th>
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    case 'individual':
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          {this.renderProductHeaderCell()}
          <th className='SeatUserPageTable_date-column'>Valid from</th>
          <th className='SeatUserPageTable_date-column'>Valid until</th>
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    default:
      return (
        <TableRow>
          <th className='SeatUserPageTable_user-column'>User</th>
          {this.renderTeamHeaderCell()}
          {this.renderProductHeaderCell()}
          <th className='SeatUserPageTable_date-column'>Last active</th>
          {this.renderTimeSavedHeaderCell()}
          <th className='SeatUserPageTable_redeploy-time-column'>Redeploy time (s)</th>
          <th className='SeatUserPageTable_actions-column' />
        </TableRow>
      );
    }
  }

  getStatusLabel(user) {
    if (user.status === 'INVITED') {
      return 'Invited';
    }

    if (hasOfflineProducts(user)) {
      return 'Offline';
    }

    return 'Active';
  }

  renderRow = user => {
    const { email } = user;

    switch (this.getRoutingKey()) {
    case 'team':
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          <TableCell>{this.getStatusLabel(user)}</TableCell>
          {this.renderProductCell(user)}
          {this.renderLastActiveCell(user)}
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    case 'offline':
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          {this.renderTeamCell(user)}
          {this.renderProductCell(user)}
          {this.renderTimeSavedCell(user)}
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    case 'invited':
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          {this.renderTeamCell(user)}
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    case 'blocked':
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          {this.renderTeamCell(user)}
          {this.renderProductCell(user)}
          <TableCell>
            <DateTimeLabel timestamp={user.statusChangedAt} />
            <div className='SeatUserPageTable_blocked-by-label'>
              by {user.statusChangedBy}
            </div>
          </TableCell>
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    case 'forgotten':
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          {this.renderTeamCell(user)}
          {this.renderProductCell(user)}
          <TableCell>
            <DateTimeLabel timestamp={user.statusChangedAt} />
          </TableCell>
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    case 'individual':
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          {this.renderProductCell(user)}
          <TableCell>{user.seats.map(({ startDate }, i) => <div key={i}><DateTimeLabel timestamp={startDate} /></div>)}</TableCell>
          <TableCell>{user.seats.map(({ endDate }, i) => <div key={i}><DateTimeLabel timestamp={endDate} /></div>)}</TableCell>
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    default:
      return (
        <TableRow key={email}>
          {this.renderUserCell(user)}
          {this.renderTeamCell(user)}
          {this.renderProductCell(user)}
          {this.renderLastActiveCell(user)}
          {this.renderTimeSavedCell(user)}
          {this.renderRedeployTimeCell(user)}
          {this.props.renderActionsCell(user)}
        </TableRow>
      );
    }
  };

  renderNoResults() {
    return (<div className='SeatUserPageTable_no-results-message'>
      <a className='SeatUserPageTable_no-results-image' />
      <div className='SeatUserPageTable_no-results-text'>
        <h2>No users added yet</h2>
        <p>Add users to start distributing your licenses</p>
      </div>
    </div>);
  }

  render() {
    return (
      <Table
        className='SeatUserPageTable'
        header={this.renderHead()}
        body={this.props.users.length === 0 ?

          <TableRow className='SeatUserPageTable_no-results'>
            <TableCell colSpan='10'>
              {this.props.fetching ? 'Fetching data...' : this.renderNoResults()}
            </TableCell>
          </TableRow> :

          this.props.users.map(this.renderRow)}
      />
    );
  }
});
