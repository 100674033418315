import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from '../../../components/Card';
import InfoTooltip from '../../../components/InfoTooltip';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import Table, { TableCell, TableRow } from '../../../components/Table';
import ValidationTooltip from '../../../components/ValidationTooltip';
import Api from '../../../utils/Api';
import EventEmitter from '../../../utils/EventEmitter';
import { getRedeployTimeValidationMessage } from '../selectors';
import './styles.css';

export default class TeamRedeployTimeModal extends Component {

  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    origin: PropTypes.string.isRequired
  };

  static savedEventId = 'TeamRedeployTimeModal.saved';

  inputElements = {};

  state = {
    teams: [],
    seatUsers: [],
    saving: false,
    fetchingTeams: true,
    fetchingSeatUsers: true
  };

  componentDidMount() {
    Api.get('/teams?with_products=true', ({ list }) => {
      this.setState({ teams: list, fetchingTeams: false });
    });

    Api.get('/seat-users', ({ list }) => {
      this.setState({ seatUsers: list, fetchingSeatUsers: false });
    });

    Api.post('/teams/events/open/team-redeploy-time-modal', { origin: this.props.origin });
  }

  getActiveUsersWithJRebelInTeam(users, teamName) {
    return users
      .filter(({ status }) => status === 'ACTIVE')
      .filter(({ seatReservations }) => seatReservations.some(seatReservation => seatReservation.productType === 'JREBEL' && seatReservation.teamName === teamName));
  }

  getUsersWithCustomRedeployTime(users, teamName) {
    return this.getActiveUsersWithJRebelInTeam(users, teamName)
      .filter(({ redeployTimeType }) => redeployTimeType === 'CONFIGURED');
  }

  getAverageCustomRedeployTime(users, teamName) {
    const times = this.getUsersWithCustomRedeployTime(users, teamName)
      .map(({ redeployTimeSeconds }) => redeployTimeSeconds);
    if (times.length > 5) {
      return times
        .sort((a, b) => a - b)
        .reduce((totalTime, time, i, array) => totalTime + (i > 0 && i + 1 < array.length ? time : 0), 0) / (times.length - 2);
    }
    else {
      return times.reduce((totalTime, time) => totalTime + time, 0) / times.length;
    }
  }

  onTimeChange = ({ currentTarget }) => {
    const redeployTimeSeconds = currentTarget.value;

    this.setState(state => {
      const changedToken = currentTarget.getAttribute('data-token');
      const team = state.teams.find(({ token }) => token === changedToken);

      team.changed = true;
      team.redeployTimeSeconds = redeployTimeSeconds;
      return state;
    });
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.setState({ saving: true });

    const list = this.state.teams
      .filter(({ changed }) => changed)
      .map(team => ({ token: team.token, redeployTimeSeconds: team.redeployTimeSeconds }));

    if (list.length > 0) {
      Api.put('/teams/redeploy-time-seconds', { origin: this.props.origin, list }, () => {
        EventEmitter.emit(TeamRedeployTimeModal.savedEventId);
        this.props.onSave();
      });
    }
    else {
      this.props.onCancel();
    }
  };

  onInputRef = element => {
    if (element) {
      this.inputElements[element.getAttribute('data-token')] = element;
    }
  };

  render() {
    return (
      <div className='TeamRedeployTimeModal'>
        <Modal
          title='Change default redeploy time for teams'
          className='TeamRedeployTimeModal_modal'
          buttons={<div>
            <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
            <ModalButton
              type='primary'
              disabled={this.state.saving || !this.state.teams.every(({ redeployTimeSeconds }) => !getRedeployTimeValidationMessage(redeployTimeSeconds))}
              submit
            >
              Save changes
            </ModalButton>
          </div>}
          onSubmit={this.onFormSubmit}
          onClose={this.props.onCancel}
        >
          <div className='TeamRedeployTimeModal_modal_description'>
            Here you can override the default redeploy time that is used to calculate time savings. Redeploy times configured by individual developers will not be overridden.
          </div>
          <Card className='TeamRedeployTimeModal_table-panel'>
            <Table
              className='TeamRedeployTimeModal_table'
              header={
                <TableRow>
                  <th className='TeamRedeployTimeModal_table_team-name'>Team</th>
                  <th className='TeamRedeployTimeModal_table_team-lead'>Team lead</th>
                  <th className='TeamRedeployTimeModal_table_users'>
                    Affected users
                    <InfoTooltip size='l' text='Users whose JRebel redeploy time is currently left at the default value.' />
                  </th>
                  <th className='TeamRedeployTimeModal_table_redeploy-time'>
                    Avg. redeploy time
                    <InfoTooltip size='l' text='The average redeploy time of users who configured JRebel redeploy time (not using default).' />
                  </th>
                  <th className='TeamRedeployTimeModal_table_redeploy-time'>Default redeploy time</th>
                </TableRow>
              }
              body={this.state.teams.length === 0 ?

                <TableRow className='TeamRedeployTimeModal_no-results'>
                  <TableCell colSpan='10'>
                    {this.state.fetchingTeams && this.state.fetchingSeatUsers ? 'Fetching data...' : 'No teams'}
                  </TableCell>
                </TableRow> :

                _(this.state.teams)
                  .sortBy('name')
                  .value()
                  .filter(({ products }) => products.some(({ productType, enabled }) => enabled && productType === 'JREBEL'))
                  .map(({ token, name, teamLead, redeployTimeSeconds, products }) => {
                    return (
                      <TableRow key={token}>
                        <TableCell className='TeamRedeployTimeModal_table_team-name'>{name}</TableCell>
                        <TableCell className='TeamRedeployTimeModal_table_team-lead'>{teamLead}</TableCell>
                        <TableCell>
                          <span>
                            {this.getActiveUsersWithJRebelInTeam(this.state.seatUsers, name).filter(({ redeployTimeType }) => redeployTimeType !== 'CONFIGURED').length}
                          </span>
                          <span> / </span>
                          <span>
                            {products.find(({ productType }) => productType === 'JREBEL').usage}
                          </span>
                        </TableCell>
                        <TableCell>
                          {this.getUsersWithCustomRedeployTime(this.state.seatUsers, name).length > 0 ?

                            this.getAverageCustomRedeployTime(this.state.seatUsers, name).toFixed(0) :

                            ''}
                        </TableCell>
                        <TableCell className='TeamRedeployTimeModal_table_redeploy-time'>
                          <InputField
                            type='text'
                            name='redeployTime'
                            idKey={name}
                            value={redeployTimeSeconds || ''}
                            data-token={token}
                            ref={this.onInputRef}
                            hasError={getRedeployTimeValidationMessage(redeployTimeSeconds)}
                            onChange={this.onTimeChange}
                          />
                          seconds
                          <ValidationTooltip target={this.inputElements[token]} text={getRedeployTimeValidationMessage(redeployTimeSeconds)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            />
          </Card>
        </Modal>
      </div>
    );
  }
}
