import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import './styles.css';
import { isBlacklisted } from '../../selectors';
import Card, { CardBody } from '../../../../components/Card';

export default class DeleteWhitelistedDomainModal extends Component {

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    remainingDomains: PropTypes.arrayOf(
      PropTypes.shape({
        domain: PropTypes.string.isRequired
      })
    ).isRequired
  };

  state = {
    productInstallations: [],
    fetching: true,
    saving: false
  };

  componentDidMount() {
    Api.get('/product-installations', ({ list }) => {
      this.setState({ productInstallations: list, fetching: false });
    });
  }

  getSeatUserEmailsMatchingDeletedDomainWithUnconfirmedProductInstallations() {
    return _(this.state.productInstallations)
      .filter(({ confirmed }) => !confirmed)
      .map(({ seatUserEmail }) => seatUserEmail)
      .filter(email => !isBlacklisted(email, this.props.domain))
      .filter(email => this.props.remainingDomains.every(({ domain }) => isBlacklisted(email, domain)))
      .uniq()
      .sort()
      .value();
  }

  onDeleteButtonPress = () => {
    this.setState({ saving: true });

    this.props.onSuccess();
  };

  render() {
    return !this.state.fetching && (
      <Modal
        title='Delete domain from whitelist'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='danger' disabled={this.state.saving} onClick={this.onDeleteButtonPress}>Delete domain</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          Activation with <strong>{this.props.domain}</strong> email addresses will not be allowed.<br />
          Users who have already activated will not be affected, use{' '}
          <a href='https://manuals.jrebel.com/zt-license-management/users/#blocking-users' rel='noopener noreferrer' target='_blank'>Blocking</a>{' '}
          if needed.
        </p>
        {this.getSeatUserEmailsMatchingDeletedDomainWithUnconfirmedProductInstallations().length > 0 && (
          <Fragment>
            <p>Following users with pending invites will not be able to activate:</p>
            <Card>
              <CardBody className='DeleteWhitelistedDomainModal_product-installation-list pre-scrollable'>
                {this.getSeatUserEmailsMatchingDeletedDomainWithUnconfirmedProductInstallations().map(email => <div key={email}>{email}</div>)}
              </CardBody>
            </Card>
          </Fragment>
        )}
        <p className='DeleteWhitelistedDomainModal_confirmation-text'>Are you sure?</p>
      </Modal>
    );
  }
}
