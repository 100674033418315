import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../Button';
import Api from '../../utils/Api';
import CopyTokenUrlButton from '../CopyTokenUrlButton';
import './styles.css';

export default class InviteOrCopyButton extends Component {

  static propTypes = {
    token: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onInviteButtonPress: PropTypes.func.isRequired,
    className: PropTypes.string,
    hideCopyButton: PropTypes.bool
  };

  state = {
    showCopyFeedback: false
  };

  onShowCopyFeedback = () => {
    this.setState({ showCopyFeedback: true });
  };

  onCancelCopyFeedback = () => {
    this.setState({ showCopyFeedback: false });
  };

  onInviteButtonPress = () => {
    this.props.onInviteButtonPress(this.props.token);

    Api.post(`/teams/${this.props.token}/events/press/users-invite`, { eventUiOrigin: this.props.origin, userActionOrigin: 'GUI' });
  };

  render() {
    return (
      <div className={classNames('InviteOrCopyButtons', this.props.className)}>
        <div className={classNames('InviteOrCopyButtons_copy-feedback', { hide: !this.state.showCopyFeedback })}>
          <i className='fa fa-check-circle' aria-hidden='true' />
          <span>Copied</span>
        </div>

        <Button
          type={this.props.active ? 'primary' : 'secondary'}
          className={classNames('InviteOrCopyButtons_invite-button', { hide: this.state.showCopyFeedback })}
          onClick={this.onInviteButtonPress}
        >
          Invite users
        </Button>
        {!this.props.hideCopyButton && (
          <div
            className={classNames('InviteOrCopyButtons_copy-url', {
              active: this.props.active,
              hide: this.state.showCopyFeedback
            })}
          >
            <span>or </span>
            <CopyTokenUrlButton
              component='a'
              origin={this.props.origin}
              token={this.props.token}
              onShowFeedback={this.onShowCopyFeedback}
              onCancelFeedback={this.onCancelCopyFeedback}
            >
              copy URL
            </CopyTokenUrlButton>
          </div>
        )}
      </div>
    );
  }
}
