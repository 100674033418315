import React, { Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../components/Alert';
import Button from '../../components/Button';
import Card, { CardBody } from '../../components/Card';
import Form from '../../components/Form';
import { InputField } from '../../components/InputField';
import Page from '../../components/Page';
import RouterComponent from '../../components/RouterComponent';
import TwoStepAuthenticationSignInAlert from '../../components/TwoStepAuthenticationSignInAlert';
import Api from '../../utils/Api';
import User from '../../utils/User';
import './styles.css';
import { withRouter } from '../../utils/withRouter';

export default withRouter(class PasswordResetPage extends RouterComponent {

  state = {
    fetching: true,
    saving: false,
    activation: false,
    username: '',
    password: '',
    password2: '',
    termsAgreed: false,
    initiatedTwoStepAuthentication: false,
    errorMessage: null
  };

  componentDidMount() {
    this.fetchPasswordResetState();
  }

  fetchPasswordResetState() {
    Api.get(`/admin-users/password-resets/${this.getRouteMatchParameter('token')}`, ({ expired, username, activation }) => {
      if (expired) {
        this.routeWithStateParameters('/login', { redirectCause: 'tokenExpired.passwordReset' });
      }
      else {
        this.setState({ username, activation, fetching: false });
      }
    });
  }

  onFormSubmit = e => {
    e.preventDefault();

    const { username, password, password2 } = this.state;

    if (password.length < 8) {
      this.setState({ errorMessage: 'Password must be at least 8 characters long' });
      return;
    }

    if (password !== password2) {
      this.setState({ errorMessage: 'Passwords should match' });
      return;
    }

    Api.post(`/admin-users/password-resets/${this.getRouteMatchParameter('token')}`, { password }, () => {
      User.authenticateWithPassword(username, password, responseCode => {
        if (responseCode === 'twoStep') {
          this.setState({ initiatedTwoStepAuthentication: true });
        }
        else {
          this.routeToHomepage();
        }
      });
    });

    this.setState({ saving: true });
  };

  onFieldChange = ({ currentTarget: { type, name, checked, value } }) => {
    this.setState({ [name]: type === 'checkbox' ? checked : value, errorMessage: null });
  };

  render() {
    return (
      <Page className='PasswordResetPage' title={this.state.activation ? 'Activate your account' : 'Enter your new password'} noHeading>
        {this.state.fetching ?

          <h1>Please wait...</h1> :

          <div className='PasswordResetPage_box'>
            <Card>
              <Form onSubmit={this.onFormSubmit}>
                <CardBody>
                  {this.state.initiatedTwoStepAuthentication ? (
                    <TwoStepAuthenticationSignInAlert />
                  ) : (
                    <Fragment>
                      <Alert type='ok'>
                        <AlertEmphasis>Hello, {this.state.username}!</AlertEmphasis><br />
                        {this.state.activation ?
                          'Please choose a password for your account, after which you will be able to access your licenses' :
                          'Please choose a new password for your account'
                        }
                      </Alert>

                      <InputField
                        type='password'
                        name='password'
                        label='Password'
                        value={this.state.password}
                        errorMessage={this.state.errorMessage}
                        onChange={this.onFieldChange}
                        autoFocus
                      />

                      <InputField
                        type='password'
                        name='password2'
                        label='Re-type password'
                        value={this.state.password2}
                        errorMessage={this.state.errorMessage}
                        onChange={this.onFieldChange}
                      />

                      {this.state.activation && (
                        <div>
                          <div className='PasswordResetPage_privacy-link'>
                            <a href='https://manuals.jrebel.com/zt-license-management/security/' target='_blank' rel='noopener noreferrer'>
                              Data security and privacy
                            </a>
                          </div>

                          <label className='PasswordResetPage_agree-with-terms-label'>
                            <input
                              type='checkbox'
                              name='termsAgreed'
                              checked={this.state.termsAgreed}
                              onChange={this.onFieldChange}
                            />

                            <span>
                              <span> I agree with the </span>
                              <a href='/tos.pdf' target='_blank' rel='noopener noreferrer'>Terms of Service</a>
                            </span>
                          </label>
                        </div>
                      )}

                      <div className='PasswordResetPage_submit-button'>
                        <Button type='primary' submit disabled={this.state.saving || (this.state.activation && !this.state.termsAgreed)}>
                          {this.state.activation ? 'Activate' : 'Reset'}
                        </Button>
                      </div>
                    </Fragment>
                  )}
                </CardBody>
              </Form>
            </Card>
          </div>}
      </Page>
    );
  }
});
