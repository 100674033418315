import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../../../components/Button';
import './styles.css';

const ESC = 27;

export default class Onboarding extends Component {

  static propTypes = {
    type: PropTypes.string.isRequired,
    highlightedElement: PropTypes.instanceOf(HTMLElement).isRequired,
    onExit: PropTypes.func.isRequired
  };

  state = {
    overlayHoleRect: {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateOverlayHoleRect);
    window.addEventListener('keydown', this.onKeyDown);
    this.updateOverlayHoleRect();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateOverlayHoleRect);
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ keyCode }) => {
    if (keyCode === ESC) {
      this.props.onExit();
    }
  };

  updateOverlayHoleRect = () => {
    this.setState({ overlayHoleRect: this.getOverlayHoleRect() });
  };

  getOverlayHoleRect() {
    const boundingRect = this.props.highlightedElement.getBoundingClientRect();
    const verticalOffset = 25;
    const margin = 45;

    return {
      left: boundingRect.left - margin,
      top: boundingRect.top + window.scrollY - margin + verticalOffset,
      width: boundingRect.width + margin * 2,
      height: boundingRect.height + margin * 2
    };
  }

  renderNoTeamsOnboarding() {
    return this.props.type === 'noTeams' && (
      <div className='DashboardOnboarding_no-teams'>
        <p className='DashboardOnboarding_emphasized'>Welcome to the Dashboard!</p>
        <p>Add a team and invite developers to activate products.</p>
        <p>Once done, you will start seeing cool statistics!</p>
        <br />
        <p className='DashboardOnboarding_no-teams_action-text'>Add a team now</p>
        <br />
        <i className='icon icon-onboarding-arrow-1' aria-hidden='true' />
      </div>
    );
  }

  renderNewAdminOnboarding() {
    return this.props.type === 'newAdmin' && (
      <div className='DashboardOnboarding_new-admin'>
        <p className='DashboardOnboarding_emphasized'>Welcome to the Dashboard!</p>
        <p>Invite developers to activate products or add a new team.</p>
        <br />
        <div className='DashboardOnboarding_new-admin_second-row'>
          <div>
            Invite developers
          </div>
          <div className='DashboardOnboarding_new-admin_second-row_or'>
            or
          </div>
          <div>
            Add team
          </div>
        </div>
        <br />
        <i className='icon icon-onboarding-arrow-2' aria-hidden='true' />
        <i className='icon icon-onboarding-arrow-3' aria-hidden='true' />
      </div>
    );
  }

  renderNewTeamLeadOnboarding() {
    return this.props.type === 'newTeamLead' && (
      <div className='DashboardOnboarding_new-team-lead'>
        <p className='DashboardOnboarding_emphasized'>Welcome to the Dashboard!</p>
        <p>Invite team members to activate products.</p>
        <br />
        <p className='DashboardOnboarding_new-team-lead_action-text'>Invite now</p>
        <br />
        <i className='icon icon-onboarding-arrow-2' aria-hidden='true' />
      </div>
    );
  }

  render() {
    return (
      <div className='DashboardOnboarding'>
        <div className='DashboardOnboarding_overlay' style={this.state.overlayHoleRect}>
          <div className='DashboardOnboarding_content'>
            {this.renderNoTeamsOnboarding()}
            {this.renderNewAdminOnboarding()}
            {this.renderNewTeamLeadOnboarding()}
          </div>
        </div>
        <Button className='DashboardOnboarding_close-button' onClick={this.props.onExit}>
          Close<i className='icon icon-close' aria-hidden='true' />
        </Button>
      </div>
    );
  }
}
