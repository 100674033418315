import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Button from '../../../components/Button';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import { productTypes } from '../../../enums/ProductType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { hasJRebelEnabled } from '../selectors';
import DeleteModal from './DeleteModal';
import ProductsPanel from './ProductsPanel';
import { hasRedeployTimeChanged, hasTeamLeadChanged, isEdit, isFormValid, isFormValidForTeamLead, isTeamLeadEmailValid, toFormState, toRequestParams } from './selectors';
import './styles.css';
import SuccessModal from './SuccessModal';
import TeamLeadAddedModal from './TeamLeadAddedModal';

export default class TeamForm extends Component {

  static propTypes = {
    editedTeam: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    adminUsers: [],
    activeModal: 'form',
    saving: false,
    originalForm: this.props.editedTeam ? toFormState(this.props.editedTeam) : null,
    form: this.props.editedTeam ? toFormState(this.props.editedTeam) : {
      token: undefined,
      name: {
        value: '',
        errorMessage: null
      },
      teamLead: {
        value: '',
        disabled: false,
        errorMessage: null
      },
      products: productTypes.map(id => ({
        productType: id,
        enabled: true,
        limit: '',
        usage: 0,
        lastTouched: false
      })),
      redeployTimeSeconds: ''
    }
  };

  onFormSubmit = e => {
    e.preventDefault();

    if (!isTeamLeadEmailValid(this.state)) {
      this.setTeamLeadErrorMessage('Please enter a valid email address.');
      return;
    }

    this.setState({ saving: true });

    if (isEdit(this.state)) {
      this.saveEdit();
    }
    else {
      this.saveCreate();
    }
  };

  saveCreate() {
    Api.post('/teams', toRequestParams(this.state.form), ({ error, token }) => {
      if (error === 'TEAM_LEAD_EXISTS_IN_ANOTHER_ACCOUNT') {
        this.setTeamLeadErrorMessage('Email address already in use, try another one.');
      }
      else if (error === 'TEAM_NAME_EXISTS') {
        this.setTeamNameErrorMessage('A team with such a name already exists.');
      }
      else if (error === 'TEAM_LEAD_IS_VIEW_ONLY_ADMIN') {
        this.setTeamLeadErrorMessage('View only administrators can not be team lead. Please change the role of the administrator or choose someone else.');
      }
      else if (error === 'TEAM_LEAD_IS_BLOCKED') {
        this.setTeamLeadErrorMessage(
          `Administrator ${this.state.form.teamLead.value} is currently blocked. Blocked administrators cannot be set as a team lead. Please unblock him or choose someone else.`);
      }
      else if (this.state.form.redeployTimeSeconds && hasJRebelEnabled(this.state.form.products)) {
        Api.put('/teams/redeploy-time-seconds',
          { origin: 'CREATE_TEAM', list: [ { token, redeployTimeSeconds: this.state.form.redeployTimeSeconds } ] },
          () => this.onCreateSaved(token));
      }
      else {
        this.onCreateSaved(token);
      }
    });
  }

  onCreateSaved(token) {
    Api.get('/admin-users', ({ list }) => {
      this.setState(state => ({
        activeModal: state.form.teamLead.value && state.form.teamLead.value !== User.getUsername() ? 'teamLeadAdded' : 'success',
        form: { ...state.form, token },
        adminUsers: list
      }));
    });
  }

  saveEdit() {
    Api.put('/teams', toRequestParams(this.state.form), ({ error }) => {
      if (error === 'TEAM_LEAD_EXISTS_IN_ANOTHER_ACCOUNT') {
        this.setTeamLeadErrorMessage('Email address already in use, try another one.');
      }
      else if (error === 'TEAM_NAME_EXISTS') {
        this.setTeamNameErrorMessage('A team with such a name already exists.');
      }
      else if (error === 'TEAM_LEAD_IS_VIEW_ONLY_ADMIN') {
        this.setTeamLeadErrorMessage('View only administrators can not be team lead. Please change the role of the administrator or choose someone else.');
      }
      else if (error === 'TEAM_LEAD_IS_BLOCKED') {
        this.setTeamLeadErrorMessage(
          `Administrator ${this.state.form.teamLead.value} is currently blocked. Blocked administrators cannot be set as a team lead. Please unblock him or choose someone else.`);
      }
      else if (hasRedeployTimeChanged(this.state) && hasJRebelEnabled(this.state.form.products)) {
        Api.put('/teams/redeploy-time-seconds',
          { origin: 'EDIT_TEAM', list: [ { token: this.state.form.token, redeployTimeSeconds: this.state.form.redeployTimeSeconds } ] },
          this.onEditSaved);
      }
      else {
        this.onEditSaved();
      }
    });
  }

  onEditSaved = () => {
    if (hasTeamLeadChanged(this.state)) {
      this.setState({ activeModal: 'teamLeadAdded' });
    }
    else {
      this.props.onSave();
    }
  };

  setTeamLeadErrorMessage(message) {
    this.setState(state => ({ saving: false, form: { ...state.form, teamLead: { ...state.form.teamLead, errorMessage: message } } }));
  }

  setTeamNameErrorMessage(message) {
    this.setState(state => ({ saving: false, form: { ...state.form, name: { ...state.form.name, errorMessage: message } } }));
  }

  onNameChange = ({ currentTarget: { value } }) => {
    this.setState(state => ({ form: { ...state.form, name: { value, errorMessage: null } } }));
  };

  onTeamLeadChange = ({ currentTarget: { value } }) => {
    this.setState(state => ({ form: { ...state.form, teamLead: { disabled: false, value, errorMessage: null } } }));
  };

  onTeamLeadDisabledChange = ({ currentTarget: { checked } }) => {
    this.setState(state => ({ form: { ...state.form, teamLead: { disabled: checked, value: '', errorMessage: null } } }));
  };

  onProductsChange = products => {
    this.setState(state => ({ form: { ...state.form, products } }));
  };

  onRedeployTimeChange = ({ currentTarget: { value } }) => {
    this.setState(state => ({ form: { ...state.form, redeployTimeSeconds: value } }));
  };

  onDeleteButtonPress = () => {
    this.setState({ activeModal: 'delete' });
  };

  onDeleteModalCancel = () => {
    this.setState({ activeModal: 'form' });
  };

  render() {
    return (
      <div className='TeamForm'>
        {this.state.activeModal === 'delete' && <DeleteModal team={this.state.originalForm} onSuccess={this.props.onSave} onCancel={this.onDeleteModalCancel} />}
        {this.state.activeModal === 'success' && <SuccessModal team={this.state.form} onClose={this.props.onSave} />}
        {this.state.activeModal === 'teamLeadAdded' && (
          <TeamLeadAddedModal teamLead={this.state.form.teamLead.value} adminUsers={this.state.adminUsers} onClose={this.props.onSave} />
        )}

        {this.state.activeModal === 'form' && (
          <Modal
            title={`${isEdit(this.state) ? 'Edit' : 'Add'} team`}
            className='TeamForm_modal'
            size='l'
            buttons={
              <Fragment>
                {!User.isTeamLead() && isEdit(this.state) && (
                  <Button type='link' className='TeamForm_delete-team-button' onClick={this.onDeleteButtonPress}>Delete team</Button>
                )}
                <div>
                  <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
                  <ModalButton
                    type='primary'
                    disabled={this.state.saving || (User.isTeamLead() ? !isFormValidForTeamLead(this.state) : !isFormValid(this.state))}
                    submit
                  >
                    {isEdit(this.state) ? 'Save changes' : 'Add team'}
                  </ModalButton>
                </div>
              </Fragment>
            }
            onSubmit={this.onFormSubmit}
            onClose={this.props.onCancel}
          >
            <InputField
              type='text'
              name='name'
              label='Team name'
              tooltip={{
                size: 'l',
                text: 'Think what developer teams do you have and name the teams accordingly.'
              }}
              value={this.state.form.name.value}
              errorMessage={this.state.form.name.errorMessage}
              onChange={this.onNameChange}
              autoFocus
            />

            {!User.isTeamLead() && (
              <Fragment>
                <InputField
                  type='text'
                  name='teamLead'
                  label='Team lead email'
                  tooltip={{
                    size: 'l',
                    text: 'The team lead will receive an email invite to Rebel Licenses. The team lead can manage team members and view team statistics.'
                  }}
                  value={this.state.form.teamLead.value || ''}
                  disabled={this.state.form.teamLead.disabled}
                  errorMessage={this.state.form.teamLead.errorMessage}
                  onChange={this.onTeamLeadChange}
                />

                <div className='TeamForm_team-lead-disabled-checkbox'>
                  <input
                    type='checkbox'
                    name='teamLead.disabled'
                    id='teamLead.disabled'
                    checked={this.state.form.teamLead.disabled}
                    onChange={this.onTeamLeadDisabledChange}
                  />

                  <label htmlFor='teamLead.disabled'>I want to manage the team myself</label>
                </div>
              </Fragment>
            )}
            {(!User.isTeamLead() || hasJRebelEnabled(this.state.form.products)) && (
              <ProductsPanel
                products={this.state.form.products}
                redeployTimeSeconds={this.state.form.redeployTimeSeconds}
                onProductsChange={this.onProductsChange}
                onRedeployTimeChange={this.onRedeployTimeChange}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}
