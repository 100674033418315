import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.css';

export default class Toggle extends Component {

  static defaultProps = {
    editable: true
  };

  static propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    editable: PropTypes.bool
  };

  render() {
    return (
      <div
        className={classNames('Toggle', { enabled: this.props.enabled, readonly: !this.props.editable }, this.props.className)}
        onClick={this.props.editable ? (() => this.props.onChange(!this.props.enabled)) : null}
      >
        <div className='Toggle_thumb'>
          {this.props.enabled ? 'On' : 'Off' }
        </div>
      </div>
    );
  }
}
