import moment from 'moment';

export function getTeamActiveSeatUsers(list: { status: string, seatReservations: any[] }[], teamName: string) {
  return list
    .filter(({ status }) => status === 'ACTIVE')
    .filter(({ seatReservations }) => seatReservations.some(sr => sr.teamName === teamName));
}

export function isDomainBlacklisted(email: string, whitelistedDomains: { domain: string }[]) {
  return whitelistedDomains.length > 0 && whitelistedDomains
    .map(({ domain }) => domain)
    .every(domain => isBlacklisted(email, domain));
}

export function getHavingOtherDomainThanListOrForm(seatUsers: { email: string }[], domains: { domain: string }[], domain = null) {
  const allDomains = domains.map(({ domain }) => domain).concat(domain ? [ domain ] : []);

  return seatUsers.filter(({ email }) => allDomains.every(domain => isBlacklisted(email, domain)));
}

// keep in sync with Java counterpart
export function isBlacklisted(email: string, whitelistedDomain: string) {
  const domainSegments = whitelistedDomain.split(/\./);
  let regexPattern = '^.+@';

  for (let i = 0; i < domainSegments.length; i++) {
    if (i === domainSegments.length - 1) {
      regexPattern += domainSegments[i].replace(/\*/g, '[^.]*');
    }
    else {
      regexPattern += domainSegments[i].replace(/\*/g, '.*');
      regexPattern += '\\.';
    }
  }

  return !new RegExp(`${regexPattern}$`, 'i').test(email);
}

export function isInt(value: any) {
  const parsedValue = parseInt(value, 10);
  return !isNaN(parsedValue) && parsedValue.toString() === value.toString();
}

export function getRedeployTimeValidationMessage(redeployTimeSeconds?: number | string) {
  if (redeployTimeSeconds === '' || redeployTimeSeconds === undefined) {
    return null;
  }

  // @ts-ignore
  if (!isInt(redeployTimeSeconds) || parseInt(redeployTimeSeconds, 10) <= 0) {
    return 'Please enter a number bigger than zero.';
  }

  // @ts-ignore
  if (parseInt(redeployTimeSeconds, 10) > 7200) {
    return 'Please enter a number smaller than 7200.';
  }

  return null;
}

export function formatNumberWithUnits(value: number, unit: string) {
  let formattedValue = value.toFixed(1);

  if (formattedValue.charAt(formattedValue.length - 1) === '0') {
    formattedValue = value.toFixed(0);
  }

  if (formattedValue !== '1') {
    unit += 's';
  }

  return { value: formattedValue, unit };
}

export function formatNumberWithoutUnits(value:number) {
  let formattedValue = value.toFixed(1);

  if (formattedValue.charAt(formattedValue.length - 1) === '0') {
    formattedValue = value.toFixed(0);
  }

  return formattedValue;
}

export function formatDuration(seconds: moment.DurationInputArg1) {
  const duration = moment.duration(seconds, 'seconds');

  if (duration.asDays() < 4) {
    return formatNumberWithUnits(duration.asHours(), 'hour');
  }

  if (duration.asDays() < 30) {
    return formatNumberWithUnits(duration.asDays(), 'day');
  }

  if (duration.asDays() < 365 / 2) {
    return formatNumberWithUnits(duration.asWeeks(), 'week');
  }

  if (duration.asDays() < 365 * 4) {
    return formatNumberWithUnits(duration.asMonths(), 'month');
  }

  return formatNumberWithUnits(duration.asYears(), 'year');
}

export function hasJRebelEnabled(products: { productType: string, enabled: boolean }[]) {
  return products.some(({ productType, enabled }) => enabled && productType === 'JREBEL');
}
