import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import InviteOrCopyButtons from '../../../../components/InviteOrCopyButtons';
import Modal, { ModalButton } from '../../../../components/Modal';
import TeamPanel, { TeamPanelTeamUrlLabel } from '../../../../components/TeamPanel';
import TokenUrlLabel from '../../../../components/TokenUrlLabel';
import SeatUserInviteForm from '../../SeatUserInviteForm';
import './styles.css';

export default class SuccessModal extends Component {

  static propTypes = {
    team: PropTypes.shape({
      token: PropTypes.string.isRequired,
      name: PropTypes.shape({
        value: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = {
    inviteFormOpen: false
  };

  onInviteButtonPress = () => {
    this.setState({ inviteFormOpen: true });
  };

  render() {
    return this.state.inviteFormOpen ?

      <SeatUserInviteForm team={{ token: this.props.team.token, name: this.props.team.name.value }} onSave={this.props.onClose} onCancel={this.props.onClose} /> :

      (
        <Modal
          title='Your team is now ready'
          size='m'
          buttons={
            <Fragment>
              <a
                className='TeamForm_link'
                href='https://manuals.jrebel.com/zt-license-management/activation/'
                target='_blank'
                rel='noopener noreferrer'
              >
                How to activate JRebel & XRebel
              </a>
              <ModalButton type='secondary' className='TeamFormSuccessModal_ok-button pull-right' onClick={this.props.onClose}>Got it!</ModalButton>
            </Fragment>
          }
          onClose={this.props.onClose}
        >
          <div className='TeamFormSuccessModal_text'>
            Continue by <strong>inviting users</strong> below.
          </div>

          <TeamPanel name={this.props.team.name.value} bodyAlwaysWithHoverStyle>
            <div className='TeamFormSuccessModal_team-details'>
              <div>
                <TeamPanelTeamUrlLabel />
              </div>
              <div>
                <TokenUrlLabel token={this.props.team.token} forPanel />
              </div>
            </div>
            <div className='TeamFormSuccessModal_team-action'>
              <InviteOrCopyButtons
                className='pull-right'
                origin='TEAM_CREATED_PAGE'
                token={this.props.team.token}
                onInviteButtonPress={this.onInviteButtonPress}
                active
              />
            </div>
          </TeamPanel>
        </Modal>
      );
  }
}
