import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import User from '../../utils/User';

const TwoStepAuthenticationPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    User.authenticateWithTwoStepAuthenticationToken(token, responseCode => {
      if (responseCode === 'ok') {
        navigate('/');
      }
      else {
        navigate('/login', { state: { redirectCause: 'tokenExpired.twoStepAuthentication' } });
      }
    });
  }, []);

  return (
    <Page className='TwoStepAuthenticationPage' title='Two-step authentication' noHeading>
      <h1>Please wait...</h1>
    </Page>
  );
};
export default TwoStepAuthenticationPage;
