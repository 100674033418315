import PropTypes from 'prop-types';
import Align from 'rc-align';
import React, { Component, Fragment } from 'react';
import ClipboardButton from 'react-clipboard.js';
import ReactTimeout from 'react-timeout';
import Popup from '../../../components/Popup';
import './styles.css';
import Tooltip from '../../../components/Tooltip';

export default ReactTimeout(class CopyTextButton extends Component {

  static propTypes = {
    component: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    copiedTooltip: PropTypes.shape({
      offset: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired,
    hoverTooltip: PropTypes.shape({
      offset: PropTypes.arrayOf(PropTypes.number).isRequired,
      text: PropTypes.string.isRequired
    }),
    onPress: PropTypes.func
  };

  static defaultProps = {
    onPress: () => {
    }
  };

  state = {
    showCopiedTooltip: false,
    timeoutId: null
  };

  elementRef = null;

  onPress = () => {
    this.props.onPress();
    this.props.clearTimeout(this.state.timeoutId);

    this.setState({ timeoutId: this.props.setTimeout(this.onTimeout, 2000), showCopiedTooltip: true });
  };

  onTimeout = () => {
    this.setState({ timeoutId: null, showCopiedTooltip: false });
  };

  onRef = element => {
    this.elementRef = element;
  };

  getTarget = () => {
    return this.elementRef;
  };

  render() {
    const { component, text, copiedTooltip, hoverTooltip, children, ...otherProps } = this.props;

    const button = (
      <span ref={this.onRef}>
        <ClipboardButton
          component={component}
          data-clipboard-text={text}
          onSuccess={this.onPress}
          {...otherProps}
        >
          {children}
        </ClipboardButton>
      </span>
    );

    return (
      <Fragment>
        {hoverTooltip ? <Tooltip offset={hoverTooltip.offset} text={hoverTooltip.text}>{button}</Tooltip> : <Fragment>{button}</Fragment>}
        {this.state.showCopiedTooltip && (
          <Align target={this.getTarget} align={{ points: [ 'tc', 'bc' ], offset: copiedTooltip.offset }} monitorWindowResize>
            <div className='CopyTextButton_tooltip'>
              <Popup
                size='xs'
                arrowPosition='top'
                text={(
                  <div><i className='fa fa-check-circle CopyTextButton_tooltip_icon' />Copied</div>
                )}
                type='success'
              />
            </div>
          </Align>
        )}
      </Fragment>
    );
  }
});
