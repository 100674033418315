import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../../components/Alert';
import Button from '../../../../components/Button';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import NavButton from '../../../../components/NavButton';
import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import TeamRedeployTimeModal from '../../TeamRedeployTimeModal';
import { getMaxEndDate, getMinStartDateOfFutureLicenses, hasFutureLicenses, hasNoSeatsReserved, shouldConfigureTeamRedeployTime } from './selectors';

export default class Alerts extends Component {

  static propTypes = {
    seatUsage: PropTypes.array.isRequired,
    teams: PropTypes.array.isRequired,
    onRedeployTimeChange: PropTypes.func.isRequired
  };

  state = {
    activeModal: null,

    accountStatus: null,

    licensedProducts: [],
    fetchingLicensedProducts: true,

    licenseDates: [],
    fetchingLicenseDates: true
  };

  componentDidMount() {
    Api.get('/account', ({ status, extendedLicense }) => {
      if (status === 'COMMERCIAL') {
        this.setState({ accountStatus: status, fetchingLicensedProducts: false, fetchingLicenseDates: false });
      }
      else {
        this.setState({ accountStatus: status, accountExtendedLicense: extendedLicense });

        Api.get('/account/licensed-products', ({ list }) => {
          this.setState({ licensedProducts: list, fetchingLicensedProducts: false });
        });

        Api.get('/licenses/dates', ({ list }) => {
          this.setState({ licenseDates: list, fetchingLicenseDates: false });
        });
      }
    });
  }

  onConfigureButtonPress = () => {
    this.setState({ activeModal: 'teamRedeployTime' });
  };

  onModalSave = () => {
    this.closeModal();
    this.props.onRedeployTimeChange();
  };

  closeModal = () => {
    this.setState({ activeModal: null });
  };

  renderAlert(type, message) {
    return (
      <Alert type={type}>
        {message}
      </Alert>
    );
  }

  renderAlerts() {
    if (this.state.fetchingLicenseDates || this.state.fetchingLicensedProducts) {
      return <span />;
    }

    if (User.isTeamLead() && this.props.teams.length === 0) {
      return this.renderAlert('error', <span><AlertEmphasis>You have no teams. </AlertEmphasis>Contact your licensing administrator.</span>);
    }

    if (User.canEdit() && this.state.accountStatus === 'COMMERCIAL' && hasNoSeatsReserved(this.props.seatUsage)) {
      return this.renderAlert('warn',
        <span>
          <AlertEmphasis>No activated seats yet.</AlertEmphasis>
          <span> {User.isTeamLead() ? 'Invite developers to activate products' : 'Add teams and invite developers to activate products'}.</span>
          {User.isAccountAdmin() && <NavButton to='/teams' type='primary' className='Alert_button pull-right'>Open teams</NavButton>}
        </span>
      );
    }

    if (this.state.accountStatus === 'EVALUATION_ONGOING') {
      return this.renderAlert('info',
        <span>
          <AlertEmphasis>{`You are currently evaluating ${this.state.licensedProducts.map(ProductTypeLabel.asString).join(' and ')}.`}</AlertEmphasis>
          <span> Evaluation licenses expire on <DateTimeLabel timestamp={getMaxEndDate(this.state.licenseDates)} />.</span>
        </span>
      );
    }

    if (this.state.accountStatus === 'UNLICENSED' && hasFutureLicenses(this.state.licenseDates)) {
      return this.renderAlert('info',
        <span>
          <AlertEmphasis>Licenses not started yet.</AlertEmphasis>
          <span> Your new licenses will start from <DateTimeLabel timestamp={getMinStartDateOfFutureLicenses(this.state.licenseDates)} />.</span>
        </span>
      );
    }

    if (this.state.accountStatus === 'EVALUATION_NOT_STARTED') {
      return this.renderAlert('info',
        <span>
          <AlertEmphasis>Evaluation not started yet.</AlertEmphasis>
          <span> Your evaluation will start from <DateTimeLabel timestamp={getMinStartDateOfFutureLicenses(this.state.licenseDates)} />.</span>
        </span>
      );
    }

    if ((this.state.accountStatus === 'UNLICENSED' && !hasFutureLicenses(this.state.licenseDates)) || this.state.accountStatus === 'EVALUATION_ENDED') {
      if (this.state.accountExtendedLicense) {
        return this.renderAlert('error',
          <span>
            <AlertEmphasis>Licenses expired.</AlertEmphasis>
            {!User.isAccountAdmin() && (<span> Your licenses have expired but a grace period is in effect. Contact account admin for more details.</span>)}
            {User.isAccountAdmin() && (
              <span> Your licenses have expired but a grace period is in effect.
                <NavButton to='/licenses' type='primary' className='Alert_button pull-right'>Open details</NavButton>
              </span>
            )}
          </span>
        );
      }
      else {
        return this.renderAlert('error',
          <span>
            <AlertEmphasis>Licenses expired.</AlertEmphasis>
            <span> Your licenses have expired. Seats can no longer be acquired. Activated products cannot be used.</span>
          </span>
        );
      }
    }

    if (User.isTeamLead() && shouldConfigureTeamRedeployTime(this.props.teams)) {
      return this.renderAlert('info',
        <span>
          <span>
            <AlertEmphasis>Please configure the default redeploy time for your teams. </AlertEmphasis>
            This will help show accurate metrics for your company.</span>
          <Button type='primary' className='Alert_button' onClick={this.onConfigureButtonPress}>Open configuration</Button>
        </span>
      );
    }

    return <span />;
  }

  render() {
    return (
      <Fragment>
        {this.state.activeModal === 'teamRedeployTime' && <TeamRedeployTimeModal origin='DASHBOARD_ALERT' onSave={this.onModalSave} onCancel={this.closeModal} />}
        {this.renderAlerts()}
      </Fragment>
    );
  }
}
