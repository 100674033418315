import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import Page from '../../../components/Page';
import ProductTypeLabel from '../../../components/ProductTypeLabel';
import RouterComponent from '../../../components/RouterComponent';
import TabBar from '../../../components/TabBar';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Api from '../../../utils/Api';
import EventEmitter from '../../../utils/EventEmitter';
import { withRouter } from '../../../utils/withRouter';
import AddWhitelistedDomainForm from '../AddWhitelistedDomainForm';
import { notificationNameLabels } from '../labels';
import TeamForm from '../TeamForm';
import './styles.css';

const descriptionTemplates = {
  TEAM_LIMIT_EXCEEDED(seatUserEmail, teamName, productType) {
    return `${seatUserEmail} tried to take a ${ProductTypeLabel.asString(productType)} seat from ${teamName}, but team seat limit is reached.`;
  },
  ACCOUNT_OUT_OF_LICENSES(seatUserEmail, teamName, productType) {
    return `${seatUserEmail} tried to take a ${ProductTypeLabel.asString(productType)} seat from ${teamName}, but your account is out of licenses.`;
  },
  INVALID_SEAT_USER_DOMAIN(seatUserEmail, teamName, productType) {
    return `${seatUserEmail} tried to take a ${ProductTypeLabel.asString(productType)} seat from ${teamName}, but this email domain is not allowed.`;
  }
};

// This was lifted out of the class because the withRouter wrapper seemed to cause visibility issues.
export const NotificationUpdatedEventId = 'NotificationPage.updated';

export default withRouter(class NotificationPage extends RouterComponent {

  state = {
    notifications: [],
    teams: [],
    whitelistedDomains: [],
    fetching: true,
    hoveredNotification: null,
    alert: null
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fetching && !this.state.fetching && this.getRouteParameter('name') && this.getRouteParameter('origin') === 'EMAIL') {
      Api.post('/notifications/events/press/call-to-action', { name: this.getRouteParameter('name'), origin: 'EMAIL' });
    }
  }

  fetch = () => {
    (async () => {
      const notificationsListPromise = this.getListPromise('/notifications');
      const teamListPromise = this.getListPromise('/teams?with_products=true');
      const whitelistedDomainsListPromise = this.getListPromise('/whitelisted-domains');

      return { notifications: await notificationsListPromise, teams: await teamListPromise, whitelistedDomains: await whitelistedDomainsListPromise };
    })().then(({ notifications, teams, whitelistedDomains }) => {
      this.updateHeader(notifications);
      this.updateNotificationStatusNewToActive(notifications);

      let alert = null;

      if (this.getRouteParameter('name') && !this.tryFindNotificationByUrl(notifications)) {
        this.closeModal();
        alert = 'Could not find the notification you we\'re looking for. Here are all the notifications.';
      }

      this.setState({ notifications, teams, whitelistedDomains, fetching: false, alert });
    });
  };

  updateHeader(notifications) {
    const list = notifications.map(notification => ({ ...notification, status: notification.status === 'NEW' ? 'ACTIVE' : notification.status }));
    EventEmitter.emit(NotificationUpdatedEventId, list);
  }

  updateNotificationStatusNewToActive(list) {
    const newNotifications = _.filter(list, { status: 'NEW' });

    if (newNotifications.length > 0) {
      Api.put('/notifications', { list: newNotifications.map(notification => ({ ...notification, status: 'ACTIVE' })) });
    }
  }

  getListPromise(url) {
    return new Promise(resolve => Api.get(url, ({ list }) => resolve(list)));
  }

  getSortedNotifications() {
    const archived = this.getRoutingKey() === 'archive';

    return _(this.state.notifications)
      .filter(({ status }) => (status === 'ARCHIVED') === archived)
      .sortBy([ ({ createdAt }) => createdAt ])
      .reverse()
      .value();
  }

  tryFindNotificationByUrl = notifications => {
    const params = this.getRouteParameters();

    return notifications
      .filter(({ name }) => name === params.name)
      .filter(({ teamName }) => teamName === params.teamName)
      .filter(({ seatUserEmail }) => seatUserEmail === params.seatUserEmail)
      .filter(({ productType }) => productType === params.productType)
      .find(({ status }) => status !== 'ARCHIVED');
  };

  archiveAndSendEvent = notification => {
    this.archive(notification);
    Api.post('/notifications/events/press/archive', { name: notification.name });
  };

  archive = notification => {
    Api.put('/notifications', { list: [ { ...notification, status: 'ARCHIVED' } ] }, this.fetch);
  };

  closeModal() {
    this.updateRouteParameters({ teamName: undefined, name: undefined, seatUserEmail: undefined, productType: undefined, origin: undefined });
  }

  closeModalAndArchiveNotification = notification => {
    this.closeModal();
    this.archive(notification);
  };

  onTableRowMouseOver = ({ currentTarget }) => {
    this.setState(state => ({ hoveredNotification: this.findNotificationByMouseEvent(state, { currentTarget }) }));
  };

  findNotificationByMouseEvent({ notifications }, { currentTarget }) {
    const searchedId = parseInt(currentTarget.getAttribute('data-id'), 10);

    return notifications.find(({ id }) => id === searchedId);
  }

  onTableRowMouseLeave = () => {
    this.setState({ hoveredNotification: null });
  };

  onActionButtonPress = event => {
    const { name, teamName, seatUserEmail, productType } = this.findNotificationByMouseEvent(this.state, event);

    this.updateRouteParameters({ name, teamName, seatUserEmail, productType });
    Api.post('/notifications/events/press/call-to-action', { name, origin: 'GUI' });
  };

  onContactSalesButtonPress = event => {
    const notification = this.findNotificationByMouseEvent(this.state, event);

    this.archive(notification);
    Api.post('/notifications/events/press/call-to-action', { name: notification.name, origin: 'GUI' });
  };

  getActionButton = ({ id, name }) => {
    const active = this.state.hoveredNotification && id === this.state.hoveredNotification.id;
    const classes = classNames('NotificationPage_table_action-button', { 'Button secondary': !active, 'Button primary': active });
    const type = active ? 'primary' : 'secondary';

    switch (name) {
    case 'TEAM_LIMIT_EXCEEDED':
      return <Button type={type} className='NotificationPage_table_action-button' data-id={id} onClick={this.onActionButtonPress}>Increase limit</Button>;
    case 'ACCOUNT_OUT_OF_LICENSES':
      return <a className={classes} href='mailto:support-rebel@perforce.com' data-id={id} onClick={this.onContactSalesButtonPress}>Contact sales</a>;
    case 'INVALID_SEAT_USER_DOMAIN':
      return (<Button type={type} className='NotificationPage_table_action-button Add_to_whitelist-button' data-id={id} onClick={this.onActionButtonPress}>
        Add domain to whitelist
      </Button>);
    default:
      return null;
    }
  };

  renderTeamForm = () => {
    const notification = this.tryFindNotificationByUrl(this.state.notifications);

    return notification && (
      <TeamForm
        editedTeam={this.state.teams.find(({ name }) => name === notification.teamName)}
        onSave={() => this.closeModalAndArchiveNotification(notification)}
        onCancel={() => this.closeModalAndArchiveNotification(notification)}
      />
    );
  };

  renderAddWhitelistedDomainForm = () => {
    const notification = this.tryFindNotificationByUrl(this.state.notifications);

    return notification && (
      <AddWhitelistedDomainForm
        domains={this.state.whitelistedDomains}
        domain={notification.seatUserEmail.split('@')[1]}
        domainWhitelistingEnabled={true}
        onSave={() => this.closeModalAndArchiveNotification(notification)}
        onCancel={() => this.closeModalAndArchiveNotification(notification)}
      />
    );
  };

  renderDescriptionCell = ({ name, seatUserEmail, teamName, productType }) => {
    return <TableCell className='NotificationPage_table_cell'>{descriptionTemplates[name](seatUserEmail, teamName, productType)}</TableCell>;
  };

  renderNoResults() {
    return (<div className='NotificationPage_no-results-message'>
      <a className='NotificationPage_no-results-image' />
      <div className='NotificationPage_no-results-text'>
        <h2>No notifications yet</h2>
        {/* <p>Add administrators to start distributing your licenses</p> */}
      </div>
    </div>);
  }

  render() {
    return (
      <Page
        className='NotificationPage'
        title='Notification feed'
        alert={this.state.alert && <Alert type='warn'>{this.state.alert}</Alert>}
        withHeading
      >
        {!this.state.fetching && this.getRouteParameter('name') === 'TEAM_LIMIT_EXCEEDED' && this.renderTeamForm()}
        {!this.state.fetching && this.getRouteParameter('name') === 'INVALID_SEAT_USER_DOMAIN' && this.renderAddWhitelistedDomainForm()}

        <TabBar>
          <NavLink end to=''>
            Active
          </NavLink>
          <NavLink to='archive'>
            Archived
          </NavLink>
        </TabBar>
        <Card>
          <Table
            className='NotificationPage_table'
            header={
              <Fragment>
                <TableRow>
                  <th className='NotificationPage_table_name-column'>What happened</th>
                  <th className='NotificationPage_table_description-column' />
                  <th className='NotificationPage_table_date-column'>Date</th>
                  {this.getRoutingKey() !== 'archive' && (
                    <Fragment>
                      <th className='NotificationPage_table_action-column' />
                      <th className='NotificationPage_table_archive-column' />
                    </Fragment>
                  )}
                </TableRow>
              </Fragment>
            }
            body={
              <Fragment>
                {this.state.fetching && (
                  <TableRow className='NotificationPage_no-results'>
                    <TableCell colSpan='5'>
                      Fetching data...
                    </TableCell>
                  </TableRow>
                )}
                {!this.state.fetching && this.getSortedNotifications().length === 0 && (

                  <TableRow className='NotificationPage_no-results'>
                    <TableCell colSpan='5'>
                      {this.renderNoResults()}
                    </TableCell>
                  </TableRow>
                )}

                {!this.state.fetching && this.getSortedNotifications().map(notification => {
                  const { id, name, createdAt, status } = notification;
                  return (
                    <TableRow key={id} data-id={id} onMouseOver={this.onTableRowMouseOver} onMouseLeave={this.onTableRowMouseLeave}>
                      <TableCell>
                        <div className={classNames('NotificationPage_table_name', { 'new': status === 'NEW' })}>{notificationNameLabels[name] || name}</div>
                      </TableCell>
                      {this.renderDescriptionCell(notification)}
                      <TableCell className='NotificationPage_table_cell'><DateTimeLabel timestamp={createdAt} /></TableCell>
                      {this.getRoutingKey() !== 'archive' && (
                        <Fragment>
                          <TableCell>{this.getActionButton(notification)}</TableCell>
                          <TableCell>
                            <Button className='NotificationPage_table_archive-button' onClick={() => this.archiveAndSendEvent(notification)}>Archive</Button>
                          </TableCell>
                        </Fragment>
                      )}
                    </TableRow>
                  );
                })}
              </Fragment>
            }
          />
        </Card>
      </Page>
    );
  }
});
