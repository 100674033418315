import _ from 'lodash';
import moment from 'moment';

export function sortLicenses(licenses) {
  return _(licenses)
    .sortBy('endDate')
    .reverse()
    .sortBy(({ purpose }) => purpose === 'EVALUATION')
    .value();
}

export function groupByOrders(licenses) {
  return _(licenses)
    .groupBy(({ orderId }) => orderId)
    .map((licenses, groupOrderId) => ({ groupOrderId, licenses }))
    .value();
}

export function filterOrders(orders, tab) {
  return orders
    .filter(({ licenses }) => tab || !areAllExpired(licenses))
    .filter(({ licenses }) => tab !== 'expired' || areAllExpired(licenses));
}

export function hasExpired(dateString) {
  return moment.utc().isAfter(toEndOfDay(dateString));
}

export function isInTheFuture(dateString) {
  return moment.utc().isBefore(moment.utc(dateString));
}

export function toEndOfDay(dateString) {
  return moment.utc(dateString).endOf('day').format();
}

function areAllExpired(licenses) {
  return licenses.every(({ endDate }) => hasExpired(endDate));
}

export function getAvailableSeatCount(seatUsage, license) {
  const exportableSeatCount = license.seatCount - Object.values(license.exportedSeatCounts).reduce((sum, seatCount) => sum + seatCount);

  if (isInTheFuture(license.startDate)) {
    return exportableSeatCount;
  }
  else {
    const { reserved, total } = seatUsage.find(({ licenseType }) => licenseType === license.type);
    return Math.min(exportableSeatCount, total - reserved);
  }
}
