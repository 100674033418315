import React, { Fragment } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Card, { CardBody, CardHeading, CardTitle } from '../../components/Card';
import Form from '../../components/Form';
import { InputField } from '../../components/InputField';
import Page from '../../components/Page';
import RouterComponent from '../../components/RouterComponent';
import User from '../../utils/User';
import '../SsoLoginPage/styles.css';
import { withRouter } from '../../utils/withRouter';

export default withRouter(class LoginPage extends RouterComponent {

  state = {
    username: this.getRouteStateParameter('username') || '',
    errorMessage: null,
    loggingIn: false
  };

  onFormSubmit = e => {
    e.preventDefault();

    this.setState({ loggingIn: true });

    User.authenticateWithSso(this.state.username, responseCode => {
      this.setState({ loggingIn: false });

      switch (responseCode) {
      case 'ok':
        if ([ 'sessionEnded', 'unauthenticated' ].includes(this.getRouteStateParameter('redirectCause'))) {
          this.routeTo({ pathname: this.getRouteStateParameter('lastRoute'), search: this.getRouteStateParameter('lastSearch') });
        }
        else {
          this.routeToHomepage();
        }
        break;
      case 'unavailable':
        this.setState(() => {
          const errorMessage = 'SSO unavailable';
          return { errorMessage };
        });
        break;
      default:
        this.setState(() => {
          const errorMessage = 'SSO unavailable';
          return { errorMessage };
        });
        break;
      }
    });
  };

  onFieldChange = ({ currentTarget: { name, value } }) => {
    this.setState({ [name]: value, errorMessage: null });
  };

  isFormDisabled() {
    return this.state.loggingIn;
  }

  onPasswordAuthButtonClick() {
    this.routeWithStateParameters('/login', { username: this.state.username });
  }

  renderAlertMessage() {
    switch (this.getRouteStateParameter('redirectCause')) {
    case 'logout':
      return <Alert type='ok'>You have been successfully signed out.</Alert>;
    case 'forgotPassword':
      return <Alert type='ok'>An email was sent to {this.getRouteStateParameter('username')} with instructions how to reset your password.</Alert>;
    case 'sessionEnded':
      return <Alert type='ok'>You were signed out due to inactivity.</Alert>;
    case 'tokenExpired.passwordReset':
      return <Alert type='warn'>Link expired. Please <NavLink to='/forgot-password'>reset your password</NavLink> to get a new one.</Alert>;
    case 'tokenExpired.twoStepAuthentication':
      return <Alert type='warn'>This two-step authentication link has expired. Please sign in again.</Alert>;
    default:
      return null;
    }
  }

  render() {
    if (User.isAuthenticated()) {
      return <Navigate to='/' />;
    }

    return (
      <Page className='LoginPage' title='Sign in with SSO' noHeading>
        <div className='LoginPage_box'>
          {this.renderAlertMessage()}

          <Card>
            <Form onSubmit={this.onFormSubmit}>
              <CardHeading>
                <CardTitle>
                  Sign in with SSO
                </CardTitle>
              </CardHeading>

              <CardBody>
                <Fragment>
                  <InputField
                    type='text'
                    name='username'
                    label='Email'
                    value={this.state.username}
                    disabled={this.isFormDisabled()}
                    errorMessage={this.state.errorMessage}
                    onChange={this.onFieldChange}
                    autoFocus
                  />

                  <Button type='primary' submit className='SsoLoginPage_button' disabled={this.isFormDisabled()}>Continue</Button>
                  <div className='SsoLoginPage_spacer'><span className=''>Or sign in with</span></div>
                  <Button type='secondary' className='SsoLoginPage_button' disabled={this.isFormDisabled()} onClick={() => this.onPasswordAuthButtonClick()}>Password</Button>
                </Fragment>
              </CardBody>

            </Form>
          </Card>
        </div>
      </Page>
    );
  }
});
