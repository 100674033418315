import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';

Alerts.propTypes = {
  alert: PropTypes.shape({
    active: PropTypes.string,
    username: PropTypes.string
  }),
  twoStepAuthenticationEnabled: PropTypes.bool
};

function render(message) {
  return (
    <Alert type='ok'>
      {message}
    </Alert>
  );
}

export default function Alerts({ alert: { active, username }, twoStepAuthenticationEnabled }) {
  switch (active) {
  case 'create':
    return render(<span>Invitation email sent successfully to <AlertEmphasis>{username}</AlertEmphasis>.</span>);
  case 'edit':
    return render(<span><AlertEmphasis>{username}</AlertEmphasis> role changed successfully.</span>);
  case 'reset':
    return render(<span>Password reset email sent successfully to <AlertEmphasis>{username}</AlertEmphasis>.</span>);
  case 'resend':
    return render(<span>Invitation email sent successfully to <AlertEmphasis>{username}</AlertEmphasis>.</span>);
  case 'block':
    return render(<span><AlertEmphasis>{username}</AlertEmphasis> has been blocked successfully.</span>);
  case 'unblock':
    return render(<span><AlertEmphasis>{username}</AlertEmphasis> has been unblocked successfully.</span>);
  case 'delete':
    return render(<span><AlertEmphasis>{username}</AlertEmphasis> has been deleted successfully.</span>);
  case 'twoStepAuthentication':
    return render(<span>Two-step authentication {twoStepAuthenticationEnabled ? 'enabled' : 'disabled'} successfully.</span>);
  default:
    return <Fragment />;
  }
}
