import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../../components/Button';
import InfoTooltip from '../../../../components/InfoTooltip';
import User from '../../../../utils/User';
import { formatDuration } from '../../selectors';
import TeamRedeployTimeModal from '../../TeamRedeployTimeModal';
import UsageCircle from './../UsageCircle';
import { getTotalFreeSeats, getTotalReservedSeats, getTotalSeats, getTotalTimeSavedPerSeatUser } from './selectors';
import './styles.css';

export default class Totals extends Component {

  static propTypes = {
    timeSavedSeconds: PropTypes.object.isRequired,
    seatUserCount: PropTypes.object.isRequired,
    seatUsage: PropTypes.array.isRequired,
    invitedSeatUsers: PropTypes.array,
    onRedeployTimeChange: PropTypes.func.isRequired
  };

  state = {
    activeModal: null
  };

  onConfigureButtonPress = () => {
    this.setState({ activeModal: 'teamRedeployTime' });
  };

  onModalSave = () => {
    this.closeModal();
    this.props.onRedeployTimeChange();
  };

  closeModal = () => {
    this.setState({ activeModal: null });
  };

  getTimeSavedLabel(seconds) {
    const timeSaved = formatDuration(seconds);
    return `${timeSaved.value} ${timeSaved.unit}`;
  }

  render() {
    const circleProps = { radius: 21, strokeWidth: 4 };

    return (
      <div className='DashboardTotals'>
        {this.state.activeModal === 'teamRedeployTime' && (
          <TeamRedeployTimeModal origin='DASHBOARD_CONFIGURATION' onSave={this.onModalSave} onCancel={this.closeModal} />
        )}
        <div className='DashboardTotals_seat-usage'>
          <h1>{User.isTeamLead() ? 'Users in your teams' : 'Overall seat usage'}</h1>
          <div>
            {User.isTeamLead() ?
              <Fragment>
                <div className='DashboardTotals_box circle'>
                  <UsageCircle
                    usage={getTotalReservedSeats(this.props.seatUsage)}
                    total={getTotalReservedSeats(this.props.seatUsage) + this.props.invitedSeatUsers.length} {...circleProps}
                  />
                </div>
                <div className='DashboardTotals_box'>
                  <div>Activated</div>
                  <div className='DashboardTotals_number emphasized'>{getTotalReservedSeats(this.props.seatUsage)}</div>
                </div>
                <div className='DashboardTotals_box'>
                  <div>Invited</div>
                  <div className='DashboardTotals_number de-emphasized'>{this.props.invitedSeatUsers.length}</div>
                </div>
                <div className='DashboardTotals_box'>
                  <div>Total</div>
                  <div className='DashboardTotals_number'>{getTotalReservedSeats(this.props.seatUsage) + this.props.invitedSeatUsers.length}</div>
                </div>
              </Fragment> :

              <Fragment>
                <div className='DashboardTotals_box circle'>
                  <UsageCircle usage={getTotalReservedSeats(this.props.seatUsage)} total={getTotalSeats(this.props.seatUsage)} {...circleProps} />
                </div>
                <div className='DashboardTotals_box'>
                  <div>In use</div>
                  <div className='DashboardTotals_number emphasized'>{getTotalReservedSeats(this.props.seatUsage)}</div>
                </div>
                <div className='DashboardTotals_box'>
                  <div>Available</div>
                  <div className='DashboardTotals_number'>{getTotalFreeSeats(this.props.seatUsage)}</div>
                </div>
                <div className='DashboardTotals_box'>
                  <div>Total seats</div>
                  <div className='DashboardTotals_number'>{getTotalSeats(this.props.seatUsage)}</div>
                </div>
              </Fragment>
            }
          </div>
        </div>

        <div className='DashboardTotals_time-saved'>
          <h1>Development time saved</h1>
          <div className='DashboardTotals_box piggy-bank'>
            <i className='icon icon-piggy-bank-o' aria-hidden='true' />
          </div>
          {getTotalReservedSeats(this.props.seatUsage) > 0 ?
            <Fragment>
              <div className='DashboardTotals_box'>
                <div>Total{User.isTeamLead() && ' by your teams'}</div>
                <div className='DashboardTotals_number emphasized'>
                  {this.getTimeSavedLabel(this.props.timeSavedSeconds.total)}
                </div>
              </div>
              <div className='DashboardTotals_box'>
                <div>
                  <span>Per developer</span>
                  <InfoTooltip size='s' text='This is the average time saved per developer. Based on JRebel users.' />
                </div>
                <div className='DashboardTotals_number'>
                  {this.getTimeSavedLabel(getTotalTimeSavedPerSeatUser(this.props.timeSavedSeconds, this.props.seatUserCount))}
                </div>
              </div>
              <div className='DashboardTotals_box'>
                {User.isTeamLead() ?
                  <Button type='link' className='DashboardTotals_configuration-button' onClick={this.onConfigureButtonPress}>Configure</Button> :
                  <NavLink to='/analytics'>More analytics</NavLink>}
              </div>
            </Fragment> :

            <div className='DashboardTotals_box'>
              <div>Once developers activate seats,</div>
              <div>we will show you cool statistics!</div>
            </div>}
        </div>

        <div className='DashboardTotals_help-bubble'>
          <h1>Stuck?</h1>
          <div>We can help!</div>
          <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>
        </div>
      </div>
    );
  }

}
