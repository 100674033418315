import _ from 'lodash';

export function filterUsers(users, presentationMode, filter) {
  const noCustomFilter = () => true;
  const includePendingBlocked = (seat, user) => seat.offline || user.status !== 'BLOCKED';
  let includeStatuses;
  let excludeNoSeatsIfNoFilter;
  let customSeatFilter;

  switch (presentationMode) {
  case 'team':
    includeStatuses = [ 'ACTIVE', 'INVITED', 'BLOCKED' ];
    customSeatFilter = includePendingBlocked;
    excludeNoSeatsIfNoFilter = true;
    break;
  case 'offline':
    includeStatuses = [ 'ACTIVE', 'BLOCKED' ];
    customSeatFilter = ({ offline }) => offline;
    excludeNoSeatsIfNoFilter = true;
    break;
  case 'invited':
    includeStatuses = [ 'INVITED' ];
    customSeatFilter = noCustomFilter;
    excludeNoSeatsIfNoFilter = true;
    break;
  case 'blocked':
    includeStatuses = [ 'BLOCKED' ];
    customSeatFilter = noCustomFilter;
    excludeNoSeatsIfNoFilter = false;
    break;
  case 'forgotten':
    includeStatuses = [ 'FORGOTTEN' ];
    customSeatFilter = noCustomFilter;
    excludeNoSeatsIfNoFilter = true;
    break;
  case 'individual':
    includeStatuses = [ 'INDIVIDUAL' ];
    customSeatFilter = noCustomFilter;
    excludeNoSeatsIfNoFilter = true;
    break;
  default:
    includeStatuses = [ 'ACTIVE', 'BLOCKED' ];
    customSeatFilter = includePendingBlocked;
    excludeNoSeatsIfNoFilter = true;
    break;
  }

  const filterSeatsBy = field => (seat => !seat[field] || !filter[field] || seat[field] === filter[field]);

  return users.map(user => Object.assign({}, user))
    .filter(({ status }) => includeStatuses.includes(status))
    .map(user => {
      user.seats = user.seats
        .filter(seat => customSeatFilter(seat, user))
        .filter(filterSeatsBy('teamName'))
        .filter(filterSeatsBy('productType'))
        .filter(filterSeatsBy('orderId'));
      return user;
    })
    .filter(({ seats }) => (!excludeNoSeatsIfNoFilter && Object.keys(filter).length === 0) || seats.length > 0);
}

export function getOfflineProductCount(user) {
  return user.seats
    .map(({ offline }) => offline)
    .filter(offline => offline)
    .length;
}

export function hasOfflineProducts(user) {
  return getOfflineProductCount(user) > 0;
}

export function getFarthestOfflineUntil(user) {
  return _(user.seats).map(({ offlineUntil }) => offlineUntil).max();
}

export function getClosestLastSeen(user) {
  return _(user.seats).map(({ lastSeen }) => lastSeen).max();
}
