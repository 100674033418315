import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalButton } from '../../../../components/Modal';
import './styles.css';
import CopyTextButton from '../../../TeamTokenPage/CopyTextButton';

const SsoDetails = ({ sso, onCancel }) => {
  return (
    <div className='SsoDetails_modal'>
      <Modal
        title='SSO Details'
        className='SsoDetails_modal'
        onClose={onCancel}
        confirmClose={false}
        buttons={
          <Fragment>
            <ModalButton
              type='primary'
              className='pull-right'
              onClick={onCancel}
            >
              Got it
            </ModalButton>
          </Fragment>
        }
      >
        <Fragment>
          <div className='SsoDetails_details'>

            <div className='SsoDetails_details-row'>
              <b className='SsoDetails_details-title'>SSO Type</b>
              <div className='SsoDetails_details-value-container'>
                <div className='SsoDetails_details-value'>OpenID connect</div>
              </div>
            </div>

            <div className='SsoDetails_details-row'>
              <b className='SsoDetails_details-title'>Client Name</b>
              <div className='SsoDetails_details-value-container'>
                <div className='SsoDetails_details-value'>
                  <CopyTextButton
                    component='button'
                    text={sso.clientName}
                    copiedTooltip={{ offset: [ 10, 10 ] }}
                    hoverTooltip={{ text: 'Click to copy Client ID', offset: [ 10, -5 ] }}
                    className='SsoDetails_value-tooltip'
                  >
                    {sso.clientName || '-'}
                  </CopyTextButton>
                </div>
              </div>
            </div>

            <div className='SsoDetails_details-row'>
              <b className='SsoDetails_details-title'>Client ID</b>
              <div className='SsoDetails_details-value-container'>
                <div className='SsoDetails_details-value'>
                  <CopyTextButton
                    component='button'
                    text={sso.clientId}
                    copiedTooltip={{ offset: [ 10, 10 ] }}
                    hoverTooltip={{ text: 'Click to copy Client ID', offset: [ 10, -5 ] }}
                    className='SsoDetails_value-tooltip'
                  >
                    {sso.clientId || '-'}
                  </CopyTextButton>
                </div>
              </div>
            </div>

            <div className='SsoDetails_details-row'>
              <b className='SsoDetails_details-title'>Client secret</b>
              <div className='SsoDetails_details-value-container'>
                <div className='SsoDetails_details-value'>
                  <CopyTextButton
                    component='button'
                    text={sso.clientSecret}
                    copiedTooltip={{ offset: [ 10, 10 ] }}
                    hoverTooltip={{ text: 'Click to copy Client secret', offset: [ 10, -5 ] }}
                    className='SsoDetails_value-tooltip'
                  >
                    {sso.clientSecret || '-'}
                  </CopyTextButton>
                </div>
              </div>
            </div>

            <div className='SsoDetails_details-row'>
              <b className='SsoDetails_details-title'>Callback URL</b>
              <div className='SsoDetails_details-value-container'>
                <div className='SsoDetails_details-value'>
                  <CopyTextButton
                    component='button'
                    text={sso.callbackUrl}
                    copiedTooltip={{ offset: [ 10, 10 ] }}
                    hoverTooltip={{ text: 'Click to copy Callback URL', offset: [ 10, -5 ] }}
                    className='SsoDetails_value-tooltip'
                  >
                    {sso.callbackUrl}
                  </CopyTextButton>
                </div>
              </div>
            </div>

            <div className='SsoDetails_details-row'>
              <b className='SsoDetails_details-title'>Discovery URL</b>
              <div className='SsoDetails_details-value-container'>
                <div className='SsoDetails_details-value'>
                  <CopyTextButton
                    component='button'
                    text={sso.discoveryUrl}
                    copiedTooltip={{ offset: [ 10, 10 ] }}
                    hoverTooltip={{ text: 'Click to copy Discovery URL', offset: [ 10, -5 ] }}
                    className='SsoDetails_value-tooltip'
                  >
                    {sso.discoveryUrl || '-'}
                  </CopyTextButton>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Modal>
    </div>
  );
};

SsoDetails.prototype = {
  sso: PropTypes.object,
  onCancel: PropTypes.func.isRequired
};

export default SsoDetails;
