import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ComponentPropsWithoutRef } from 'react';
import './styles.css';

BrandLabel.propTypes = {
  id: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  styled: PropTypes.bool
};

interface BrandLabelProps extends ComponentPropsWithoutRef<'span'> {
  labels: { [key: string]: string },
  id: string,
  styled: boolean,
}

export default function BrandLabel({ id, labels, styled = false, ...otherProps }: BrandLabelProps) {
  return (
    <span className={classNames('BrandLabel', { 'styled': styled })} {...otherProps}>
      <span className='BrandLabel_emphasized-letters'>{labels[id][0]}</span>
      <span>{labels[id][1]}</span>
    </span>
  );
}
