import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';

export default class ResendModal extends Component {

  static propTypes = {
    username: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false
  };

  onResendButtonPress = () => {
    this.setState({ saving: true });

    Api.post('/admin-users/invites', { username: this.props.username }, () => {
      this.props.onSuccess(this.props.username);
    });
  };

  render() {
    return (
      <Modal
        title='Resend invite'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='primary' disabled={this.state.saving} onClick={this.onResendButtonPress}>Resend</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          You are about to resend the invite to <strong>{this.props.username}</strong>.
        </p>
        <p>Are you sure?</p>
      </Modal>
    );
  }
}
