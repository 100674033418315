import PropTypes from 'prop-types';
import Align from 'rc-align';
import React, { Component } from 'react';
import Popup from '../Popup';
import './styles.css';

export default class ValidationTooltip extends Component {

  static propTypes = {
    target: PropTypes.instanceOf(HTMLElement),
    text: PropTypes.string
  };

  getTarget = () => {
    return this.props.target;
  };

  render() {
    return this.props.text && (
      <Align target={this.getTarget} align={{ points: [ 'bc', 'tc' ], offset: [ 0, -13 ] }} monitorWindowResize>
        <div className='ValidationTooltip'>
          <Popup size='s' arrowPosition='bottom' text={this.props.text} type='error' />
        </div>
      </Align>
    );
  }
}
