import PropTypes from 'prop-types';
import React, { MouseEventHandler, ReactNode } from 'react';
import WarningTooltip from '../WarningTooltip';
import Button from '../Button';
import './styles.css';

Bubble.propTypes = {
  text: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  warningTooltip: PropTypes.node
};

interface BubbleProps {
    text: string,
    onRemove: MouseEventHandler<HTMLElement>,
    warningTooltip: ReactNode
}

export default function Bubble({ text, onRemove, warningTooltip, ...otherProps }: BubbleProps) {
  return (
    <div className='Bubble'>
      {warningTooltip && <WarningTooltip iconClassName='Bubble_warning-icon' size='l' text={warningTooltip} to={undefined} />}
      <span>{text}</span>
      <Button className='Bubble_remove-button' onClick={onRemove} {...otherProps}>
        <i className='icon icon-close' aria-hidden='true' />
      </Button>
    </div>
  );
}
