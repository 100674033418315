import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import Button from '../../../../../components/Button';
import SelectField from '../../../../../components/SelectField';
import UnitLabel from '../../../../../components/UnitLabel';
import Api from '../../../../../utils/Api';
import User from '../../../../../utils/User';
import TeamRedeployTimeModal from '../../../TeamRedeployTimeModal';
import ProductMetricsChart from '../../ProductMetricChartTab/ProductMetricsChart';
import RoiCalculatorPanel, { SavingsNumbers } from '../RoiCalculatorPanel';
import DataRecapTable from '../RoiRecapTable';
import { getJRebelHostedSeatCount, getJRebelPriceYearly, shouldShowSavingsSummaryForActualSavings } from '../selectors';
import { calculateRoiOld, getDaysUntilPeriodOld, getPeriodLengthDays, getTimeSavedInPeriodHours, isAtLeastPeriodOld } from './selectors';
import './styles.css';

const periodLabels = {
  yearly: 'Last 365 days',
  quarterly: 'Last 90 days',
  monthly: 'Last 30 days',
  weekly: 'Last 7 days'
};

const periodToGranularity = {
  yearly: 'MONTH',
  quarterly: 'MONTH',
  monthly: 'WEEK',
  weekly: 'DAY',
  total: 'MONTH'
};

export default class ActualRoiPanel extends Component {

  static propTypes = {
    developerSalaryYearly: PropTypes.number.isRequired,
    jrebelPriceYearly: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    timeSavedSeconds: PropTypes.object.isRequired,
    seatUserCount: PropTypes.object.isRequired,
    seatUsage: PropTypes.array.isRequired,
    onRedeployTimeChange: PropTypes.func.isRequired,
    reloadsCountInPeriod: PropTypes.number,
    accountCreatedAt: PropTypes.string,
    fetchingAccountCreatedAt: PropTypes.bool.isRequired,
    period: PropTypes.string.isRequired,
    onPeriodChange: PropTypes.func,
    licenses: PropTypes.any.isRequired
  };

  state = {
    activeModal: null,
    mode: null
  };

  onConfigureButtonPress = () => {
    this.setState({ activeModal: 'teamRedeployTime' });
  };

  onModalSave = () => {
    this.closeModal();
    this.props.onRedeployTimeChange();
  };

  closeModal = () => {
    this.setState({ activeModal: null });
  };

  onPeriodChange = ({ currentTarget: { value } }) => {
    this.props.onPeriodChange(value);
  };
  /* onPeriodChange = e => { TODO: Select field update for CLS-816
    this.props.onPeriodChange(e.value);
  }; */

  renderBadRoiMessage() {
    const daysUntilWeekOld = getDaysUntilPeriodOld(this.props.accountCreatedAt, 'weekly');

    if (daysUntilWeekOld > 0) {
      return <span>You just joined Rebel Licenses. We'll be able to show your savings after <UnitLabel value={daysUntilWeekOld} unit='day' />.</span>;
    }

    if (getJRebelHostedSeatCount(this.props.seatUsage) === 0) {
      return <span>No JRebel usage data available.</span>;
    }

    return <span>It looks like your JRebel licenses are not being used to their full potential.</span>;
  }

  periodToChartConfig() {
    const period = this.props.period;
    const days = getPeriodLengthDays(this.props.accountCreatedAt, period);
    const end = moment.utc();
    const start = end.clone().subtract(days, 'days');
    const granularity = periodToGranularity[period] || 'MONTH';
    return {
      startDate: start,
      endDate: end,
      granularity
    };
  }

  getExportToPDFParameters() {
    // TODO: use a different pdf report for Actual savings panel. (CLS-680)
    const HOURS_PER_WORKDAY = 5;
    const WORKDAYS_PER_YEAR = 261;
    const DAYS_PER_YEAR = 365;
    const fractionOfWorkingDays = WORKDAYS_PER_YEAR / DAYS_PER_YEAR;

    const { accountCreatedAt, period, seatUserCount, currency, developerSalaryYearly, licenses, timeSavedSeconds, reloadsCountInPeriod } = this.props;

    const timeSavedPeriod = getTimeSavedInPeriodHours(timeSavedSeconds, period);
    const seatUsagePeriod = seatUserCount[period];
    const periodWorkingDays = getPeriodLengthDays(accountCreatedAt, period) * fractionOfWorkingDays;
    const redeployCountHourly = reloadsCountInPeriod / seatUsagePeriod / periodWorkingDays / HOURS_PER_WORKDAY;
    const redeployTimeMinutes_ = (moment.duration(timeSavedPeriod, 'hours').asMinutes() / reloadsCountInPeriod);

    // The total redeploy time saved per hour must be less than 1 hour. Guard against degenerate cases, by clamping.
    const redeployTimeMinutes = Math.min(redeployTimeMinutes_, 59 / redeployCountHourly);

    if (periodWorkingDays === 0 || timeSavedPeriod === 0 || !seatUsagePeriod || !redeployCountHourly || redeployCountHourly === Infinity || !redeployTimeMinutes) {
      // No usage or degenerate data.
      // Bail out here to ensure we do not issue an invalid request.
      return false;
    }

    return {
      currency,
      developerCount: seatUsagePeriod,
      developerSalaryYearly,
      jrebelPriceYearly: getJRebelPriceYearly(licenses, currency),
      jrebelPriceYearlyUsd: getJRebelPriceYearly(licenses, 'USD'),
      organization: User.getOrganization(),
      redeployCountHourly,
      redeployTimeMinutes,
      reportType: 'ACTUAL_SAVINGS'
    };
  }

  canExportToPDF() {
    return !!this.getExportToPDFParameters();
  }

  onExportToPdfButtonPress = () => {
    const reportParams = this.getExportToPDFParameters();
    if (reportParams) {
      Api.initiateAuthenticatedDownload(`/reports/roi?${queryString.stringify(reportParams)}`);
    }
  };

  showTotalPeriod() {
    return !isAtLeastPeriodOld(this.props.accountCreatedAt, 'yearly');
  }

  /*
  getSelectPeriodOptions() { TODO: Select field update for CLS-816
    const options = [ 'monthly', 'quarterly', 'yearly' ]
      .filter(period => isAtLeastPeriodOld(this.props.accountCreatedAt, period))
      .map(period => ({ value: period, label: periodLabels[period] }));
    if (this.showTotalPeriod()) {
      options.push({ value: 'total', label: 'All time' });
    }
    return options;
  }
  */

  render() {
    if (this.props.fetchingAccountCreatedAt) {
      return null;
    }

    const {
      period,
      currency,
      reloadsCountInPeriod,
      timeSavedSeconds,
      seatUserCount,
      accountCreatedAt,
      developerSalaryYearly,
      jrebelPriceYearly,
      seatUsage
    } = this.props;

    const roi = calculateRoiOld(accountCreatedAt, period, developerSalaryYearly, jrebelPriceYearly, seatUsage, timeSavedSeconds, seatUserCount);

    // TODO: If the account is older than 1 year then limit "all time" period to those who have a license that lasts more than a year.
    //       This is to prevent cases where we generate degenerate graphs with so many bars they become unreadable.
    const chart = (
      <ProductMetricsChart
        metric='TIME'
        metricTransform='TIME_SIMPLE'
        team=''
        {...this.periodToChartConfig()}
        headerTitle=''
        chartWidth={610}
        chartHeight={200}
        chartMargin={{ left: 40 }}
        spinnerStyle='NONE'
        leftAxisTickValues={3}
      />
    );

    const redeployCount = (reloadsCountInPeriod || 0).toFixed();
    const averageRedeploymentSeconds = reloadsCountInPeriod && (timeSavedSeconds[period] / reloadsCountInPeriod);
    const averageRedeployMinutes = moment.duration(averageRedeploymentSeconds, 'seconds').asMinutes().toFixed();

    const recapValues = [
      {
        icon: 'icon-rotating-arrows',
        label: 'Redeploys saved',
        value: redeployCount,
        tooltip: 'The total number of times JRebel has updated a running application, thus presumably preventing a full redeploy.'
      },
      {
        icon: 'icon-person-head',
        label: 'Active developers',
        value: seatUserCount[period].toFixed(),
        tooltip: 'The number of distinct seat users that have actively used JRebel within the reporting period.'
      },
      {
        icon: 'icon-clock-full',
        label: 'Time saved in hours',
        extra: '(Total across developers)',
        value: getTimeSavedInPeriodHours(timeSavedSeconds, period).toFixed(),
        tooltip: 'The total time saved across active developers. This is based on "Redeploy time" configurations for teams and individual users, as well as product metrics.'
      },
      {
        icon: 'icon-clock-dotted',
        label: 'Average redeploy time',
        extra: '(Time saved/redeploys)',
        value: averageRedeployMinutes,
        tooltip: 'This is a simple arithmetic average estimated duration for the application. (Total time saved across developers / total number of redeploys prevented)'
      }
    ];

    return (
      <Fragment>
        {this.state.activeModal === 'teamRedeployTime' && (
          <TeamRedeployTimeModal origin='ROI_CALCULATOR' onSave={this.onModalSave} onCancel={this.closeModal} />
        )}
        <RoiCalculatorPanel
          className='ActualRoiPanel ActualRoiPanel_full-mode'
          heading='Actual savings'
          headerLeftPart={
            <div className='ChartUnitsLabel'>
              <div className='ChartUnitsLabel_dot' />
              <span className='ChartUnitsLabel_text'>Hours</span>
            </div>
          }
          headerRightPart={
            <>
              <Button type='primary' disabled={!this.canExportToPDF()} className='pull-right' onClick={this.onExportToPdfButtonPress}>Export to PDF</Button>
              <div className='ActualRoiPanel_period-select pull-right'>
                <SelectField
                  value={period}
                  disabled={false}
                  onChange={this.onPeriodChange}
                >
                  {[ 'monthly', 'quarterly', 'yearly' ]
                    .filter(period => isAtLeastPeriodOld(this.props.accountCreatedAt, period))
                    .map(period => <option key={period} value={period}>{periodLabels[period]}</option>)}
                  {this.showTotalPeriod() && <option value='total'>All time</option>}
                </SelectField>
                {/* <NewSelectField TODO: Select field update for CLS-816
                  value={{ value: period, label: periodLabels[period] }}
                  isDisabled={false}
                  onChange={this.onPeriodChange}
                  options={this.getSelectPeriodOptions()}
                /> */}
              </div>
            </>
          }
          hideCentralSection={false}
          centralSection={
            <>
              {chart}
              <div className='ActualRoiPanel_recap'>
                <h5 className='ActualRoiPanel_recap_heading'>JRebel Saved you</h5>
                <DataRecapTable values={recapValues} />
              </div>
              {shouldShowSavingsSummaryForActualSavings(roi, developerSalaryYearly, seatUserCount[period]) &&
                <SavingsNumbers
                  savingsHeading='You have saved'
                  timeSavedPerDeveloperDailyMinutes={roi.timeSavedPerDeveloperDailyMinutes}
                  timeSavedYearlyHours={roi.timeSavedYearlyHours}
                  moneySavedYearly={roi.moneySavedYearly}
                  currency={currency}
                />
              }
            </>
          }
          currency={currency}
          {...roi}
        />
      </Fragment>
    );
  }
}
