import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { SsoNotification, translateErrorCode } from '../labels';
import './styles.css';

const ActivateModal = ({ sso, enable, onSuccess, onCancel, onShowNotification }) => {

  const [ activating, setActivating ] = useState(false);

  const onOkButtonPress = () => {
    setActivating(true);

    const action = enable ? 'activate' : 'deactivate';
    Api.post(`/sso/${sso.uuid}/${action}`, null, ({ error }) => {
      if (error) {
        onShowNotification(translateErrorCode(error));
      }
      else {
        onShowNotification(enable ? SsoNotification.ACTIVATED : SsoNotification.DEACTIVATED);
      }
      onSuccess();
    });
  };

  return (
    <Modal
      title={<div className='SsoActivateModal_header'>{enable ? 'Enable SSO' : 'Disable SSO'}</div>}
      buttons={
        <Fragment>
          <div>
            <ModalButton type='warning' className='secondary' onClick={onCancel}>Cancel</ModalButton>
            <ModalButton type='warning' className='SsoActivateModal_button' disabled={activating} onClick={onOkButtonPress}>{enable ? 'Enable' : 'Disable'}</ModalButton>
          </div>
        </Fragment>}
      onClose={onCancel}
    >
      <p>
        {enable && <Fragment>
          You are about to enable this SSO configuration. This will also disable password authentication.
        </Fragment>}
        {!enable && <Fragment>
          You are about to disable this SSO configuration. This will also enable password authentication.
        </Fragment>}
      </p>
    </Modal>
  );
};

ActivateModal.prototype = {
  sso: PropTypes.object,
  enable: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onShowNotification: PropTypes.func.isRequired
};

export default ActivateModal;
