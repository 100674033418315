import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Page from '../../../components/Page';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import TeamForm from '../TeamForm';
import TeamTable from '../TeamTable';
import './styles.css';

const TeamPage = () => {
  const location = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ list, setList ] = useState([]);
  const [ editedTeam, setEditedTeam ] = useState(null);
  const [ fetching, setFetching ] = useState(true);

  const modal = searchParams.get('modal');
  const origin = searchParams.get('origin');

  const fetch = () => {
    Api.get('/teams?with_products=true', ({ list }) => {
      setList(list);
      setFetching(false);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (modal === 'teamForm' && origin === 'EMAIL') {
      Api.post('/admin-users/events/press/call-to-action', { name: 'ADD_TEAMS', origin: 'EMAIL' });
    }
  }, [ fetching, modal, origin ]);

  const onCreateButtonPress = () => {
    setSearchParams({ ...searchParams, modal: 'teamForm', origin: 'GUI' });
  };

  const closeModal = () => {
    setEditedTeam(null);

    setSearchParams(searchParams => {
      searchParams.delete('modal');
      searchParams.delete('origin');
      return searchParams;
    });
  };

  const onModalSave = () => {
    closeModal();
    fetch();
  };

  const sortedTeams = _(list)
    .sortBy('name')
    .value();

  return (
    <Page
      className='TeamPage'
      title='Teams'
      alert={location.state?.redirectCause === 'teamNotFound' && (
        <Alert type='warn'>Could not find the team you we're looking for. Here are all the teams.</Alert>
      )}
      buttons={User.isAccountAdmin() && (
        <Button type='primary' onClick={onCreateButtonPress}>Add team</Button>
      )}
    >
      {modal === 'teamForm' && (
        <TeamForm
          editedTeam={editedTeam}
          onSave={onModalSave}
          onCancel={closeModal}
        />
      )}

      <TeamTable onTeamsUpdate={fetch} list={sortedTeams} fetching={fetching} origin='TEAMS_PAGE' />
    </Page>
  );
};

export default TeamPage;
