import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import './styles.css';
import FeedbackFormModal from '../FeedbackFormModal';
import User from '../../../utils/User';
import Button from '../../../components/Button';

CornerNotificationPopup.propTypes = {
  type: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  origin: PropTypes.string
};

export const LOCAL_STORAGE_KEY = {
  feedbackLastSubmitTime: 'feedbackLastSubmitTime',
  feedbackLastShownTime: 'feedbackLastShownTime'
};

export default function CornerNotificationPopup({ type, onSave, onCancel, origin }) {
  const [ showNotification, setShowNotification ] = useState(false);
  const [ showButton, setShowButton ] = useState(true);
  const [ unread, setUnread ] = useState(true);

  useEffect(() => {
    setShowButton(shouldShowForUser());
  }, []);

  function shouldShowForUser() {
    let show = true;
    if (localStorage.getItem(LOCAL_STORAGE_KEY.feedbackLastSubmitTime)) {
      const lastSubmitTime = moment.utc(localStorage.getItem(LOCAL_STORAGE_KEY.feedbackLastSubmitTime), moment.ISO_8601);
      // if today - lastSubmitTime <= 30 days --> don't show again
      show = moment.utc().diff(lastSubmitTime, 'days') >= 30;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEY.feedbackLastShownTime) && show) {
      const lastShownTime = moment.utc(localStorage.getItem(LOCAL_STORAGE_KEY.feedbackLastShownTime), moment.ISO_8601);
      // if today - lastShownTime <= 7 days --> don't show again
      show = moment.utc().diff(lastShownTime, 'days') >= 7;
    }
    return show;
  }

  function onSaveModal() {
    setShowNotification(false);
    localStorage.setItem(LOCAL_STORAGE_KEY.feedbackLastSubmitTime, moment.utc().format());
    User.setNPSNotificationSetting();
    onSave();
  }

  function onCancelModal() {
    setShowNotification(false);
    localStorage.setItem(LOCAL_STORAGE_KEY.feedbackLastShownTime, moment.utc().format());
    onCancel();
  }

  return (
    <Fragment>
      {showNotification && type === 'SHOW_FEEDBACK_FORM' && (
        <FeedbackFormModal onSave={onSaveModal} onCancel={onCancelModal} origin={origin} />
      )}
      {showButton && (
        <Fragment>
          <Button
            className={classNames('CornerNotificationPopup_show-button', { 'unread': unread })} onClick={() => {
              setShowNotification(true);
              setUnread(false);
            }}
          >
            <i className='icon icon-corner-notification' aria-hidden='true' />
          </Button>
          {unread && (<div className='ripple' />)}
        </Fragment>
      )}
    </Fragment>
  );
}
