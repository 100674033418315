import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';

export default class UnblockModal extends Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    saving: false
  };

  onOkButtonPress = () => {
    this.setState({ saving: true });

    Api.put('/seat-users/blocked', { email: this.props.user.email, blocked: false }, () => {
      this.props.onSuccess(this.props.user.email);
    });
  };

  render() {
    return (
      <Modal
        title='Unblock user'
        size='m'
        buttons={<div>
          <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
          <ModalButton type='primary' disabled={this.state.saving} onClick={this.onOkButtonPress}>Unblock</ModalButton>
        </div>}
        onClose={this.props.onCancel}
      >
        <p>
          You are about to unblock <strong>{this.props.user.email}</strong>
        </p>
        <p>Are you sure?</p>
      </Modal>
    );
  }
}
