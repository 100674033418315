import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './styles.css';

Page.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.node,
  headingWidget: PropTypes.node,
  buttons: PropTypes.node,
  noHeading: PropTypes.bool,
  alert: PropTypes.node
};

export default function Page({ children, className, title, titleDescription, headingWidget, buttons, noHeading, alert }) {
  return (
    <div className={className}>
      <Helmet title={title} />
      <div className='Page_alert'>
        {alert}
      </div>
      {!noHeading && (
        <div className='Page_heading-row'>
          <div>
            <h2 className='Page_heading'>{title}</h2>
            {titleDescription}
          </div>
          <div className='Page_heading-widget'>{headingWidget}</div>
          <div className='Page_buttons pull-right'>{buttons}</div>
        </div>
      )}
      {children}
    </div>
  );
}
