import _ from 'lodash';

// defines also the sorting order in UI
export const productTypes = [
  'JREBEL',
  'XREBEL'
];

export function sortProductTypes(list) {
  return _(list)
    .sortBy(productType => productTypes.indexOf(productType))
    .value();
}

export function sortByProductType(items) {
  return _(items)
    .sortBy(({ productType }) => productTypes.indexOf(productType))
    .value();
}
