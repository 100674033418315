import classNames from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';
import './styles.css';

type DivProps = ComponentPropsWithoutRef<'div'>;

export function CardHeading({ className, children, ...otherProps }: DivProps) {
  return <div className={classNames('CardHeading', className)} {...otherProps}>{children}</div>;
}

export function CardTitle({ className, children, ...otherProps }: DivProps) {
  return <div className={classNames('CardTitle', className)} {...otherProps}>{children}</div>;
}

export function CardBody({ className, children, ...otherProps }: DivProps) {
  return <div className={classNames('CardBody', className)} {...otherProps}>{children}</div>;
}

export function CardFooter({ className, children, ...otherProps }: DivProps) {
  return <div className={classNames('CardFooter', className)} {...otherProps}>{children}</div>;
}

export default function Card({ className, children, ...otherProps }: DivProps) {
  return (
    <div className={classNames('Card', className)} {...otherProps}>
      {children}
    </div>
  );
}
