import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

function onDoubleClick({ currentTarget }) {
  const range = document.createRange();
  range.selectNode(currentTarget);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
}

TokenUrlLabel.propTypes = {
  token: PropTypes.string.isRequired,
  className: PropTypes.string,
  forPanel: PropTypes.bool
};

export default function TokenUrlLabel({ token, className, forPanel = false, ...otherProps }) {
  return (
    <span className={classNames('TokenUrlLabel text-code', className, { 'for-panel': forPanel })} onDoubleClick={onDoubleClick} {...otherProps}>
      {window.location.origin}/{token}
    </span>
  );
}
