import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import SelectField from '../../../../components/SelectField';
import ValidationTooltip from '../../../../components/ValidationTooltip';
import Api from '../../../../utils/Api';
import { hasJRebelEnabled } from '../../selectors';
import './styles.css';
import ProductMetricsChart from './ProductMetricsChart';

const granularities = [
  { label: 'by day', value: 'DAY', momentUnit: 'day', maxVisible: 25 },
  { label: 'by week', value: 'WEEK', momentUnit: 'week', minVisible: 2, maxVisible: 14 },
  { label: 'by month', value: 'MONTH', momentUnit: 'month', minVisible: 2 }
];

export default class ProductMetricChartTab extends Component {

  static propTypes = {
    metric: PropTypes.oneOf([ 'TIME', 'REDEPLOYS' ]).isRequired
  };

  datePickerFieldRef;

  state = {
    form: {
      startDate: null,
      endDate: moment.utc(),
      granularity: null,
      team: ''
    },

    datePickerFocusedInputRef: null,

    teams: null,
    fetchingTeams: true,

    accountCreatedAt: null,
    fetchingAccountCreatedAt: true
  };

  componentDidMount() {
    Api.get('/teams?with_products=true', ({ list }) => {
      this.setState({ teams: list.filter(({ products }) => hasJRebelEnabled(products)), fetchingTeams: false });
    });

    Api.get('/account', ({ createdAt }) => {
      this.setState(state => {
        const accountCreatedAt = moment.utc(createdAt);
        const monthAgo = moment.utc().subtract({ days: 29 });
        const startDate = accountCreatedAt.isBefore(monthAgo) ? monthAgo : accountCreatedAt;
        const granularity = granularities.find(granularity => this.isGranularityAllowed(granularity, startDate, state.form.endDate)).value;

        return { form: { ...state.form, startDate, granularity }, accountCreatedAt, fetchingAccountCreatedAt: false };
      });
    });
  }

  formatDate(moment) {
    return moment.format('YYYY-MM-DD');
  }

  isGranularityAllowed({ momentUnit, minVisible, maxVisible }, startDate, endDate) {
    if (startDate === null || endDate === null) {
      return false;
    }

    const diff = endDate.diff(startDate, momentUnit);

    return (!minVisible || diff >= minVisible) && (!maxVisible || diff <= maxVisible);
  }

  onPeriodChange = ({ startDate, endDate }) => {
    this.setState(state => {
      const allowedGranularities = granularities.filter(granularity => this.isGranularityAllowed(granularity, startDate, endDate));
      const form = { ...state.form, startDate, endDate };

      if (!allowedGranularities.some(({ value }) => value === state.form.granularity) && allowedGranularities.length !== 0) {
        form.granularity = allowedGranularities[0].value;
      }

      return { form };
    });
  };

  onInputChange = ({ currentTarget: { name, value } }) => {
    this.setState(state => ({ form: { ...state.form, [name]: value } }));
  };

  getPeriodValidationMessage() {
    if (!this.state.form.startDate) {
      return 'Please enter a valid start date.';
    }

    if (!this.state.form.endDate) {
      return 'Please enter a valid end date.';
    }

    return null;
  }

  renderTeamSelectionRadioButton(token, teamName) {
    return (
      <div className='ProductMetricChartTab_teams_radio' key={token}>
        <input
          type='radio'
          name='team'
          id={`team-${token}`}
          value={token}
          checked={this.state.form.team === token}
          onChange={this.onInputChange}
        />
        <label htmlFor={`team-${token}`}>{teamName}</label>
      </div>
    );
  }

  closeDatePicker = () => {
    this.setState({ datePickerFocusedInputRef: null });
  };

  getButtonType = selected => {
    if (selected) {
      return 'primary';
    }
    if (!selected) {
      return 'secondary';
    }
    return 'default';
  };

  renderDatePresets = () => {
    const { startDate, endDate } = this.state.form;
    const today = moment.utc();
    const presets = [
      { text: 'Last 7 days', start: moment.utc().subtract({ days: 6 }), end: today },
      { text: 'Last 30 days', start: moment.utc().subtract({ days: 29 }), end: today },
      { text: 'Last 365 days', start: moment.utc().subtract({ days: 364 }), end: today },
      { text: 'All time', start: this.state.accountCreatedAt, end: today }
    ];

    return (
      <div className='ProductMetricChartTab_date-picker_panel'>
        {presets.map(({ text, start, end }) => {
          const selected = start.isSame(startDate, 'day') && end.isSame(endDate, 'day');
          return (
            <Button
              key={text}
              className={classNames('ProductMetricChartTab_date-picker_button')}
              type={this.getButtonType(selected)}
              disabled={start.isBefore(this.state.accountCreatedAt, 'day')}
              onClick={() => {
                this.onPeriodChange({ startDate: start, endDate: end });
                this.closeDatePicker();
              }}
            >
              {text}
            </Button>
          );
        })}
        <Button className='ProductMetricChartTab_date-picker_close pull-right' onClick={this.closeDatePicker}>
          <i className='icon icon-close' aria-hidden='true' />
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className='ProductMetricChartTab'>
        <div className='ProductMetricChartTab_content'>
          <div className='ProductMetricChartTab_left-panel'>
            {!this.state.fetchingAccountCreatedAt && (
              <div>
                <div className='ProductMetricChartTab_selectors_title'>Timeline</div>
                <div className={classNames('ProductMetricChartTab_selectors_item', { 'error': !this.state.form.startDate || !this.state.form.endDate })}>
                  <div ref={element => this.datePickerFieldRef = element}>
                    <DateRangePicker
                      startDate={this.state.form.startDate}
                      startDateId='START_DATE'
                      endDate={this.state.form.endDate}
                      endDateId='END_DATE'
                      onDatesChange={this.onPeriodChange}
                      focusedInput={this.state.datePickerFocusedInputRef}
                      onFocusChange={element => this.setState({ datePickerFocusedInputRef: element })}
                      isOutsideRange={day => day.isAfter(moment.utc(), 'day') || day.isBefore(this.state.accountCreatedAt, 'day')}
                      firstDayOfWeek={1}
                      displayFormat='DD.MM.YYYY'
                      renderCalendarInfo={this.renderDatePresets}
                      hideKeyboardShortcutsPanel
                      small
                      calendarInfoPosition='top'
                    />
                  </div>
                  <ValidationTooltip target={this.datePickerFieldRef} text={this.getPeriodValidationMessage()} />
                </div>
                <div className='ProductMetricChartTab_selectors_item'>
                  <SelectField
                    className='ProductMetricChartTab_selectors_granularity'
                    name='granularity'
                    value={this.state.form.granularity}
                    onChange={this.onInputChange}
                  >
                    {granularities.map(granularity => {
                      const { value, label } = granularity;
                      const { startDate, endDate } = this.state.form;
                      return <option key={value} value={value} disabled={!this.isGranularityAllowed(granularity, startDate, endDate)}>{label}</option>;
                    })}
                  </SelectField>
                </div>
              </div>
            )}
            {!this.state.fetchingTeams && (
              <div className='ProductMetricChartTab_teams'>
                <div className='ProductMetricChartTab_teams_title'>Team</div>
                <div>
                  {this.renderTeamSelectionRadioButton('', 'All teams')}
                  {this.state.teams.map(({ token, name }) => this.renderTeamSelectionRadioButton(token, name))}
                </div>
              </div>
            )}
          </div>
          <Card className='ProductMetricChartTab_right-panel'>
            <ProductMetricsChart
              metric={this.props.metric}
              metricTransform={this.props.metric}
              team={this.state.form.team}
              granularity={this.state.form.granularity}
              startDate={this.state.form.startDate}
              endDate={this.state.form.endDate}
              headerTitle='JRebel'
            />
          </Card>
        </div>
      </div>
    );
  }
}
