import _ from 'lodash';

// defines also the sorting order in UI
export const supportedProductsTypes = {
  'JREBEL_STANDALONE': [ 'JREBEL' ],
  'XREBEL_STANDALONE': [ 'XREBEL' ],
  'REBEL_SUITE': [ 'JREBEL', 'XREBEL' ]
};

export const licenseTypes = Object.keys(supportedProductsTypes);

export function licenseTypesSupportingProduct(productType) {
  return licenseTypes.filter(licenseType => supportedProductsTypes[licenseType].indexOf(productType) >= 0);
}

export function productTypeOfStandalone(licenseType) {
  const productTypes = supportedProductsTypes[licenseType];
  return isStandalone(licenseType) ? productTypes[0] : null;
}

export function sortByLicenseType(items) {
  return _(items)
    .sortBy(({ licenseType }) => licenseTypes.indexOf(licenseType))
    .value();
}

export function isStandalone(licenseType) {
  return supportedProductsTypes[licenseType].length === 1;
}
