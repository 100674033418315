import emailValidator from 'email-validator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';
import Bubble from '../../../components/Bubble';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import { getTeamActiveSeatUsers, isDomainBlacklisted } from '../selectors';
import './styles.css';

export default class SeatUserInviteForm extends Component {

  static propTypes = {
    team: PropTypes.shape({
      token: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    domainWhitelistingEnabled: {
      value: null,
      fetching: true
    },
    whitelistedDomains: {
      list: [],
      fetching: true
    },
    seatUsers: {
      list: [],
      fetching: true
    },
    email: '',
    emails: [],
    errorMessage: null,
    showDomainWarning: false,
    activeModal: 'form',
    saving: false
  };

  componentDidMount() {
    Api.get('/account', ({ domainWhitelistingEnabled }) => {
      this.setState({ domainWhitelistingEnabled: { value: domainWhitelistingEnabled, fetching: false } });
    });

    Api.get('/whitelisted-domains', ({ list }) => {
      this.setState({ whitelistedDomains: { list, fetching: false } });
    });

    Api.get('/seat-users', ({ list }) => {
      this.setState({ seatUsers: { list, fetching: false } });
    });
  }

  getEmailValidationMessage = () => {
    if (!emailValidator.validate(this.state.email)) {
      return 'Please enter a valid email address.';
    }

    if (this.state.domainWhitelistingEnabled.value && isDomainBlacklisted(this.state.email, this.state.whitelistedDomains.list)) {
      return 'This email domain is not allowed by your licensing administrator.';
    }

    if (getTeamActiveSeatUsers(this.state.seatUsers.list, this.props.team.name).some(({ email }) => email === this.state.email)) {
      return 'A user with this email has already activated a product in this team.';
    }

    return null;
  };

  onFormSubmit = e => {
    e.preventDefault();

    if (this.getEmailValidationMessage()) {
      this.setState({ errorMessage: this.getEmailValidationMessage() });

      if (this.state.domainWhitelistingEnabled.value && isDomainBlacklisted(this.state.email, this.state.whitelistedDomains.list)) {
        this.setState({ showDomainWarning: true });
      }

      return;
    }

    this.setState(state => {
      const emails = [ ...state.emails ];

      if (!emails.includes(state.email)) {
        emails.push(state.email);
      }

      return { emails, email: '' };
    });
  };

  onEmailChange = ({ currentTarget: { name, value } }) => {
    this.setState({ [name]: value, errorMessage: null });
  };

  onDeleteEmail = ({ currentTarget }) => {
    this.setState(state => {
      const emails = [ ...state.emails ];

      emails.splice(emails.indexOf(currentTarget.getAttribute('data-email')), 1);

      return { emails };
    });
  };

  onSubmitButtonPress = () => {
    this.setState({ saving: true });

    Api.post(`/teams/${this.props.team.token}/seat-users/invited`, { emails: this.state.emails }, () => {
      this.setState({ activeModal: 'success' });
    });
  };

  render() {
    return (
      <div className='SeatUserInviteForm'>
        {this.state.activeModal === 'success' && (
          <Modal
            title='Invites sent'
            size='m'
            buttons={<ModalButton type='primary' className='pull-right' onClick={this.props.onSave}>Got it!</ModalButton>}
            onClose={this.props.onSave}
          >
            <div className='SeatUserInviteForm_text'>
              <span>Invitation emails with installation and activation instructions have been sent to your team members. </span>
              <span>You can see their usage statistics once they activate. </span>
              <span>You can go back to being awesome now.</span>
            </div>
          </Modal>
        )}

        {this.state.activeModal === 'form' && (
          <Modal
            title={
              <span>
                <span className='SeatUserInviteForm_light-title'>Invite users to </span>
                <span>{this.props.team.name}</span>
              </span>
            }
            size='m'
            buttons={<div>
              <ModalButton type='secondary' disabled={this.state.saving} onClick={this.props.onCancel}>Cancel</ModalButton>
              <ModalButton type='primary' disabled={this.state.saving || this.state.emails.length === 0} onClick={this.onSubmitButtonPress}>Send invite</ModalButton>
            </div>}
            onSubmit={this.onFormSubmit}
            onClose={this.props.onCancel}
          >
            <p className='SeatUserInviteForm_text'>
              <span>Add emails for the license users that you wish to invite to this team. </span>
              <span>They can use the team URL to activate JRebel & XRebel.</span>
            </p>

            {this.state.showDomainWarning && (
              <Alert type='warn'>
                <AlertEmphasis>Only following email domains are allowed: </AlertEmphasis>
                {this.state.whitelistedDomains.list.map(({ domain }) => domain).join(', ')}
              </Alert>
            )}

            <div className='SeatUserInviteForm_email-input'>
              <div className='SeatUserInviteForm_email-field'>
                <InputField
                  type='text'
                  label='User email'
                  errorMessage={this.state.errorMessage}
                  placeholder='Add emails one by one'
                  name='email'
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  autoFocus
                />
              </div>
              <ModalButton
                type='primary'
                className='SeatUserInviteForm_email-add-button'
                disabled={this.state.whitelistedDomains.fetching || this.state.seatUsers.fetching}
                submit
              >
                Add
              </ModalButton>
            </div>

            <div className='SeatUserInviteForm_email-bubbles'>
              {this.state.emails.map(email => <Bubble key={email} data-email={email} text={email} onRemove={this.onDeleteEmail} />)}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
