import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import User from '../../utils/User';
import UnsubscribePage from '../UnsubscribePage';
import AdminUserPage from './AdminUserPage';
import AdminUserSettingsPage from './AdminUserSettingsPage';
import AnalyticsPage from './AnalyticsPage';
import Dashboard from './Dashboard';
import EmailDomainWhitelistPage from './EmailDomainWhitelistPage';
import LicensePage from './LicensePage';
import NotificationPage from './NotificationPage';
import SeatUserPage from './SeatUserPage';
import SsoSettingsPage from './SsoSettingsPage';
import TeamPage from './TeamPage';

const AdminPages = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getRedirectState = (redirectCause: string) => {
    return { redirectCause, lastRoute: location.pathname, lastSearch: location.search };
  };

  useEffect(() => {
    const sessionEndedListenerToken = User.addSessionEndedListener(() => {
      navigate('/login', { state: getRedirectState('sessionEnded') });
    });

    return () => sessionEndedListenerToken.remove();
  }, []);

  if (!User.isAuthenticated()) {
    return <Navigate replace key='2' to='/login' state={getRedirectState('unauthenticated')} />;
  }

  return (
    <Routes>
      <Route path='/unsubscribe' element={<UnsubscribePage />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/seat-users/*' element={<SeatUserPage />}>
        <Route path='' element={<></>} />
        <Route path=':rk' element={<></>} />
      </Route>
      {!User.isTeamLead() && [
        <Route key='1' path='/licenses/' element={<LicensePage />}>
          <Route path='' element={<></>} />
          <Route path=':rk' element={<></>} />
        </Route>,
        <Route key='2' path='/teams' element={<TeamPage />} />,
        <Route key='3' path='/admin-users' element={<AdminUserPage />} />,
        <Route key='4' path='/analytics/*' element={<AnalyticsPage />} />,
        <Route key='5' path='/email-domain-whitelist' element={<EmailDomainWhitelistPage />} />,
        User.shouldBeOnboarded() && <Route key='5' element={<Navigate replace key='5' to='/dashboard' />} />
      ]}
      {(User.isAccountAdmin() || User.isAccountViewAdmin()) &&
        <Route path='/sso-settings' element={<SsoSettingsPage />} />}
      {User.isAccountAdmin() && <Route path='/notifications/*' element={<NotificationPage />}>
        <Route path='' element={<></>} />
        <Route path=':rk' element={<></>} />
      </Route>}
      {User.canEdit() && <Route path='/user-settings' element={<AdminUserSettingsPage />} />}
      <Route path='*' element={<Navigate replace to='/dashboard' />} />
    </Routes>
  );
};

export default AdminPages;
